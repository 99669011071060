import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid as MuiGrid } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useImmer } from 'use-immer';
import * as Domain from '@liasincontrol/domain';
import { Finance } from '@liasincontrol/data-service';
import { TextElement } from '@liasincontrol/ui-elements';
import { FormMode, ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Wrapper, WrapperContent, PageTitle, Heading1, Bar, Button, Section, ErrorOverlay } from '@liasincontrol/ui-basics';
import { useWorkflowTemplates, useUsers } from '../../../shared/hooks';

import { BudgetJournalKindForm } from '../BudgetJournalKindForm/index';
import { BudgetJournalKindGroups } from '../BudgetJournalKindGroups';
import { SetWorkflowDialog } from '../SetWorkflowDialog';

type BudgetJournalKindDialogType = {
    showDialog: boolean,
};

type WorkflowDialogType = {
    showDialog: boolean,
    working: boolean,
}

/** Represents a UI component that renders the detail page od a budget journal kind. */
const BudgetJournalKindDetails: React.FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [item, setItem] = useImmer<Domain.Finance.BudgetJournalKindItem>(undefined);
    const [editDialog, setEditDialog] = useState<BudgetJournalKindDialogType>({ showDialog: false });
    const users = useUsers();
    const [workflowDialog, setWorkflowDialog] = useState<WorkflowDialogType>({ showDialog: false, working: false });
    const workflowTemplates = useWorkflowTemplates();
    const [refreshBudgetJournalGroups, setRefreshBudgetJournalGroups] = useState<number>(Date.now());

    useEffect(() => {
        Finance.BudgetJournalKindDataAccessor.get(id).then((response) => {
            setError(undefined);
            setItem(response.data);
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        });
    }, []);

    const showEditDialog = () => {
        setEditDialog({
            showDialog: true,
        });
    };

    const changeWorkflow = (workflowId: string, currentName: string) => {
        setWorkflowDialog({ showDialog: true, working: true });
        Finance.BudgetJournalKindDataAccessor.updateWorkflowDefinitionId(id, workflowId, currentName).then(() => {
            if (item.workflowDefinitionId === workflowId) {
                setRefreshBudgetJournalGroups(Date.now());
            }
            else {
                setItem((item) => {
                    item.workflowDefinitionId = workflowId;
                    item.workflowDefinitionName = workflowTemplates.items.find((wft) => wft.id === workflowId).name;
                });
            }
        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.BudgetJournalNameNotMatch)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.BudgetJournalNameNotMatch] });
            } else {
                setError(errorInfo);
            }
        }).finally(() => {
            setWorkflowDialog({ showDialog: false, working: false });
        });
    };

    const onSave = (budgetJournalKind: Domain.Finance.BudgetJournalKindItem) => {
        Finance.BudgetJournalKindDataAccessor.update(budgetJournalKind).then(() => {
            setItem(budgetJournalKind);
        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.CodeNotUniqueBaseYearKind)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.CodeNotUniqueBaseYear] });
            } else {
                setError(errorInfo);
            }
        }).finally(() => {
            setEditDialog({
                showDialog: false,
            });
        });
    };

    return (
        <>
            <Wrapper>
                <WrapperContent>
                    <PageTitle>
                        <Heading1>
                            <Button
                                btnbase="iconbuttons"
                                btntype="medium_transparentmain"
                                icon={<ArrowBack />}
                                onClick={() => navigate('/finance/admin/budgetjournalkinds')}
                            />
                            {item?.name}
                        </Heading1>
                    </PageTitle>

                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        {item &&
                            <>
                                <Bar look="toolbar">
                                    <Bar start>
                                        {!error && (<>
                                            <Button id={`btn-edit-${id}`} btnbase="textbuttons" btntype="medium_icon" onClick={showEditDialog}>
                                                Bewerken
                                            </Button>
                                            <Button id={`btn-change-workflow-${id}`} btnbase="textbuttons" btntype="medium_icon" onClick={() => setWorkflowDialog({ showDialog: true, working: false })}>
                                                Workflow Wijzigen
                                            </Button>
                                        </>)}
                                    </Bar>
                                </Bar>
                                <Section look='white' rowSpan={1}>
                                    <MuiGrid container
                                        spacing={{ xs: 2 }}
                                        columns={{ xs: 1, md: 5 }}
                                        justifyContent="flex-start"
                                        alignItems="flex-end">
                                        <MuiGrid item xs={1}>
                                            <TextElement id='text-name' value={item?.name}
                                                label='Naam'
                                                editorSettings={{
                                                    disabled: true,
                                                    restrictions: {},
                                                    validationErrors: [],
                                                    onChange: () => null
                                                }}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item xs={1}>
                                            <TextElement id='text-code' value={item?.code}
                                                label='Code'
                                                editorSettings={{
                                                    disabled: true,
                                                    restrictions: {},
                                                    validationErrors: [],
                                                    onChange: () => null
                                                }}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item xs={1}>
                                            <TextElement id='text-baseyear' value={item?.baseYear.toString()}
                                                label='Basisjaar'
                                                editorSettings={{
                                                    disabled: true,
                                                    restrictions: {},
                                                    validationErrors: [],
                                                    onChange: () => null
                                                }}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item xs={1}>
                                            <TextElement id='text-isactive' value={item?.isActive ? 'Actief' : 'Inactief'}
                                                label='Actief'
                                                editorSettings={{
                                                    disabled: true,
                                                    restrictions: {},
                                                    validationErrors: [],
                                                    onChange: () => null
                                                }}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item xs={1}>
                                            <TextElement id='text-workflowname' value={item?.workflowDefinitionName}
                                                label='Workflow'
                                                editorSettings={{
                                                    disabled: true,
                                                    restrictions: {},
                                                    validationErrors: [],
                                                    onChange: () => null
                                                }}
                                            />
                                        </MuiGrid>
                                    </MuiGrid>
                                </Section>
                                <Section look='white'>
                                    <BudgetJournalKindGroups
                                        budgetJournalKindId={id}
                                        workflowDefinitionId={item.workflowDefinitionId}
                                        pageSize={75}
                                        externalRefresh={refreshBudgetJournalGroups}
                                        users={users.items || []}
                                        onError={setError} />
                                </Section>
                            </>
                        }
                    </ErrorOverlay>
                </WrapperContent>
            </Wrapper>

            {editDialog.showDialog && <BudgetJournalKindForm
                budgetJournalKind={item}
                workflowTemplates={workflowTemplates.items}
                onSave={(budgetJournalKind) => onSave(budgetJournalKind)}
                onCancel={() => setEditDialog({ showDialog: false })}
                formMode={FormMode.Edit}
            />}
            {workflowDialog.showDialog && <SetWorkflowDialog
                currentName={item?.name}
                workflows={workflowTemplates.items}
                onCancel={() => setWorkflowDialog({ showDialog: false, working: false })}
                disabled={workflowDialog.working}
                onConfirm={changeWorkflow}
            />}
        </>
    );
};

export { BudgetJournalKindDetails as index }
