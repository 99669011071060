import React, { useEffect, useMemo, useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { FormData, FormHelper, BasicValidator, ValidationUtils, ValidatorsDictionary } from '@liasincontrol/core-service';
import { SelectEditor } from '@liasincontrol/ui-elements';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { AutoFocus, IDataItemProps, IRadioGroupFieldItem, ModalDialog, ModalDialogFooter, RadioGroupField } from '@liasincontrol/ui-basics';

type Props = {
    publishButtonDisabled: boolean,
    onPublishDocument: (publishProfileId: string) => void,
    onCloseDialog: () => void,
};

/**
 * Represents a UI component that renders the publish configuration modal dialog for a document.
 */
export const PublishConfiguration: React.FC<Props> = (props) => {
    const [publishForm, setPublishForm] = useState<FormData<string>>(initForm());
    const [availablePublishProfiles, setAvailablePublishProfiles] = useState<IDataItemProps<string>[]>();
    const [publishProfileType, setPublishProfileType] = useState<string>('1');

    useEffect(() => {
        DataAccess.SiteMap.getAvailablePublishProfiles().then((response) => {
            setAvailablePublishProfiles(response.data.map((item) => ({ value: item.profileId, label: item.profileName })));
        });
    }, []);

    const hasErrors = useMemo(() => Object.keys(publishForm?.validationErrors)
        .some(fieldId => publishForm?.validationErrors[fieldId]?.length > 0), [publishForm?.validationErrors]);

    const storeFormValue = (value: string, systemId: keyof typeof validators, selectProfile: boolean) => {
        const validators = getValidators({ publishProfileDefinitionId: publishProfileFieldDefinition }, selectProfile);
        setPublishForm((prevForm) => FormHelper.validateAndStoreFormValue<FormData<string>>(prevForm, value, validators, systemId));
    };

    const publishProfileFieldDefinition = { systemId: publishProfileDefinitionId, id: publishProfileDefinitionId, optionItems: availablePublishProfiles ? availablePublishProfiles?.map(pp => ({ id: pp.value, name: pp.label })) : [] } as Domain.Shared.FieldDefinition;
    const validators = getValidators({ publishProfileDefinitionId: publishProfileFieldDefinition }, publishProfileType === '2');

    return (
        <ModalDialog settings={{
            look: 'interactive',
            title: 'Genereren',
            footer:
                <ModalDialogFooter
                    leftButtonText='Annuleren'
                    onLeftButtonClick={props.onCloseDialog}
                    rightButtonText='Genereren'
                    onRightButtonClick={() => props.onPublishDocument(publishForm.values[publishProfileDefinitionId])}
                    rightButtonDisabled={hasErrors || props.publishButtonDisabled}
                />
        }}>
            <MuiGrid container
                spacing={2}
                columns={{ xs: 1 }}
                justifyContent="flex-start"
                alignItems="flex-end">
                <MuiGrid item xs={1} key='profiles'>
                    <AutoFocus>
                        <RadioGroupField
                            id='docVersion-withProfile'
                            label='Publicatie profiel'
                            alignment='vertical'
                            items={publishProfileTypeItems}
                            value={publishProfileType}
                            onChange={(val: string) => {
                                setPublishProfileType(val);
                                storeFormValue(val === '1' ? null : publishForm.values[publishProfileDefinitionId], publishProfileDefinitionId, val === '2');
                            }}
                        />
                    </AutoFocus>
                    <SelectEditor<Domain.Shared.FieldDefinitionOptionItem>
                        id='docVersion-publishProfile'
                        displayExpr='name'
                        searchable={false}
                        clearable={true}
                        optionItems={publishProfileFieldDefinition.optionItems}
                        editorSettings={ValidationUtils.getEditorSettings(true, publishProfileType === '1', validators, publishForm, (val: Domain.Shared.FieldDefinitionOptionItem) => storeFormValue(val?.id, publishProfileDefinitionId, publishProfileType === '2'), publishProfileDefinitionId)}
                        value={publishProfileFieldDefinition.optionItems.find(oi => oi.id === publishForm.values[publishProfileDefinitionId])}
                    />
                </MuiGrid>
            </MuiGrid>
        </ModalDialog>
    );
};

const initForm = (): FormData<string> => {
    return {
        values: {
            [publishProfileDefinitionId]: null,
        },
        touched: {},
        validationErrors: {},
        isValid: true,
    };
};

const getValidators = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>, selectProfile: boolean): ValidatorsDictionary => {
    if (!fieldDefinitions) return {};
    return {
        [publishProfileDefinitionId]: new BasicValidator<string>({ required: selectProfile }),
    };
};

const publishProfileDefinitionId = 'publish-profile-id';
const publishProfileTypeItems: IRadioGroupFieldItem[] = [
    { value: '1', label: 'Publiceer alles' },
    { value: '2', label: 'Gebruik profiel' },
];
