import React, { useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { ModalDialog, ModalDialogFooter, RadioGroupField } from '@liasincontrol/ui-basics';
import { siteAccessItems } from '..';

type Props = {
    saveButtonDisabled: boolean,
    currentSiteAccess: boolean,
    onCloseDialog: () => void,
    onChangeSiteAccess: (isPublic: boolean) => void,
};

/**
 * Represents a UI component that renders the version site access configuration modal dialog.
 */
export const VersionSiteAccessConfiguration: React.FC<Props> = (props) => {
    const [isPublicWebsite, setIsPublicWebsite] = useState<boolean>(props.currentSiteAccess || false);

    return (
        <ModalDialog settings={{
            look: 'interactive',
            title: 'Toegang',
            footer:
                <ModalDialogFooter
                    leftButtonText='Annuleren'
                    onLeftButtonClick={props.onCloseDialog}
                    rightButtonText='Opslaan'
                    onRightButtonClick={() => props.onChangeSiteAccess(isPublicWebsite)}
                    rightButtonDisabled={props.saveButtonDisabled}
                />
        }}>
            <MuiGrid container
                columns={1}
                justifyContent="flex-start"
                alignItems="flex-end">
                <MuiGrid item xs={1} key='siteAccess'>
                    <RadioGroupField
                        id='webVersion-siteAccess'
                        label='Toegang tot de publicatie'
                        alignment='vertical'
                        items={siteAccessItems}
                        value={isPublicWebsite.toString()}
                        onChange={(val: string) => setIsPublicWebsite(JSON.parse(val))}
                    />
                </MuiGrid>
            </MuiGrid>
        </ModalDialog>
    );
};
