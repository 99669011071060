import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate, Routes } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import * as Domain from '@liasincontrol/domain';
import { Button, Heading1, PageTitle, WrapperContent, WarningWrapper, WarningLook, Wrapper } from '@liasincontrol/ui-basics';
import { State, ActionSource, ModulesActionCreator, ElementDefinitionsActionCreator, AjaxRequestStatus } from '@liasincontrol/redux-service';
import { Publisher as DataAccess, Studio as StudioDataAccess } from '@liasincontrol/data-service';
import { DefinitionsHelper, FieldsHelper } from '@liasincontrol/core-service';
import { SystemModuleDefinitions, SystemElementDefinitions } from '@liasincontrol/domain';
import Helper from '../../PublicationItem/PublicationInformation/index.helper';
import { PublicationNavBar } from '../PublicationNavBar';
import { PublicationContext } from '../../../../helpers/PublicationContext';
import { queueHelper } from './QueueHelper';
import { CUSTOM_PALETTE } from '@liasincontrol/ui-devextreme';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    look: 'normal' | 'admin',
    modulenavigation: boolean,
    components: React.ReactElement
};

/**
 * Represents an UI element that renders the nested layout for a selected publication, containing the pageheader, navigation and active content.
 */
const Index: React.FC<Props> = (props) => {
    const { id: publicationId } = useParams();
    const [publicationSettings, setPublicationSettings] = useState<Domain.Publisher.Publication>();
    const [publicationWorkflowStates, setPublicationWorkflowStates] = useState<Domain.Publisher.TaskManagerWorkflowState[]>();
    const [studioHierarchies, setStudioHierarchies] = useState<Domain.Studio.HierarchyListItem[]>();

    const navigate = useNavigate();

    useEffect(() => {
        if (!publicationId) {
            setPublicationSettings(null);
            return;
        }

        const getPublicationSettings = DataAccess.Publications.getPublication(publicationId);
        const getWorkflowStates = DataAccess.TasksManagement.getWorkflowStatesForPublication(publicationId);
        const getStudioHierarchies = StudioDataAccess.HierarchyDataAccessor.getV2('');

        const promises = [getPublicationSettings, getWorkflowStates, getStudioHierarchies];

        Promise.allSettled(promises)
            .then(([publicationSettingsResponse, workflowStatesResponse, studioHierarchiesResponse]) => {
                const pubSettings = publicationSettingsResponse.status === 'fulfilled' ? publicationSettingsResponse.value.data : null;
                const sortedStates = workflowStatesResponse.status === 'fulfilled' ? workflowStatesResponse.value.data : [];
                const studioHierarchies = studioHierarchiesResponse.status === 'fulfilled' ? studioHierarchiesResponse.value.data : [];

                setPublicationSettings(pubSettings as Domain.Publisher.Publication);
                setPublicationWorkflowStates((sortedStates as Domain.Publisher.TaskManagerWorkflowState[]).sort((a, b) => a.order - b.order));
                setStudioHierarchies(studioHierarchies['value'] as Domain.Studio.HierarchyListItem[]);
            });

    }, [publicationId]);

    const publicationElementDef = DefinitionsHelper.findElementDefinition(props.elementDefinitions?.items, SystemElementDefinitions.Pub.Publication);
    const dataSourceElementDef = DefinitionsHelper.findElementDefinition(props.elementDefinitions?.items, SystemElementDefinitions.Pub.DataSource);
    const publicationClosed = Helper.getPublicationIsClosed(publicationSettings, publicationElementDef);
    const colorPalette = Helper.getColorPaletteName(publicationSettings, publicationElementDef);

    const customColors = useMemo(() => {
        if (!publicationElementDef || !publicationSettings) return null;

        if (colorPalette === CUSTOM_PALETTE) {
            const customColorsField = publicationElementDef?.fields?.find(f => f.systemId === Domain.SystemFieldDefinitions.Pub.CustomColorPaletteColors);
            if (customColorsField) {
                const customColorsJSON = publicationSettings?.publication.fields[customColorsField.id];
                return JSON.parse(customColorsJSON || 'null');
            }
        }
        return null;
    }, [colorPalette, publicationElementDef, publicationSettings]);

    // initialize the dependent data...
    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Publisher]);
        return null;
    }

    const loadDataSourceElement = async (dataSourceId: string): Promise<Domain.Publisher.DataSourceElement> => {
        //known issue: on the backend triggering multiple refresh calls in parallel might cause an error
        //that is why whe are using a queue here
        const finalResponse = await queueHelper.addOrGetJob(async () => {
            const response = await DataAccess.DataSources.getDataSource(publicationId, dataSourceId);
            const dataSourceElement = new Domain.Publisher.DataSourceElement();
            FieldsHelper.mapObject<Domain.Publisher.DataSourceElement>(dataSourceElement, dataSourceElementDef.fields, response.data.fields);
            dataSourceElement.id = response.data.elementId;
            if (!dataSourceElement.autoRefresh || dataSourceElement.failedRefresh) return dataSourceElement;

            const refreshResponse = await DataAccess.DataSources.refreshDataSource(publicationId, dataSourceId);
            const dataSourceElementRefreshed = new Domain.Publisher.DataSourceElement();
            FieldsHelper.mapObject<Domain.Publisher.DataSourceElement>(dataSourceElementRefreshed, dataSourceElementDef.fields, refreshResponse.data.fields);
            dataSourceElementRefreshed.id = refreshResponse.data.elementId;
            return dataSourceElementRefreshed;

        }, dataSourceId);

        return finalResponse;
    };


    return (<Wrapper>
        <PublicationContext.Provider
            value={
                {
                    settings: publicationSettings,
                    setSettings: setPublicationSettings,
                    workflowStates: publicationWorkflowStates,
                    setWorkflowStates: setPublicationWorkflowStates,
                    loadDataSourceElement: loadDataSourceElement,
                    colorPalette: colorPalette,
                    studioHierarchies: studioHierarchies,
                    setStudioHierarchies: setStudioHierarchies,
                    customColors: customColors,
                }}>
            <WrapperContent>
                {publicationId &&
                    <PageTitle>
                        <Heading1>
                            <Button btnbase="iconbuttons" btntype="medium_transparentmain" icon={<ArrowBack />} onClick={() => navigate('/publisher/publication/list')}></Button>
                            {Helper.getPublicationName(publicationSettings, publicationElementDef)}
                        </Heading1>
                        {publicationClosed &&
                            <WarningWrapper
                                look={WarningLook.warning}
                                icon={<WarningAmberOutlinedIcon />}
                                className='mb-100 p-025'
                                messageText='Let op: Deze publicatie is gesloten en kan niet worden aangepast.' />
                        }
                    </PageTitle>
                }
                {props.modulenavigation && <PublicationNavBar isClosed={publicationClosed} />}
                <Routes>
                    {props.components.props?.children}
                </Routes>
            </WrapperContent>
        </PublicationContext.Provider>
    </Wrapper>
    );
}

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
        elementDefinitions: state.elementdefinitions[ActionSource.Publication],
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Publication, data: { moduleId: module?.id } }));
        },
    };
};
const Component = connect(mapStateToProps, mapDispatchToProps)(Index);

export { Component as PublicationLayout };