import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

export const resolveAutoFocus = (el: HTMLElement, selectors: readonly string[]) => {
  const selectFirstMatch = makeSelectFirstMatch(el);
  const focusable = selectors.reduce(selectFirstMatch, null);
  return focusable;
};

export const AutoFocus = ({ children, ignoreDefaults, selectors, delay }: IAutoFocusProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const selectorsList = selectors ? selectors : [];
    if (ref.current) {
      const focusable = resolveAutoFocus(ref.current, ignoreDefaults ? selectorsList : [...selectorsList, ...selectorDefault]);
      if (focusable && focusable.focus) {
        setTimeout(() => {
          focusable.focus();
          if (focusable.select) {
            focusable.select();
          }
        }, delay);
        //return focusable;
      }
    }
  }, [ref, delay, selectors, ignoreDefaults]);

  return <Container ref={ref}>{children}</Container>;
};

export interface IAutoFocusProps {
  readonly selectors?: readonly string[];
  readonly children?: React.ReactNode;
  readonly delay?: number;
  readonly ignoreDefaults?: boolean;
}

const Container = styled.div`
  width: 100%;
`;

const selectorDefault: readonly string[] = [
  `input:not(:disabled), textarea:not(:disabled), select:not(:disabled), [role]:not(:disabled):not([aria-disabled]):not([aria-readonly])`,
  `a:not(:disabled), button:not(:disabled)`,
];

// matches: for checking my child
// querySelector: for anything beneath
const makeSelectFirstMatch = (el: HTMLElement) => (
  found: any,
  nextSelector: string,
) => found || (el.matches(nextSelector) ? el : el.querySelector(nextSelector));
