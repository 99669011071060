import React, { useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { IDataItemProps } from '@liasincontrol/ui-basics';
import { MultiSelectElement } from '@liasincontrol/ui-elements';
import { ActionType, Actions } from '@liasincontrol/userrights-service';

type PagePublicationProfilesSettingsProps = {
    selectedPublishProfiles: IDataItemProps<any>[],
    availablePublishProfiles: IDataItemProps<any>[],
    disabled: boolean,
    onChangePagePublish: (publishProfiles: IDataItemProps<any>[]) => void,
    canPerformAction?: (action: Actions, actionType: ActionType) => boolean,
}

export const PagePublicationProfilesSettings: React.FC<PagePublicationProfilesSettingsProps> = (props) => {
    const [selectedProfiles, setSelectedProfiles] = useState<IDataItemProps<any>[]>(props.selectedPublishProfiles);

    const hasPublisherSetPublishProfileItemsAccess = props.canPerformAction && props.canPerformAction(Actions.COMPLEX_PublisherSetPublishProfileItems, ActionType.Update);

    return (
        <MuiGrid item xs={12}>
            <MultiSelectElement
                id="publish-profiles"
                label="Publicatie profiel"
                editorSettings={{
                    disabled: !hasPublisherSetPublishProfileItemsAccess || !props.availablePublishProfiles || props.disabled,
                    validationErrors: undefined,
                    restrictions: undefined,
                    onChange: (selectedItems: IDataItemProps<any>[]) => {
                        setSelectedProfiles(selectedItems);
                        props.onChangePagePublish(selectedItems);
                    },
                }}
                searchable={false}
                clearable={true}
                optionItems={props.availablePublishProfiles ? props.availablePublishProfiles : []}
                value={selectedProfiles}
            />
        </MuiGrid>
    );
};