import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import { AppSettingsService } from '@liasincontrol/config-service';
import { Section } from '@liasincontrol/ui-basics';
import { ContextMenu, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Shared as DataAccess } from '@liasincontrol/data-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, Actions } from '@liasincontrol/userrights-service';
import { LinkUserGroup } from './LinkUserGroup';

type Props = {
    userIdentity: UserIdentity;
    lastRefresh?: number;
    onError: (error: Domain.Shared.ErrorInfo) => void;
    onRefresh?: (tick: number) => void;
    setSelectedTab?: (tabIndex: number) => void;
};

/**
 * Represents a UI component that renders the users list page.
 */
const Index: React.FC = () => {
    const props = useOutletContext<Props>();
    const hasManageUsersAccess = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups);
    const [users, setUsers] = useState<Domain.Shared.User[]>([]);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isUserLink, setIsUserLink] = useState<{ visible: boolean, data?: Domain.Shared.User }>({ visible: false });

    useEffect(() => {
        props.setSelectedTab(0);
        DataAccess.Users.get()
            .then((response) => {
                props.onError(undefined);
                const allEnabledUsers = response.data.filter(user => user.isInTenant);
                setUsers(allEnabledUsers);
            }).catch((err) => {
                props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });
    }, [props.lastRefresh]);

    const linkUserGroupsToUser = (userId: string, userGroupsId: string[]) => {
        setIsBusy(true);
        DataAccess.Users.addUserToGroups(userId, userGroupsId)
            .then(() => {
                props.onRefresh(Date.now());
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.ItemAlreadyInUse)) {
                    props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting,
                        { ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.ItemAlreadyInUse] }));
                } else {
                    props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception));
                }
            }).finally(() => {
                setIsUserLink({ visible: false });
                setIsBusy(false);
            });
    };

    const availableColumns: GridColumn<Domain.Shared.User>[] = [
        {
            name: 'name',
            title: 'Naam',
            width: '50%',
        },
        {
            name: 'groups',
            title: 'Gebruikersgroep(en)',
            dataType: 'string',
            calculateDisplayValue: (data) => data.groups.map(group => group.name).join(', '),
            calculateFilterExpression: (filterValue, selectedFilterOperation, target) => {
                if (target === "filterRow") {
                    return [(data) => {
                        const groupNames = data.groups.map(group => group.name.toLowerCase());
                        const filterVal = String(filterValue).toLowerCase();

                        switch (selectedFilterOperation) {
                            case 'contains':
                                return groupNames.some(name => name.includes(filterVal));
                            case 'notcontains':
                                return groupNames.every(name => !name.includes(filterVal));
                            case 'startswith':
                                return groupNames.some(name => name.startsWith(filterVal));
                            case 'endswith':
                                return groupNames.some(name => name.endsWith(filterVal));
                            case '=':
                                return groupNames.some(name => name === filterVal);
                            case '<>':
                                return groupNames.every(name => name !== filterVal);
                            default:
                                return false;
                        }
                    }];
                }
                return null;
            }
        },
        {
            name: 'email',
            title: 'E-mailadres',
        },
        {
            name: 'id',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => {
                if (!hasManageUsersAccess) return;
                return (
                    <ContextMenu<Domain.Shared.User>
                        keyExpr='id'
                        item={data}
                        actions={[
                            {
                                action: (item) => setIsUserLink({ visible: true, data: item }),
                                displayName: 'Bewerk gebruikersgroep',
                                ariaLabel: `Bewerken ${data.name}`,
                                actionName: `edit-${data.id}`
                            }
                        ]}
                    />);
            },
        }
    ];

    return (
        <Section look='white'>
            {users && availableColumns &&
                <LsGrid
                    dataSource={users}
                    columns={availableColumns}
                    enableColumnChooser={false}
                    searching={false}
                    paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                    showRowLines={true}
                    showColumnLines={false}
                    showBorders={false}
                    enableFilterRow={true}
                />
            }
            {hasManageUsersAccess && isUserLink.visible ? (
                <LinkUserGroup
                    user={isUserLink.data}
                    onSave={linkUserGroupsToUser}
                    onCancel={() => setIsUserLink({ visible: false })}
                    onError={props.onError}
                    isBusy={isBusy}
                />
            ) : null}
        </Section>
    );
};

export { Index as index };
