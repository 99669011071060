import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus, Section } from '@liasincontrol/ui-basics';
import { DateValidator, TextValidator, ValueType, FormMode, FormHelper, ValidationErrorData, DateUtils, NumberValidator, BasicValidator, ValidatorsDictionary, AnyFormData } from '@liasincontrol/core-service';
import { TextElement, DateElement, IntegerElement, TagBoxElement } from '@liasincontrol/ui-elements';

type Props = {
    measureMoment?: Domain.Shared.MeasureMoment,
    mode: FormMode,
    externalError?: { id: string, message: string },
    onFormDataChanged: (formData: { value: Record<string, ValueType>, isValid: boolean, isTouched: boolean }) => void,
};

/**
 * Represents a UI component that renders the measure moment form.
 */
export const MeasureMomentForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<AnyFormData>(initFormData(props.measureMoment));
    const validators = getValidators();

    useEffect(() => {
        if (!props.externalError) return;

        setForm((prevForm) => ({
            ...prevForm,
            isValid: false,
            validationErrors: {
                ...prevForm.validationErrors,
                [props.externalError.id]: [{ error: props.externalError.message, isExternal: true }]
            }
        }));
    }, [props.externalError]);

    useEffect(() => {
        setForm(initFormData(props.measureMoment));
    }, [props.measureMoment]);

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators, resetExternalErrorFieldSystemIds: string[] = []): void => {
        setForm((prevForm) => {
            const newForm = FormHelper.validateAndStoreFormValue<AnyFormData>(prevForm, value, validators, systemId, resetExternalErrorFieldSystemIds);
            props.onFormDataChanged({ value: newForm.values, isValid: newForm.isValid, isTouched: Object.keys(newForm.touched).length > 0 });
            return newForm;
        });
    };

    return (
        <Section look='white'>
            <MuiGrid container justifyContent="flex-start" alignItems="flex-start" spacing={2} columns={{ xs: 1, md: 6 }}>
                <MuiGrid item xs={1} md={6} key='measure-moment-name'>
                    <AutoFocus>
                        <TextElement
                            id='measureMoment-name'
                            label='Naam'
                            editorSettings={{
                                disabled: props.mode === FormMode.View,
                                restrictions: validators.name.getRestrictions(),
                                validationErrors: form?.validationErrors['name'],
                                onChange: (value: string) => storeFormValue(value, 'name')
                            }}
                            value={form?.values.name}
                        />
                    </AutoFocus>
                </MuiGrid>
                <MuiGrid item xs={1} md={6} key='measureMoment-baseYear'>
                    <IntegerElement
                        id='measureMoment-baseYear'
                        label='Basisjaar'
                        controlButtons={true}
                        editorSettings={{
                            disabled: props.mode === FormMode.View,
                            restrictions: validators.baseYear.getRestrictions(),
                            validationErrors: form?.validationErrors['baseYear'],
                            onChange: (value) => storeFormValue(value, 'baseYear', ['tags'])
                        }}
                        value={form?.values.baseYear || 0}
                    />
                </MuiGrid>
                <MuiGrid item xs={1} md={3} key='pub-measureMoment-startDate'>
                    <DateElement
                        id='pub-measureMoment-startDate'
                        label='Startdatum'
                        editorSettings={{
                            disabled: props.mode === FormMode.View,
                            restrictions: validators.baseYear.getRestrictions(),
                            validationErrors: form?.validationErrors['startDate'],
                            onChange: (value) => storeFormValue(value, 'startDate')
                        }}
                        value={form?.values.startDate}

                    />
                </MuiGrid>
                <MuiGrid item xs={1} md={3} key='measureMoment-endDate'>
                    <DateElement
                        id='measureMoment-endDate'
                        label='Einddatum'
                        editorSettings={{
                            disabled: props.mode === FormMode.View,
                            restrictions: validators.baseYear.getRestrictions(),
                            validationErrors: form?.validationErrors['endDate'],
                            onChange: (value) => storeFormValue(value, 'endDate')
                        }}
                        value={form?.values.endDate}
                    />
                </MuiGrid>
                <MuiGrid item xs={1} md={6} key='measureMoment-tags'>
                    <TagBoxElement
                        id='measureMoment-labels'
                        value={form?.values.tags}
                        label='Labels'
                        editorSettings={{
                            disabled: props.mode === FormMode.View,
                            restrictions: validators.tags.getRestrictions(),
                            validationErrors: form?.validationErrors['tags'],
                            onChange: (value: string[]) => storeFormValue(value, 'tags')
                        }}
                        showClearButton={true}
                        showDropDownButton={false}
                        openOnFieldClick={false}
                        acceptCustomValue={true}
                    />
                </MuiGrid>
            </MuiGrid>
        </Section >
    );
};

/**
 * Initialises the validators for the form.
 */
const getValidators = (): ValidatorsDictionary => {
    return {
        'name': new TextValidator({
            required: true,
            stringMaxLength: 50,
            stringType: Domain.Shared.StringType.SingleLine
        }),
        'baseYear': new NumberValidator({
            required: true,
            minValue: 0,
        }),
        'startDate': new DateValidator({
            required: true,
        },
            (value: Date, formFields: Record<string, ValueType>): ValidationErrorData[] => {
                if (
                    value &&
                    formFields['endDate'] &&
                    DateUtils.tryConvertDate(value, true).getTime() > DateUtils.tryConvertDate(formFields['endDate'], true).getTime()
                ) {
                    return [{ error: 'Datum valt na de einddatum.' }];
                }
                return [];
            }
        ),
        'endDate': new DateValidator({
            required: true,
        },
            (value: Date, formFields: Record<string, ValueType>): ValidationErrorData[] => {
                if (
                    value &&
                    formFields['startDate'] &&
                    DateUtils.tryConvertDate(value, true).getTime() < DateUtils.tryConvertDate(formFields['startDate'], true).getTime()
                ) {
                    return [{ error: 'Datum valt voor de startdatum.' }];
                }
                return [];
            }
        ),
        'tags': new BasicValidator<string[]>({ required: false },
            (value: string[], formFields: Record<string, ValueType>): ValidationErrorData[] => {
                const emptyTags = value.some((v) => _.isEmpty(v));
                if (emptyTags) {
                    return [{ error: 'Ongeldig label.' }];
                }
                const longTags = value.some((v) => v.length > 50);
                if (longTags) {
                    return [{ error: 'Maximaal 50 tekens per label.' }];
                }
                const unallowedCharacter = value.every((a) => regex.test(a));
                if (!unallowedCharacter) {
                    return [{ error: 'Niet toegestaan om teken te gebruiken.' }];
                }
                return [];
            }),
    };
};

/**
 * Initialises the form data with user group data.
 */
const initFormData = (data: Domain.Shared.MeasureMoment): AnyFormData => {
    const formData = {
        values: {
            'name': data?.name || '',
            'startDate': data?.startDate,
            'endDate': data?.endDate,
            'baseYear': data?.baseYear || 0,
            'tags': data?.tags || [],
        },
        touched: {},
        validationErrors: {},
        isValid: false,
    };
    return formData;
};

const regex = /^([-a-zA-Z0-9_\s]+$)/;
