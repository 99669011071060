import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import TextBox from "devextreme-react/text-box";
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import { Bar, Button, ErrorOverlay, Heading2, Icon, IconSize, IconValue, PageTitle, Section, Wrapper, WrapperContent, LeaseWrapper, ModalDialog, ModalDialogFooter, Text, SVGIcon } from '@liasincontrol/ui-basics';
import { ApiErrorReportingHelper, FormMode, ValueType, isMomentOpen, FormInfo, useUserSettings } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ActionSource, ElementDefinitionsActionCreator, ModulesActionCreator, UsersActionCreator, State, AjaxRequestStatus, WorkflowTemplateActionCreator, MeasureMomentsActionCreator, SvgIconActionCreator } from '@liasincontrol/redux-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { Performance, Shared as SharedDataAccess } from '@liasincontrol/data-service';
import { SystemModuleDefinitions } from '@liasincontrol/domain';
import { SelectElement, SelectElementTemplateProps } from '@liasincontrol/ui-elements';
import Styled from './index.styled';
import { HierarchyItem } from './HierarchyItem';
import { HierarchyGrid } from './HierarchyGrid';
import { MissingWorkflowDialog } from './MissingWorkflowDialog';
import _ from 'lodash';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

type PanelVisibilityData = {
    elementId?: string;
    parentElementId?: string;
    elementDefinitionSystemId?: string;
    isVisible: boolean;
    audit: Domain.Dto.Shared.AuditEvent[];
};

type PerformanceType = { value: Domain.Performance.HierarchyItemElementType, label: string, elementDefinitionId: string };
/**
 * Represents a UI component that renders the overview of the performance management module.
 */
export const Overview: React.FC<Props> = (props) => {
    const { selectedtype: selectedTypeParam, selectedmoment: selectedMoment, selecteditem: selectedItemParam } = useParams<{ selectedtype?: string, selectedmoment?: string, selecteditem?: string }>();
    const [types, setTypes] = useState<{
        values: PerformanceType[],
        selectedType?: PerformanceType
    }>({ values: [], selectedType: undefined });

    const [measureMoments, setMeasureMoments] = useState<{
        values: Domain.Shared.MomentItem[],
        selectedMeasureMoment: Domain.Shared.MomentItem
    }>({ values: [], selectedMeasureMoment: undefined });
    const [editPanelVisibility, setEditPanelVisibility] = useState<PanelVisibilityData>({ isVisible: !!selectedItemParam, elementId: selectedItemParam, elementDefinitionSystemId: selectedTypeParam, audit: [] });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [hierarchyItems, setHierarchyItems] = useState<Domain.Performance.HierarchyItem[]>();
    const [hierarchySettings, setHierarchySettings] = useState<Domain.Performance.HierarchySettings>();
    const [hierarchyRights, setHierarchyRights] = useState<Domain.Dto.Performance.HierarchyRights>();
    const [showMissingWorkflowDialog, setShowMissingWorkflowDialog] = useState<boolean>(false);
    const [elementInstance, setElementInstance] = useState<Domain.Performance.HierarchyItem>(null);
    const [leaseInfo, setLeaseInfo] = useState<Domain.Shared.AcquireLease>();
    const [showNoLeaseDialog, setShowNoLeaseDialog] = useState<{ visible: boolean, message?: string }>({ visible: false });
    const [showImportDialog, setShowImportDialog] = useState<boolean>(false);
    const [fromMeasureMoment, setFromMeasureMoment] = useState<Domain.Shared.MomentItem>();
    const [isImportDialogSaveButtonActive, setIsImportDialogSaveButtonActive] = useState<boolean>(true);
    const [showTimeoutMessage, setShowTimeoutMessage] = useState<boolean>(false);
    const userSettingsContext = useUserSettings();

    const availableUsers = useMemo(() => {
        if (!props.users || props.users.status !== AjaxRequestStatus.Done) {
            return [];
        }

        return props.users.items.filter(user => user.enabled);
    }, [props.users]);

    const workflowDictionary = useMemo(() => {
        const workflowDictionary = {};
        if (hierarchySettings && props.workflowTemplates?.items?.length > 0) {
            const tryAddWorkflowDefinition = (workflowId: string, hierarchyElementType: Domain.Performance.HierarchyItemElementType) => {
                if (workflowId) {
                    const workflow = props.workflowTemplates.items.find(workflow => workflow.id === workflowId);
                    if (workflow) {
                        workflowDictionary[hierarchyElementType] = workflow;
                    }
                }
            };

            tryAddWorkflowDefinition(hierarchySettings.policyWorkflowProcessId, Domain.SystemElementDefinitions.Performance.Policy);
            tryAddWorkflowDefinition(hierarchySettings.goalWorkflowProcessId, Domain.SystemElementDefinitions.Performance.Goal);
            tryAddWorkflowDefinition(hierarchySettings.achievementWorkflowProcessId, Domain.SystemElementDefinitions.Performance.Achievement);
            tryAddWorkflowDefinition(hierarchySettings.activityWorkflowProcessId, Domain.SystemElementDefinitions.Performance.Activity);
        }

        return workflowDictionary as Record<Domain.Performance.HierarchyItemElementType, Domain.Shared.WorkflowTemplateWithStates>;
    }, [hierarchySettings, props.workflowTemplates]);

    const selectedElementDefinition = useMemo(() => {
        if (!props.elementDefinitions?.items || !editPanelVisibility.elementDefinitionSystemId) {
            return null;
        }

        return Object.values(props.elementDefinitions.items).find(item => item.systemId === editPanelVisibility.elementDefinitionSystemId);
    }, [props.elementDefinitions, editPanelVisibility.elementDefinitionSystemId]);

    const canCreate = useMemo(() =>
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_GlobalPerformanceContributor, ActionType.Create)
        , [props.userIdentity]);

    const canEdit = useMemo(() =>
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_GlobalPerformanceContributor, ActionType.Update)
        , [props.userIdentity]);

    useEffect(() => {
        // Check if (default) workflow is set:
        if (props.workflowTemplates.items.length === 0 && props.workflowTemplates.status === AjaxRequestStatus.Done) {
            setShowMissingWorkflowDialog(true);
        }
    }, [props.workflowTemplates]);

    useEffect(() => {
        if (!props.measureMoments) {
            return;
        }

        const momentObjects = props.measureMoments.items.map((moment) => ({ value: moment.id, label: moment.name, closed: moment.status === Domain.Shared.MeasureMomentStatus.Closed }));

        const defaultMoment = userSettingsContext.measureMomentId
            ? props.measureMoments.items.find((moment) => moment.id === userSettingsContext.measureMomentId)
            : selectedMoment
                ? props.measureMoments.items.find((moment) => moment.id === selectedMoment)
                : props.measureMoments.items.find((moment) => isMomentOpen(moment.status));

        setMeasureMoments({
            values: momentObjects,
            selectedMeasureMoment: defaultMoment ? {
                value: defaultMoment.id,
                label: defaultMoment.name,
                closed: !isMomentOpen(defaultMoment.status),
            } : undefined,
        });
    }, [props.measureMoments, userSettingsContext.measureMomentId]);

    useEffect(() => {
        if (!measureMoments.selectedMeasureMoment || !measureMoments.selectedMeasureMoment.value) {
            return;
        }

        fetchHierarchy(measureMoments.selectedMeasureMoment.value);
    }, [measureMoments.selectedMeasureMoment]);

    useEffect(() => {
        if (!props.elementDefinitions?.items) {
            return;
        }

        const availableTypes = Object.keys(props.elementDefinitions.items)
            .filter((elementDefinitionId) => performanceElementDefinitionSystemIds.includes(props.elementDefinitions.items[elementDefinitionId].systemId))
            .map((elementDefinitionId) => ({
                value: props.elementDefinitions.items[elementDefinitionId].systemId as Domain.Performance.HierarchyItemElementType,
                label: props.elementDefinitions.items[elementDefinitionId].name,
                elementDefinitionId: props.elementDefinitions.items[elementDefinitionId].id,
                icon: props.elementDefinitions.items[elementDefinitionId].icon,
                color: props.elementDefinitions.items[elementDefinitionId].color,
            }));

        setTypes({
            values: availableTypes,
            selectedType: selectedTypeParam ? availableTypes.find(item => item.value === selectedTypeParam) : undefined
        });
    }, [props.elementDefinitions, selectedTypeParam]);

    useEffect(() => {
        if (!selectedElementDefinition) {
            setElementInstance(null);
            setLeaseInfo(undefined);
        } else if (editPanelVisibility.elementId) {
            if (!hierarchyItems || hierarchyItems.length <= 0) return;
            if (canEdit) {
                getLease(editPanelVisibility.elementId);
            }
            Performance.HierarchyItemDataAccessor.getHierarchyItem(editPanelVisibility.elementId)
                .then((response) => {
                    const instance = hierarchyItems?.find(item => item.element.elementId === editPanelVisibility.elementId);
                    instance.element = response.data;
                    setElementInstance({ ...instance, element: response.data });
                }).catch((exception) => {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
                });
        } else {
            const newElement = new Domain.Performance.HierarchyItem();
            newElement.element = {
                fields: selectedElementDefinition.fields.reduce(
                    (collection, item) => ({ ...collection, [`${item.id}`]: '' }),
                    {}
                ),
                elementDefinitionId: selectedElementDefinition.id,
                elementDefinitionSystemId: selectedElementDefinition.systemId,
                elementId: undefined,
                attachments: null,
                complexFields: null
            };
            setElementInstance(newElement);
        }
    }, [selectedElementDefinition, hierarchyItems, editPanelVisibility.elementId]);

    // #region handlers
    const fetchHierarchy = (selectedMeasureMomentId: string) => {
        if (!selectedMeasureMomentId) return;
        Performance.HierarchyDataAccessor.get(selectedMeasureMomentId, true, true).then((result) => {
            setHierarchyItems(result.data.hierarchy);
            setHierarchySettings(result.data.settings);
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        });

        Performance.HierarchyRightsAccessor.get(selectedMeasureMomentId).then((response) => {
            setHierarchyRights(response.data)
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        });
    };

    const getLease = (elementId: string) => {
        if (elementId) {
            SharedDataAccess.Leases.acquireLease(elementId)
                .then((response) => {
                    const lease: Domain.Shared.AcquireLease = response.data;
                    setLeaseInfo(lease);
                }).catch((err) => {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
                });
        }
    };

    const deleteLease = useCallback(() => {
        //delete lease if exist a lease, and it's owned by logged in user
        if (leaseInfo && leaseInfo?.ownerId === props.userIdentity.profile.sub) {
            SharedDataAccess.Leases.deleteLease(leaseInfo.subjectId)
                .then(() => {
                    setLeaseInfo(undefined);
                }).catch(() => null);
            return true;
        } else
            return false;
    }, [leaseInfo]);

    const onCancelChanges = (withDeleteLease = true) => {
        const newHierarchyItems = [...hierarchyItems];
        const instanceId = newHierarchyItems?.findIndex(item => item.element.elementId === editPanelVisibility.elementId);
        if (instanceId >= 0) {
            newHierarchyItems.splice(instanceId, 1, elementInstance);
            setHierarchyItems(newHierarchyItems);
        }

        setEditPanelVisibility({
            isVisible: false,
            elementId: null,
            elementDefinitionSystemId: null,
            parentElementId: null,
            audit: []
        });
        if (withDeleteLease) deleteLease();
    };

    const onSaveChanges = (refreshData = true) => {
        if (!measureMoments.selectedMeasureMoment?.value) return;

        // Save the changes that have been made over a performance instance:
        const instance: Domain.Performance.HierarchyItem = {
            ...elementInstance,
            parentHierarchyItemId: editPanelVisibility.parentElementId,
        };

        const cleanFields = Object.keys(instance.element.fields).reduce((collection, item) => ({ ...collection, [item]: instance.element.fields[item].toString() }), {});
        const cleanAttachments = instance.element.attachments.filter(attachment => attachment.blobId || attachment.deleted);
        instance.element = {
            ...instance.element,
            fields: cleanFields,
            attachments: cleanAttachments,
        };

        if (editPanelVisibility.elementId) {
            if (!canEdit) {
                return;
            }

            Performance.HierarchyItemDataAccessor.update(measureMoments.selectedMeasureMoment.value, instance)
                .then(() => {
                    if (refreshData) {
                        onCancelChanges(false);
                        fetchHierarchy(measureMoments.selectedMeasureMoment.value);
                    }
                }).catch((exception) => {
                    const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);
                    if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceNoValidLeaseForSave) ||
                        errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceItemIsLockedByAnotherUser)) {
                        setShowNoLeaseDialog({ visible: true, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PerformanceNoValidLeaseForSave] });
                    } else {
                        onCancelChanges();
                        setError(errorInfo);
                    }
                });
            return;
        }

        Performance.HierarchyItemDataAccessor.create(measureMoments.selectedMeasureMoment.value, instance)
            .then(() => {
                if (refreshData) {
                    onCancelChanges();
                    fetchHierarchy(measureMoments.selectedMeasureMoment.value);
                }
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceCreateItemLeaseInvalid)) {
                    setShowNoLeaseDialog({ visible: true, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PerformanceCreateItemLeaseInvalid] });
                } else {
                    onCancelChanges();
                    setError(errorInfo);
                }
            });
    };

    const onAssignContributors = (contributors: Record<string, boolean>) => {
        if (!measureMoments.selectedMeasureMoment?.value) return;

        Performance.HierarchyRightsAccessor.updateContributors(measureMoments.selectedMeasureMoment.value, elementInstance.element.elementId, contributors)
            .then(() => {
                fetchHierarchy(measureMoments.selectedMeasureMoment.value);
            }).catch((exception) => {
                onCancelChanges();
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception));
            });
    };

    const onReparentEntity = (hierarchyItemId: string, parentHierarchyItemId: string) => {
        if (!measureMoments.selectedMeasureMoment?.value) return;

        Performance.HierarchyItemDataAccessor.reparent(measureMoments.selectedMeasureMoment.value, hierarchyItemId, parentHierarchyItemId)
            .then(() => {
                fetchHierarchy(measureMoments.selectedMeasureMoment?.value);
            }).catch((exception) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception));
            });
    };

    const onReorderEntities = (moveAfterItemId: string, movedItemId: string) => {
        if (!measureMoments.selectedMeasureMoment?.value) return;

        Performance.HierarchyItemDataAccessor.reorderChildren(measureMoments.selectedMeasureMoment.value, moveAfterItemId, movedItemId)
            .then(() => {
                fetchHierarchy(measureMoments.selectedMeasureMoment?.value);
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);

                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceMoveItemError) ||
                    errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceReorderItemLeaseInvalid)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PerformanceReorderItemLeaseInvalid] });
                } else {
                    setError(errorInfo);
                }
            });
    };

    const onDeleteEntity = (hierarchyItemId) => {
        if (!measureMoments.selectedMeasureMoment?.value || !hierarchyItemId) return;
        Performance.HierarchyItemDataAccessor.delete(measureMoments.selectedMeasureMoment.value, hierarchyItemId)
            .then(() => {
                fetchHierarchy(measureMoments.selectedMeasureMoment?.value);
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceItemIsLockedByAnotherUser) ||
                    errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceDeleteItemLeaseInvalid)) {
                    setShowNoLeaseDialog({ visible: true, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PerformanceItemIsLockedByAnotherUser] });
                } else {
                    setError(errorInfo);
                }
            });
    };

    const onEditEntity = (id) => {
        const hierarchyItem = hierarchyItems.find(item => item.element.elementId === id);

        if (!hierarchyItem) return;

        const panelVisibilityData: PanelVisibilityData = {
            isVisible: true,
            elementId: hierarchyItem.element.elementId,
            elementDefinitionSystemId: hierarchyItem.element.elementDefinitionSystemId,
            parentElementId: hierarchyItem.parentHierarchyItemId,
            audit: []
        };

        if (hierarchyItem.element.elementId && measureMoments.selectedMeasureMoment?.value) {
            Performance.HierarchyItemDataAccessor.getAuditData(measureMoments.selectedMeasureMoment.value, hierarchyItem.element.elementId)
                .then((response) => {
                    setEditPanelVisibility({
                        ...panelVisibilityData,
                        audit: response.data || []
                    });
                })
                .catch(() => {
                    setEditPanelVisibility(panelVisibilityData);
                });

        } else {
            setEditPanelVisibility(panelVisibilityData);
        }
    };

    const onImport = () => {
        if (!measureMoments.selectedMeasureMoment?.value || !fromMeasureMoment) return;

        setIsImportDialogSaveButtonActive(false);
        Performance.HierarchyDataAccessor.import(fromMeasureMoment.value, measureMoments.selectedMeasureMoment?.value)
            .then(() => {
                fetchHierarchy(measureMoments.selectedMeasureMoment?.value);
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.AxiosTimeout)) {
                    setShowTimeoutMessage(true);
                } else {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
                }

            })
            .finally(() => {
                setIsImportDialogSaveButtonActive(true);
                setShowImportDialog(false);
            })

    };
    // #endregion

    // #region idle timer handlers
    const onActive = () => {
        getLease(editPanelVisibility.elementId);
    };

    const onIdle = () => {
        onSaveChanges(false);
    };

    const handleStillHere = () => {
        getLease(editPanelVisibility.elementId);
    };

    const handleGone = () => {
        onCancelChanges();
        if (measureMoments.selectedMeasureMoment.value) {
            fetchHierarchy(measureMoments.selectedMeasureMoment.value);
        }
    };

    const handleDeleteLease = () => {
        deleteLease();
    };
    // #endregion

    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return null;
    }

    if (props.users.status === AjaxRequestStatus.NotSet) {
        props.fetchUsers();
        return null;
    }

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Performance]);
        return null;
    }

    if (props.workflowTemplates.status === AjaxRequestStatus.NotSet) {
        props.fetchWorkflowTemplates();
        return null;
    }

    if (!props.icons || props.icons.status === AjaxRequestStatus.NotSet) {
        props.fetchIcons();
        return null;
    }

    const canRenderHierarchyItem = editPanelVisibility.isVisible && hierarchyItems && hierarchyRights && measureMoments.selectedMeasureMoment?.value && elementInstance && selectedElementDefinition;

    // #region custom components
    const momentInputCustomOptions = (inputProps: any, templateProps?: SelectElementTemplateProps) => {
        const moment = props.measureMoments.items.find(moment => inputProps?.value === moment.id); // very inefficient
        return (
            <Styled.HierarchyTypeWrapper>
                {moment && <Icon value={!isMomentOpen(moment.status) ? IconValue.Lock : IconValue.OpenLock} size={IconSize.medium} />}
                {templateProps?.isFieldTemplate ?
                    <TextBox stylingMode='outlined' value={inputProps && inputProps.label} placeholder={templateProps.placeholder} />
                    :
                    <Styled.SingleValueLabel>{inputProps.label}</Styled.SingleValueLabel>
                }
            </Styled.HierarchyTypeWrapper>
        )
    };

    const typeInputCustomOptions = (customValueProps: any, templateProps?: SelectElementTemplateProps) => {
        const ed = getElementDefinition(Object.values(props.elementDefinitions.items), customValueProps?.value as string);
        return (<Styled.HierarchyTypeWrapper>
            {ed &&
                <SVGIcon value={props.icons.items?.[ed.icon]?.svg} size={IconSize.medium} color={ed.color} />
            }
            {templateProps?.isFieldTemplate ?
                <TextBox stylingMode='outlined' value={customValueProps && customValueProps.label} placeholder={templateProps?.placeholder} />
                :
                <Styled.SingleValueLabel>{customValueProps?.label}</Styled.SingleValueLabel>
            }
        </Styled.HierarchyTypeWrapper>);
    };

    const measureMomentsOptions = measureMoments.values.filter((moment) => moment.value !== measureMoments?.selectedMeasureMoment?.value);
    // #endregion

    return (<>
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Doelenboom</Heading2>
                </PageTitle>
                <ErrorOverlay error={error?.details.title} errorDetails={error?.details} onRetry={() => fetchHierarchy(measureMoments.selectedMeasureMoment?.value)} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <Bar look='toolbar'>
                        <Bar start>
                            <Button
                                id="btn-add-new-item"
                                btnbase="textbuttons"
                                btntype="medium_icon"
                                icon={<Add />}
                                disabled={!canCreate
                                    || props.workflowTemplates.items.length === 0
                                    || props.workflowTemplates.status !== AjaxRequestStatus.Done
                                    || measureMoments.selectedMeasureMoment?.closed}
                                onClick={() => {
                                    setEditPanelVisibility({
                                        isVisible: true,
                                        elementId: null,
                                        elementDefinitionSystemId: types.selectedType ? types.selectedType.value : types.values[0]?.value,
                                        parentElementId: null,
                                        audit: []
                                    })
                                }}
                            >
                                Nieuw
                            </Button>
                            {(hierarchyItems?.length === 0 && !measureMoments?.selectedMeasureMoment?.closed)
                                ? <Button
                                    id="btn-import-hierarchy"
                                    btnbase="textbuttons"
                                    btntype="medium_icon"
                                    disabled={!canCreate}
                                    onClick={() => {
                                        setFromMeasureMoment(null);
                                        setShowImportDialog(true);
                                    }}
                                >
                                    OVERNEMEN
                                </Button>
                                : null
                            }
                        </Bar>
                        <Bar end>
                            <SelectElement<PerformanceType>
                                id='select-hierarchy-item-element'
                                optionItems={types.values}
                                value={types.selectedType}
                                displayExpr='label'
                                clearable={true}
                                customOptions={(item) => typeInputCustomOptions(item)}
                                customSingleValue={(item) => typeInputCustomOptions(item || types.selectedType, { isFieldTemplate: true, placeholder: 'Alles' })}
                                editorSettings={{
                                    disabled: false,
                                    validationErrors: [],
                                    restrictions: {},
                                    onChange: (selectedItem: PerformanceType) => {
                                        setTypes((prev) => ({
                                            ...prev,
                                            selectedType: prev.values.find(item => item === selectedItem)
                                        }));
                                    }
                                }}
                            />
                            <SelectElement<Domain.Shared.MomentItem>
                                id='select-measuremoment'
                                displayExpr='label'
                                optionItems={measureMoments.values}
                                placeholder='Kies...'
                                value={measureMoments.selectedMeasureMoment}
                                customOptions={momentInputCustomOptions}
                                customSingleValue={(item) => momentInputCustomOptions(item || measureMoments.selectedMeasureMoment, { isFieldTemplate: true, placeholder: 'Kies...' })}
                                editorSettings={{
                                    onChange: (selectedItem) => {
                                        userSettingsContext.setMeasureMomentId(selectedItem ? selectedItem.value : undefined);
                                    }
                                }}
                            />
                        </Bar>
                    </Bar>
                    <Section look='white'>
                        <HierarchyGrid
                            userIdentity={props.userIdentity}
                            elementDefinitions={props.elementDefinitions.items}
                            hierarchyItems={hierarchyItems}
                            selectedType={(types.selectedType ? types.selectedType.value : types.values[0]?.value)}
                            isMeasureMomentClosed={measureMoments.selectedMeasureMoment?.closed}
                            workflowDictionary={workflowDictionary}
                            users={availableUsers}
                            allowReordering={!types.selectedType || types.selectedType.value === Domain.SystemElementDefinitions.Performance.Policy}
                            onCreateEntity={(type: Domain.Performance.HierarchyItemElementType, parentHierarchyItemId: string) => {
                                setEditPanelVisibility({
                                    isVisible: true,
                                    elementDefinitionSystemId: type,
                                    parentElementId: parentHierarchyItemId,
                                    elementId: null,
                                    audit: []
                                });
                            }}
                            onReparentEntity={onReparentEntity}
                            onReorderEntities={onReorderEntities}
                            onDeleteEntity={onDeleteEntity}
                            onEditEntity={onEditEntity}
                            icons={props.icons.items}
                        />
                    </Section>
                </ErrorOverlay>
            </WrapperContent>
        </Wrapper>

        {showMissingWorkflowDialog && <MissingWorkflowDialog onClose={() => setShowMissingWorkflowDialog(false)} />}
        {/* Lease makes no sense when you view item or create a new item. So it will be enabled only if one can edit the selected item */}
        {canRenderHierarchyItem &&
            <HierarchyComponent
                canEditHierarchyItem={canEdit}
                fields={elementInstance.element.fields}
                complexFields={elementInstance.element.complexFields}
                attachments={elementInstance.element.attachments}
                isMeasureMomentClosed={measureMoments.selectedMeasureMoment?.closed}
                audit={editPanelVisibility.audit}
                users={availableUsers}
                workflowTemplate={(elementInstance.element.elementId) ? workflowDictionary[selectedElementDefinition.systemId] : null}
                workflowState={(elementInstance.element.elementId) ? {
                    workflowState: elementInstance.workflowStateId,
                    assignedUser: elementInstance.assignedUserId,
                    workflow: workflowDictionary[selectedElementDefinition.systemId]?.id
                } : null}
                elementDefinition={selectedElementDefinition}
                hierarchyRights={hierarchyRights.hierarchyRights[elementInstance.element.elementId]}
                elementId={elementInstance.element.elementId}
                onAssignContributors={onAssignContributors}
                onSaveChanges={onSaveChanges}
                onCancelChanges={onCancelChanges}
                deleteLease={deleteLease}
                handleDeleteLease={handleDeleteLease}
                handleGone={handleGone}
                handleStillHere={handleStillHere}
                onIdle={onIdle}
                onActive={onActive}
                userIdentity={props.userIdentity}
                workflowDictionary={workflowDictionary}

                onFieldsDataChanged={(fields: FormInfo<ValueType>) => {
                    setElementInstance((prev) => {
                        const newInstance = _.cloneDeep(prev);
                        newInstance.element = {
                            ...prev.element,
                            fields: fields.values,
                            complexFields: fields.complex,
                            attachments: fields.attachments,
                        };
                        return newInstance;
                    });
                }}
                onWorkflowStateChanged={(workflowStateValue: Domain.Shared.AbstractElementWorkflowStatus) => {
                    setElementInstance((prev) => {
                        const newInstance = _.cloneDeep(prev);
                        newInstance.assignedUserId = workflowStateValue?.assignedUser;
                        newInstance.workflowStateId = workflowStateValue?.workflowState;
                        return newInstance;
                    });
                }}
                leaseInfo={leaseInfo}
                icons={props.icons?.items}
            />}

        {showNoLeaseDialog.visible &&
            <ModalDialog
                id='no-lease-dialog'
                settings={{
                    look: 'message',
                    title: '',
                    footer: <ModalDialogFooter
                        rightButtonText='Sluiten'
                        onRightButtonClick={() => setShowNoLeaseDialog({ visible: false })} />
                }}
            >
                <Text value={showNoLeaseDialog.message} />
            </ModalDialog>
        }

        {showImportDialog &&
            <ModalDialog
                id='import-dialog'
                settings={{
                    look: 'message',
                    title: 'Hiërarchieën overnemen',
                    footer: <ModalDialogFooter
                        rightButtonText='Aanmaken'
                        leftButtonText='Anuleren'
                        rightButtonDisabled={!fromMeasureMoment || !isImportDialogSaveButtonActive}
                        onRightButtonClick={onImport}
                        onLeftButtonClick={() => setShowImportDialog(false)} />
                }}
            >
                <SelectElement<Domain.Shared.MomentItem>
                    id='input-moment'
                    label='Moment'
                    optionItems={measureMomentsOptions}
                    value={fromMeasureMoment}
                    displayExpr='label'
                    clearable={true}
                    searchable={true}
                    customOptions={(item) => momentInputCustomOptions(item)}
                    customSingleValue={(item) => momentInputCustomOptions(item, { isFieldTemplate: true, placeholder: 'Kies...' })}
                    editorSettings={{
                        disabled: false,
                        restrictions: { required: true },
                        validationErrors: [],
                        onChange: setFromMeasureMoment
                    }}
                />
            </ModalDialog>
        }

        {showTimeoutMessage &&
            <ModalDialog settings={{
                look: 'interactive',
                title: 'Kopie maken',
                footer: <ModalDialogFooter rightButtonText='Sluiten' onRightButtonClick={() => setShowTimeoutMessage(false)} />
            }}>
                <Text value='Kopieren duurt wat langer dan verwacht.' />
            </ModalDialog >
        }
    </>);
};

type HierarchyComponentProps = {
    canEditHierarchyItem: boolean;
    complexFields: Domain.Shared.ComplexField[],
    hierarchyRights: Domain.Performance.HierarchyItemRight[],
    leaseInfo: Domain.Shared.AcquireLease;
    userIdentity: UserIdentity;
    audit: Domain.Dto.Shared.AuditEvent[];
    users: Domain.Shared.User[];
    isMeasureMomentClosed: boolean;
    workflowDictionary: Record<string, Domain.Shared.WorkflowTemplateWithStates>;
    elementDefinition: Domain.Shared.ElementDefinition;
    fields: Record<string, string>;
    attachments: Domain.Shared.Attachment[],
    workflowTemplate: Domain.Shared.WorkflowTemplateWithStates;
    workflowState: Domain.Shared.AbstractElementWorkflowStatus;
    elementId: string,
    onAssignContributors: (contributors: Record<string, boolean>) => void,
    onFieldsDataChanged: (fields: FormInfo<ValueType>) => void,
    onWorkflowStateChanged: (workflowStateValue: Domain.Shared.AbstractElementWorkflowStatus) => void,
    handleGone: () => void;
    onIdle: () => void;
    onActive: () => void;
    handleStillHere: () => void;
    handleDeleteLease: () => void;
    onSaveChanges: () => void;
    onCancelChanges: () => void;
    deleteLease: () => void;
    icons?: Record<string, Domain.Shared.SvgIcon>,
};

const HierarchyComponent: React.FC<HierarchyComponentProps> = (props) => {
    const {
        canEditHierarchyItem,
        complexFields,
        leaseInfo,
        userIdentity,
        fields,
        attachments,
        hierarchyRights,
        audit,
        users,
        isMeasureMomentClosed,
        workflowTemplate,
        workflowState,
        elementDefinition,
        elementId,
        handleGone,
        onIdle,
        onActive,
        handleStillHere,
        handleDeleteLease,
        onSaveChanges,
        onCancelChanges,
        deleteLease,
        onAssignContributors,
        onFieldsDataChanged,
        onWorkflowStateChanged,
    } = props;

    const commonProps = {
        userIdentity,
        complexFields,
        users,
        fields,
        attachments,
        audit,
        isMeasureMomentClosed,
        workflowTemplate,
        workflowState,
        elementDefinition,
        onAssignContributors,
        onFieldsDataChanged,
        onWorkflowStateChanged,
    };

    if (canEditHierarchyItem) {
        return (
            <LeaseWrapper leaseInfo={leaseInfo} onActive={onActive} handleGone={handleGone} onIdle={onIdle} handleStillHere={handleStillHere} handleDeleteLease={handleDeleteLease}>
                <HierarchyItem
                    {...commonProps}
                    leaseInfo={leaseInfo}
                    hierarchyRights={hierarchyRights}
                    mode={FormMode.Edit}
                    onSaveChanges={onSaveChanges}
                    userIdentity={userIdentity}
                    onCancelChanges={() => {
                        onCancelChanges();
                        deleteLease();
                    }}
                    icons={props.icons}
                />
            </LeaseWrapper>
        );
    }

    return (
        <HierarchyItem
            {...commonProps}
            mode={elementId ? FormMode.View : FormMode.AddNew}
            onSaveChanges={onSaveChanges}
            onCancelChanges={onCancelChanges}
            icons={props.icons}
        />);
};

const performanceElementDefinitionSystemIds = [
    Domain.SystemElementDefinitions.Performance.Policy,
    Domain.SystemElementDefinitions.Performance.Goal,
    Domain.SystemElementDefinitions.Performance.Achievement,
    Domain.SystemElementDefinitions.Performance.Activity
] as string[];

const getElementDefinition = (elementDefinitions: Domain.Shared.ElementDefinition[], id: string) => {
    if (!id) return
    return elementDefinitions.find(item => item.systemId === id);
};

const mapStateToProps = (state: State) => {
    return {
        elementDefinitions: state.elementdefinitions[ActionSource.Performance],
        modules: state.modules[ActionSource.Performance],
        users: state.users,
        workflowTemplates: state.workflowtemplates,
        measureMoments: state.measuremoments,
        icons: state.icons,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Performance, data: { moduleId: module?.id } }));
        },
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Performance, data: {} }));
        },
        fetchUsers: () => {
            dispatch(UsersActionCreator.set());
        },
        fetchWorkflowTemplates: () => {
            dispatch(WorkflowTemplateActionCreator.set());
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
        fetchIcons: () => {
            dispatch(SvgIconActionCreator.set());
        }
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Overview);
export { Component as index };
