import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';
import { AutoFocus } from '../../autofocus';
import { Overlay } from '../../overlay';
import { px } from '../../style';
import { palette } from '../../styleguide';
import { Label } from '../label';
import { HelpText } from '../../helptext';

export interface IColorResult {
    hsl: { h: number; s: number; l: number };
    hex: string;
    rgb: { r: number; g: number; b: number };
    hsv: { h: number; s: number; v: number };
    oldHue: number;
    source: string;
}

export interface IPickerProps {
    readonly id: string;
    readonly ariaLabel?: string;
    readonly title?: string;
    readonly size?: 'default' | 'small';
    readonly withBorder?: boolean;
    readonly color?: string;
    readonly colors?: string[];
    readonly defaultColor?: string;
    readonly disabled?: boolean;
    readonly onColorChange: (color: string) => void;
    readonly helpText?: { title?: string, text: string, type?: 'info' | 'hint' } | null;
}

export const colorPickerDefaultPalette = {
    colors: ['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#ff8a65', '#ba68c8', '#fff'],
};

export const ColorPicker = React.memo(({ id, ariaLabel, title, helpText, size, withBorder, color, colors, disabled, defaultColor, onColorChange }: IPickerProps) => {
    const [visible, setVisible] = useState(false);

    const hide = () => {
        setVisible(false);
    };

    const show = () => {
        setVisible(true);
    };

    const onColorChangeInternal = (colorResult: IColorResult) => {
        onColorChange(colorResult.hex);
    };

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === 'Escape') {
            hide();
        }
    };

    return (
        <Container className='color-picker' title={title}>
            {title && <Label>{title}</Label>}
            {helpText && helpText.text && <HelpText title={helpText.title}>{helpText.text}</HelpText>}
            <SwatchWrap btnSize={size}>
                <SwatchBtn id={`btn-color-picker-${id}`} btnSize={size} btnColor={color || defaultColor} onClick={show} aria-label={ariaLabel} disabled={disabled}>
                    Kleurvoorbeeld {title}
                </SwatchBtn>
                <Overlay relative visible={visible} onClick={hide}>
                    <Panel btnSize={size} btnColor={color || defaultColor} onKeyDown={onKeyDown}>
                        <AutoFocus key={visible ? 1 : 0} delay={100}>
                            <SketchPicker color={color || defaultColor} presetColors={colors || colorPickerDefaultPalette.colors}
                                onChange={onColorChangeInternal} disableAlpha={true} />
                        </AutoFocus>
                    </Panel>
                </Overlay>
            </SwatchWrap>
            {withBorder && <div style={{ height: '1rem', borderBottom: `2px solid ${palette.grey4}`, marginBottom: '20px' }} />}
        </Container>
    );
});

interface IBtnProps {
    readonly btnSize: string;
    readonly btnColor: string;
}

interface IWrapProps {
    readonly btnSize: string;
}

const Container = styled.div`
    margin-bottom: 2rem;
`;

const SwatchWrap = styled.div`
    position: relative;
    overflow: visible;
    width: ${(p: IWrapProps) => (p.btnSize === 'small' && px(40)) || px(56)};
`;

const SwatchBtn = styled.button`
    display: block;
    padding: 0;
    margin: 0;
    border: 0 none;
    outline: 0;
    text-indent: -9999px;
    cursor: ${(p) => (!p.disabled && 'pointer') || 'default'};

    width: ${(p: IBtnProps) => (p.btnSize === 'small' && px(40)) || px(56)};
    height: ${(p: IBtnProps) => (p.btnSize === 'small' && px(40)) || px(56)};
    border-radius: 50%;
    border: 1px solid ${palette.grey3};

    background-color: ${(p: IBtnProps) => p.btnColor};
    transition: background-color 0.3s ease;
`;

const Panel = styled.div`
    position: absolute;
    left: ${(p: IBtnProps) => (p.btnSize === 'small' && px(40 / 2)) || px(56 / 2)};
    top: ${px(10)};
    transform: translateX(-50%);
    box-shadow: 0px 5px 20px 0px rgba(75, 85, 246, 0.1);
    border-radius: 6px;

    input {
        border-radius: 3px;
        text-align: center;
        width: 100% !important;
    }

    :after {
        content: "";
        position: absolute;
        top: -9px;
        left: 101px;
        border-style: solid;
        border-width: 0 9px 9px;
        border-color: #FFFFFF transparent;
        z-index: 1;
    }

    :before {
        content: "";
        position: absolute;
        top: -10px;
        left: 100px;
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: ${palette.grey3b} transparent;
    }
`;
