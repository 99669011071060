import React from 'react';
import Styled from './index.styled';

/**
 * Represents a UI component that renders a breadcrumb bar.
 */
export const BreadcrumbBar: React.FC<React.PropsWithChildren<{}>> = (props) => {
    const getCrumbsElements = () => {
        return React.Children.toArray(props.children).map((crumb: React.ReactElement, index: number) => {
            if (index === 0) {
                return crumb;
            }

            return (
                <React.Fragment key={crumb.key}>
                    <Styled.SeperatorIcon />
                    {crumb}
                </React.Fragment>
            );
        });
    };

    return (
        <Styled.Container aria-label="Breadcrumb" className='mb-050'>
            {getCrumbsElements()}
        </Styled.Container>
    );
};

