import React, { useEffect, useMemo, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { AutoFocus, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import { LoadPanel, ScrollView } from '@liasincontrol/ui-devextreme';
import { ValueType, FormMode, FormInfo, ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { License } from '@liasincontrol/auth-service';
import { RoleForm } from '../RoleForm';

type Props = {
    mode: FormMode,
    role?: Domain.Shared.UserRole,
    licenses: License[],
    roles: Domain.Shared.UserRole[],
    onSave: (fields: Record<string, ValueType>, mode: FormMode) => void,
    onCancel: () => void,
    onError: (error: Domain.Shared.ErrorInfo) => void;
};

/**
 * Represents a UI component that renders the user role create/edit modal.
 */
export const RoleAddEdit: React.FC<Props> = (props) => {
    const [isBusy, setIsBusy] = useState<boolean>(props.mode !== FormMode.AddNew);
    const [role, setRole] = useState<Domain.Shared.UserRole>(undefined);
    const [formInfo, setFormInfo] = useState<FormInfo<ValueType>>({ values: undefined, isValid: true, isTouched: false });

    useEffect(() => {
        if (props.mode === FormMode.AddNew || !props.role?.id) return;
        setIsBusy(true);
        DataAccess.RolesDataAccess.get(props.role.id)
            .then((response) => {
                setRole(response.data);
            }).catch((err) => {
                setRole(undefined);
                props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            }).finally(() => setIsBusy(false));
    }, [props.role, props.mode]);

    const cleanRoleNames = useMemo(() => {
        return props.roles.reduce((acc, r) => {
            if (role && r.id !== role.id) acc.push(r.name);
            return acc;
        }, [] as string[])
    }, [props.roles, role]);

    return (<ModalDialog
        settings={{
            look: 'interactive',
            title: props.mode === FormMode.AddNew ? 'Rol aanmaken' : 'Wijzig algemene instellingen',
            footer:
                <ModalDialogFooter
                    leftButtonText="Sluiten"
                    rightButtonText="Opslaan"
                    onLeftButtonClick={props.onCancel}
                    onRightButtonClick={() => props.onSave(formInfo.values, props.mode)}
                    rightButtonDisabled={!formInfo?.isTouched || !formInfo?.isValid} />
        }}
    >
        <ScrollView
            elementAttr={{
                class: 'lias-modal-dialog-content'
            }}
            bounceEnabled={false}
            showScrollbar="always"
            useNative={true}
            scrollByThumb={true}
            direction="vertical"
        >
            <div className='p-050'>
                {isBusy
                    ? <LoadPanel visible={true} shading={false} showPane={false} />
                    : <AutoFocus>
                        <RoleForm
                            mode={props.mode}
                            role={role}
                            roleNames={cleanRoleNames}
                            licenses={props.licenses}
                            onFormDataChanged={setFormInfo}
                        />
                    </AutoFocus>
                }
            </div>
        </ScrollView>
    </ModalDialog>
    );
};
