import { useSelector } from "react-redux";
import { State, AjaxRequestStatus, useAppDispatch, UserGroupsActionCreator } from '@liasincontrol/redux-service';

export const useUserGroups = () => {

    const dispatch = useAppDispatch();
    const userGroups = useSelector((state: State) => state.usergroups);

    if (!userGroups?.status || userGroups?.status === AjaxRequestStatus.NotSet) {
        //this warning will be fixed in another PBI (the refactor redux PBI)
        dispatch(UserGroupsActionCreator.set() as any);
    }

    return userGroups;
};