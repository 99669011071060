import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { Grid as MuiGrid } from '@mui/material';
import { MultiSelectItem } from '@liasincontrol/ui-basics';
import { CheckboxElement, SelectElement } from '@liasincontrol/ui-elements';
import { ValidatorsDictionary, FormData, BasicValidator, FormMode, FormHelper, ValueType } from '@liasincontrol/core-service';
import _ from 'lodash';

type Props = {
    form: FormData<ValueType>,
    mode: FormMode,
    selectedDataStore: { id: string, kind: string, element: Domain.Publisher.DataStoreElement },
    structures: Domain.Finance.Structure[],
    elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    measureMoments: Domain.Shared.MeasureMoment[],
    onChange: (subForm: FormData<ValueType>) => void,
    inValidateForm?: () => void,
};

/**
 * Represents a UI component that renders a sub form to view/edit a data source with finance.
 */

const FinanceForm: React.FC<Props> = (props) => {
    const [measureMomentOptions, setMeasureMomentOptions] = useState<MultiSelectItem[]>([]);

    useEffect(() => {
        let momentOptions: MultiSelectItem[];

        if (_.isEmpty(props.form.values[SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds])) {
            momentOptions = props.measureMoments.map(mm => ({
                id: mm.id,
                label: mm.name,
                value: false,
                disabled: false,
            }));
        }
        else {
            const selectedMomentIds = JSON.parse(props.form.values[SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds]);
            momentOptions = props.measureMoments.map(mm => ({
                id: mm.id,
                label: mm.name,
                value: !!selectedMomentIds.find(id => mm.id === id),
                disabled: false,
            }));
        }

        setMeasureMomentOptions(momentOptions);
    }, [props.measureMoments, props.form.values[SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds]]);

    useEffect(() => {
        props.inValidateForm?.();
    }, [props.selectedDataStore]);

    const toggleMeasureMomentOption = (momentId: string, val: boolean): void => {
        const clonedItemList = [...measureMomentOptions];
        const measureMoment = clonedItemList.find((f) => f.id === momentId);
        clonedItemList.splice(clonedItemList.indexOf(measureMoment), 1, { ...measureMoment, value: val });
        const selectedMoments: MultiSelectItem[] = clonedItemList?.filter((t) => !!t.value);

        const fields = {
            [SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds]: _.isEmpty(selectedMoments) ? '' : JSON.stringify(selectedMoments.map(i => i.id)),
        };

        storeFormValues(fields);
    };

    const complexFieldDefinitions: Record<string, Domain.Shared.FieldDefinition> =
        props.elementdefinitions[Domain.SystemElementDefinitions.Pub.DataSource].complexFields[0].fields
            ?.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {});
    const structure = complexFieldDefinitions[SystemFieldDefinitions.Pub.DataSourceStructure];

    const storeFormValues = (values: Record<string, ValueType>): void => {
        const clonedForm = FormHelper.handleFormValuesChanged(values, props.form);
        const { errors } = FormHelper.validateForm(validators, clonedForm.values, clonedForm.validationErrors, []);
        clonedForm.validationErrors = { ...props.form.validationErrors, ...errors };
        clonedForm.isValid = !Object.values(clonedForm.validationErrors).some(a => a.length > 0);
        props.onChange(clonedForm);
    };

    return (<>
        <MuiGrid item xs={1} sm={2} md={4} key='datasource-structures'>
            <SelectElement<Domain.Finance.Structure>
                id='datasource-structures'
                key='key-datasource-structures'
                displayExpr='name'
                label={structure.label ? structure.label : structure.name}
                searchable={false}
                clearable={false}
                optionItems={props.structures}
                editorSettings={{
                    disabled: props.mode === FormMode.View,
                    restrictions: validators[Domain.SystemFieldDefinitions.Pub.DataSourceStructure] ? validators[Domain.SystemFieldDefinitions.Pub.DataSourceStructure].getRestrictions() : undefined,
                    validationErrors: props.form.touched[Domain.SystemFieldDefinitions.Pub.DataSourceStructure] ? props.form.validationErrors[Domain.SystemFieldDefinitions.Pub.DataSourceStructure] : [],
                    onChange: (item) => {
                        const fields = {
                            [Domain.SystemFieldDefinitions.Pub.DataSourceStructure]: item.rk,
                        };
                        storeFormValues(fields);
                    }
                }}
                value={props.structures?.find(fs => fs.rk === props.form.values[Domain.SystemFieldDefinitions.Pub.DataSourceStructure])}
            />
        </MuiGrid>
        {measureMomentOptions.map((option) => (
            <MuiGrid item xs={1} key={`key-measure-moment-wrapper-${option.id}`}>
                <CheckboxElement
                    id={option.id}
                    altLabel={option.label}
                    value={option.value}
                    helpText={option.label.length > 50 && {
                        text: option.label,
                        type: 'info'
                    }}
                    editorSettings={{
                        disabled: props.mode === FormMode.View,
                        restrictions: validators[SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds] ? validators[SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds].getRestrictions() : undefined,
                        validationErrors: props.form.touched[SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds] ? props.form.validationErrors[SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds] : [],
                        onChange: (val: boolean) => {
                            toggleMeasureMomentOption(option.id, val);
                        }
                    }}
                />
            </MuiGrid>
        ))}
    </>
    );
}

// /**
//  * Initialises the subform validators.
//  */
const validators: ValidatorsDictionary = {
    [SystemFieldDefinitions.Pub.DataSourceStructure]: new BasicValidator<string>({ required: true }),
};

export { FinanceForm };
