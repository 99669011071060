import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { NIL as NIL_UUID } from 'uuid';
import _ from 'lodash';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CheckBox } from 'devextreme-react/check-box';

import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { SystemElementDefinitions } from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper, DomUtils } from '@liasincontrol/core-service';
import { DxTemplate, DxTreeList, DxColumn, DxRemoteOperations, DxCustomStore, DxDataSource, DxSelection, DxToolbar, DxItem } from '@liasincontrol/ui-devextreme';
import { Icon, IconValue, IconSize, IconSet, Button, palette } from '@liasincontrol/ui-basics';
import { ToolboxIcon } from '../../../../../../components/Renderer/Element/Containers/_shared/Placeholder';
import * as Styled from '../../../../_shared/TreeList/index.styled';
import '../../../../_shared/TreeList/index.style.less';
import { taskListHiddenControlSystemIds } from '../../../../_shared/TreeList/constants';
import { AssignedPopover } from '../../shared/AssignedPopover';
import { AssigmentActions } from '..';
import { NoTasksWarningPopover } from './NoTasksWarningPopover';

type Props = {
    userIdentity: UserIdentity,
    publication: Domain.Publisher.Publication,
    workflowStates: Domain.Publisher.TaskManagerWorkflowState[],
    elementDefinitions: { [id: string]: Domain.Shared.ElementDefinition; },
    bulkMode: boolean,
    initialComponentsWithoutTasks: string[],
    toogleModal: (componentId: string, elementId: string, pageId: string, statusId: string) => void,
    updateComponentWorkflowState(pageId: string, componentId: string, currentStateId: string, pageComponentId: string),
    setError: (error: Domain.Shared.ErrorInfo) => void,
    setAssigments: (action: AssigmentActions, pageId: string, elementIds: string[]) => void,
    setState: (selected: boolean, statusId: string) => void,
};

type TreeViewNode = {
    id: string;
    elementId: string;
    elementName: string;
    elementDefinitionSystemId: Domain.SystemElementDefinitions.Pub;
    parentId: string;
    hasChildren: boolean;
    isValid: boolean;
    workflowStateId?: string;
    [key: string]: any;
};

const AssignTreeList = React.forwardRef<any, Props>((props, ref) => {
    const dataTree = useRef(null);
    const inProgressWorkflowStates = props.workflowStates?.filter(state => state.category === Domain.Shared.WorkflowCategoryType.InProgress);
    const columnWidth = inProgressWorkflowStates ? Math.floor(52 / inProgressWorkflowStates.length) : 52;

    useEffect(() => {
        customDataSource.reload();
    }, [props.workflowStates, props.bulkMode]);

    const fetchNode = (pageId: string) => {
        return DataAccess.TasksManagement.getNodesForPage(props.publication.publication.elementId, pageId, true)
            .then((response) => response.data)
            .catch((err) => {
                props.setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });
    };

    const fetchTaskList = (publicationId: string, pageId: string, controlId: string) => {
        const params: DataAccess.WorkflowTaskQueryParam = {
            includePageDetails: false,
            includeComponentDetails: false,
            includeStateDetails: true,
            includeUserDetails: true,
            onlyActive: false,
            onlyNotCompleted: false,
            onlyCurrentUser: false,
        };

        return DataAccess.WorkflowTask.getComponentWorkflowTask(publicationId, pageId, controlId, params)
            .then((response) => {
                return response.data;
            }).catch((err) => {
                return [];
            });
    }

    const getComponentName = (systemId: string) => {
        const element = Object.values(props.elementDefinitions).find((ed: Domain.Shared.ElementDefinition) => ed.systemId === systemId);
        return element?.label || element?.name || 'N/A';
    };

    const getNameTemplate: React.FC<{ data: TreeViewNode }> = ({ data }) => {
        let icon: JSX.Element = (<InsertDriveFileOutlinedIcon />);
        if (data.elementDefinitionSystemId !== SystemElementDefinitions.Pub.Page && data.elementDefinitionSystemId) {
            return (<Styled.Wrapper withBackGround={false} withPadding={true}>
                <Styled.ColoredToolboxIconWrapper color={palette.primary2}>
                    <ToolboxIcon systemId={data.elementDefinitionSystemId} />
                </Styled.ColoredToolboxIconWrapper>
                <Styled.Label>{getComponentName(data.elementDefinitionSystemId)} - {data.elementName}</Styled.Label>
            </Styled.Wrapper>);
        } else if (data.elementId === props.publication.rootPageId) {
            icon = (<HomeOutlinedIcon />);
        } else if (data.hasChildren) {
            icon = (<FolderOpenOutlinedIcon />);
        }

        return (<Styled.Wrapper withBackGround={false} withPadding={true}>
            <Styled.ColoredToolboxIconWrapper>{icon}</Styled.ColoredToolboxIconWrapper>
            <Styled.Label>{data.elementName}</Styled.Label>
        </Styled.Wrapper>);
    };

    const moveComponentStatusTemplate: React.FC<{ column: any; columnIndex: number, row: any, rowIndex: number, data: TreeViewNode }> = (e) => {
        if (e.data.elementDefinitionSystemId === SystemElementDefinitions.Pub.Page) return null;
        const currentState = props.workflowStates.find(wfs => wfs.id === e.data.workflowStateId);
        if (currentState == null) return null;
        const nextState = props.workflowStates.find(wfs => wfs.order === currentState.order + 1);
        return <Styled.WorkflowCell>
            <div className='name'>{currentState.name}</div>
            <Button
                btnbase="iconbuttons"
                btntype="small_transparent"
                icon={<EditOutlinedIcon />}
                id={`btn-set-component-workflow-state-${e.data.id}`}
                disabled={!nextState}
                onClick={() => props.updateComponentWorkflowState(e.data.parentId, e.data.elementId, currentState.id, e.data.id)}
            />
        </Styled.WorkflowCell>
    }

    const renderTitleHeader = (data) => {
        if (props.bulkMode)
            return <>
                <CheckBox
                    id={`input-${data.column.caption.id}`}
                    className="mr-050"
                    onValueChanged={(e) => props.setState(e.value, data.column.caption.id)} />
                {data.column.caption.name}
            </>
        else
            return data.column.caption.name;
    };

    const getWorkflowStatusTemplate: React.FC<{ column: any; data: TreeViewNode, columnIndex, rowIndex }> = ({ column, data, columnIndex, rowIndex }) => {
        return !props.bulkMode && data.elementDefinitionSystemId !== SystemElementDefinitions.Pub.Page
            ? (<Styled.Wrapper withBackGround={false} withPadding={false}>
                <Styled.Column>
                    <Icon value={IconValue.Customer} size={IconSize.small} iconSet={IconSet.default} />
                    <Styled.IconText>{data[column.caption.id] || 0} </Styled.IconText>
                    <Button
                        id={`btn-add-contributor-${data.id}`}
                        btnbase="iconbuttons"
                        btntype="small_transparent"
                        aria-label={`Gebruikers koppelen ${column.caption.name}`}
                        onClick={() => {
                            props.toogleModal(data.id, data.elementId, data.parentId, column.caption.id);
                        }}
                        icon={<EditOutlinedIcon />}
                    />
                </Styled.Column>
            </Styled.Wrapper>) : null;
    };

    const getWorkflowTaskTemplate: React.FC<{ column: any; data: TreeViewNode }> = ({ column, data }) => {
        return data.elementDefinitionSystemId !== SystemElementDefinitions.Pub.Page
            ? (
                <>
                    <NoTasksWarningPopover pageComponentId={data.id} visible={!props.bulkMode && !data.isValid} />
                    <AssignedPopover
                        controlId={data.elementId}
                        pageId={data.parentId}
                        publicationId={props.publication.publication.elementId}
                        componentName={column.caption.name}
                        workflowStates={props.workflowStates}
                        currentStateId={data.workflowStateId}
                        fetchTaskList={fetchTaskList}
                    />
                </>
            ) : null;
    };

    const updateCell = (value, key, state, componentState) => {
        const rowData = dataTree.current.instance().getNodeByKey(key)
        const pushedData = { ...rowData.data, [state]: value, workflowStateId: componentState };
        dataTree.current.instance().getDataSource().store().push([{ type: "update", data: pushedData, key: key }]);
    };

    const updateStepCell = (key, value) => {
        const rowData = dataTree.current.instance().getNodeByKey(key)
        const pushedData = { ...rowData.data, workflowStateId: value };
        dataTree.current.instance().getDataSource().store().push([{ type: "update", data: pushedData, key: key }]);
    };

    const deSelectAll = () => {
        dataTree.current.instance().deselectAll();
        dataTree.current.instance().repaint();
    };

    const updateInvalidComponents = (key: string, components: string[]) => {
        const rowData = dataTree.current.instance().getNodeByKey(key);
        const pushedData = { ...rowData.data, isValid: !components.includes(key) };
        dataTree.current.instance().getDataSource().store().push([{ type: "update", data: pushedData, key: key }]);
        dataTree.current.instance().repaint();
    }

    useImperativeHandle(ref, () => ({ updateCell, updateStepCell, deSelectAll, updateInvalidComponents }), []);

    const selecting = useRef(false);

    const onSelectionChange = (e) => {
        if (selecting.current) return;

        const asyncWrapper = async () => {
            //Select a page: select all first level components, select a component => if last checked => select parent
            if (e.currentSelectedRowKeys.length > 0) {
                const clickedId = e.currentSelectedRowKeys[0];
                const currentElement = e.selectedRowsData.find(e => e.id === clickedId);
                selecting.current = true;
                if (currentElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.Page) {
                    await e.component.expandRow(clickedId);
                    const node = e.component.getNodeByKey(clickedId);
                    if (node && node.data.elementDefinitionSystemId === SystemElementDefinitions.Pub.Page) {
                        const selectedIds = node.children
                            ?.filter((child) => (child.data.elementDefinitionSystemId !== SystemElementDefinitions.Pub.Page))
                            ?.map(child => ({ 'key': child.key, 'elementId': child.data.elementId }));
                        e.component.selectRows(selectedIds.map(e => e.key), true);
                        props.setAssigments(AssigmentActions.change, node.key, selectedIds.map(e => e.elementId));
                    }
                } else {
                    const node = e.component.getNodeByKey(clickedId);
                    const parent = node.parent;
                    let allChecked = true;
                    parent.children.forEach((child) => {
                        if (child.data.elementDefinitionSystemId !== SystemElementDefinitions.Pub.Page) {
                            if (!e.selectedRowKeys.includes(child.key)) {
                                allChecked = false;
                            }
                        }
                    })
                    if (allChecked) {
                        e.component.selectRows([parent.key], true);
                    }
                    props.setAssigments(AssigmentActions.add, parent.key, [node.data.elementId]);
                }
                selecting.current = false;
            }

            //DeSelect a page => deselect all first level components
            if (e.currentDeselectedRowKeys.length > 0) {
                const clickedId = e.currentDeselectedRowKeys[0];
                const node = e.component.getNodeByKey(clickedId);
                selecting.current = true;
                if (node && node.data.elementDefinitionSystemId === SystemElementDefinitions.Pub.Page) {
                    const deSelectedIds = node.children
                        ?.filter((child) => (child.data.elementDefinitionSystemId !== SystemElementDefinitions.Pub.Page))
                        ?.map(child => child.key);
                    await e.component.deselectRows(deSelectedIds);
                    props.setAssigments(AssigmentActions.change, node.key, null);

                } else {
                    await e.component.deselectRows([node.parent.key]);
                    props.setAssigments(AssigmentActions.remove, node.parent.key, [node.data.elementId]);
                }
                selecting.current = false;
            }

        };
        asyncWrapper();
    }

    const customDataSource = new DxDataSource({
        store: new DxCustomStore({
            key: "id",
            load: (loadOptions) => {
                const parentIdsParam = loadOptions.parentIds;
                const assignmentNodeLoaders: Promise<void | Domain.Publisher.AssignmentNode>[] = [];
                if (parentIdsParam) {
                    parentIdsParam.filter(id => id !== props.publication.rootPageId).forEach((id) => {
                        if (!id || id === NIL_UUID) assignmentNodeLoaders.push(fetchNode(props.publication.rootPageId))
                        else assignmentNodeLoaders.push(fetchNode(id));
                    });
                }
                // Multiple calls on datasource, concat only in rootpage in call
                return Promise.all(assignmentNodeLoaders)
                    .then((results) => {
                        let returnedArray: TreeViewNode[] = [];
                        returnedArray = results.flatMap((data: Domain.Publisher.AssignmentNode) => {
                            const pagesArray = data.pages.map((page) => ({
                                id: page.pageId,
                                elementId: page.pageId,
                                elementName: page.pageName,
                                elementDefinitionSystemId: page.elementDefinitionSystemId,
                                parentId: page.parentId === props.publication.rootPageId ? NIL_UUID : page.parentId,
                                hasChildren: page.hasChildren,
                                workflowStateId: null,
                                isValid: true,
                            }));

                            //Add elements per page above pages
                            if (data.components) {
                                const orderedComponents = data.components.sort((a, b) => a.order - b.order);
                                pagesArray.unshift(...orderedComponents
                                    ?.filter(component => !taskListHiddenControlSystemIds.includes(component.elementDefinitionSystemId))
                                    ?.map((component) => {
                                        const assignedFlat = component.workflowStateAssignedUsers.reduce((collection, item) => ({ ...collection, [item.stateId]: item.assignedUsers }), {});
                                        return {
                                            id: component.id,
                                            elementId: component.elementId,
                                            elementName: component.elementName,
                                            elementDefinitionSystemId: component.elementDefinitionSystemId,
                                            parentId: component.parentId,
                                            hasChildren: false,
                                            workflowStateId: component.workflowStateId,
                                            ...assignedFlat,
                                            isValid: !props.initialComponentsWithoutTasks?.includes(component.id),
                                        };
                                    }));
                            }
                            //Add rootNode to array as first element, homepage has never child pages
                            if (data.currentPage.pageId === props.publication.rootPageId) {
                                pagesArray.unshift({
                                    id: data.currentPage.pageId,
                                    elementId: data.currentPage.pageId,
                                    elementName: data.currentPage.pageName,
                                    elementDefinitionSystemId: data.currentPage.elementDefinitionSystemId,
                                    parentId: data.currentPage.parentId === props.publication.rootPageId ? NIL_UUID : data.currentPage.parentId,
                                    hasChildren: false || !!data.components?.length,
                                    workflowStateId: null,
                                    isValid: true,
                                });
                            }
                            return pagesArray as TreeViewNode[];
                        });

                        return {
                            data: returnedArray,
                        };
                    });
            },
            loadMode: 'processed',
            cacheRawData: false,
        }),
        pushAggregationTimeout: 0,
        reshapeOnPush: true,
        cacheRawData: false,
    });

    const exportToCsv = () => {
        const handleDownload = async () => {
            try {
                // Make GET request with axios
                const response = await DataAccess.WorkflowTask.exportTasks(props.publication.publication.elementId);
                // Get the content-disposition header to extract the filename
                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'publicatietaken.csv'; // Default filename

                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                    if (filenameMatch && filenameMatch[1]) {
                        fileName = filenameMatch[1].replace(/['"]/g, '');
                    }
                }

                // Create a blob from the response
                const fileType = response.headers['content-type'] || 'text/csv';
                const fileBlob = new Blob([response.data], { type: fileType });
                DomUtils.download(fileBlob, fileName);
            } catch (error) {
                console.error('Error while downloading file:', error);
            }
        };
        handleDownload();
    };

    return (<>
        {props.publication && <DxTreeList
            ref={dataTree}
            id='assignment-item-list'
            dataSource={customDataSource}
            showBorders={false}
            showColumnLines={false}
            showRowLines={true}
            columnAutoWidth={false}
            keyExpr='id'
            parentIdExpr='parentId'
            hasItemsExpr='hasChildren'
            loadPanel={{
                text: 'Laden...',
                shading: true,
            }}
            rootValue={NIL_UUID}
            defaultExpandedRowKeys={[props.publication.rootPageId]}
            scrolling={{
                mode: 'standard',
                rowRenderingMode: 'virtual',
                columnRenderingMode: 'virtual',
            }}
            renderAsync={true}
            repaintChangesOnly={true}
            onSelectionChanged={onSelectionChange}
        >
            <DxRemoteOperations filtering={true} />
            {props.bulkMode && <DxSelection mode='multiple' recursive={false} allowSelectAll={false} />}
            <DxColumn
                caption="Pagina's en pagina-onderdelen"
                dataField='elementName'
                allowSorting={false}
                allowFiltering={false}
                cellTemplate='nameTemplate'
                width='30%'
            />
            {inProgressWorkflowStates.map((status, index) => {
                return (
                    <DxColumn
                        key={status.id}
                        caption={status}
                        dataField={status.id}
                        allowSorting={false}
                        cellTemplate='workflowStatusTemplate'
                        headerCellRender={renderTitleHeader}
                        width={`${columnWidth}%`}
                        alignment='left'
                    >
                    </DxColumn>
                );
            })}
            {!props.bulkMode &&
                <DxColumn
                    caption="Actuele workflowstatus"
                    dataField='workflowStateId'
                    allowSorting={false}
                    allowFiltering={false}
                    cellTemplate='moveComponentStatusTemplate'
                    width={'13%'}
                />
            }
            <DxColumn
                caption=""
                dataField='id'
                allowSorting={false}
                allowFiltering={false}
                cellTemplate='workflowTaskTemplate'
                alignment='right'
            />
            <DxTemplate name='moveComponentStatusTemplate' render={moveComponentStatusTemplate} />
            <DxTemplate name='nameTemplate' render={getNameTemplate} />
            <DxTemplate name='workflowStatusTemplate' render={getWorkflowStatusTemplate} />
            <DxTemplate name='workflowTaskTemplate' render={getWorkflowTaskTemplate} />

            <DxToolbar>
                <DxItem
                    locateInMenu='auto'
                    location="after"
                    render={() => (<Button
                        btnbase='iconbuttons'
                        btntype='large_transparent'
                        aria-label='Exporteer naar Excel'
                        onClick={exportToCsv}
                        icon={<i className="dx-icon-xlsxfile dx-icon-custom-style"></i>}
                    />)}
                />
            </DxToolbar>
        </DxTreeList >}
    </>);
});

export const AssignmentsTreeList = React.memo(AssignTreeList, (prevProps, nextProps) => {
    return prevProps.workflowStates === nextProps.workflowStates
        && _.isEqual(prevProps.workflowStates, nextProps.workflowStates)
        && _.isEqual(prevProps.bulkMode, nextProps.bulkMode)
        && _.isEqual(prevProps.elementDefinitions, nextProps.elementDefinitions);
});
