import styled from 'styled-components';

const FlexBox = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right: 0.5rem;
    }

    button {
        margin-left: 0.5rem;
    }
`;

const OverflowEllipsis = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export { FlexBox, OverflowEllipsis };