import styled from 'styled-components';
import Styled, { lineHeightCoefficient } from "../../SharedControlElements/index.styled"

const PivotTableWrapper = styled(Styled.GridWrapper) <{
    primaryColor: string,
    primaryTextColor: string,
    textColor: string,
    textFontSize?: string,
    editMode: boolean
}>`
   .dx-pivotgrid {
        overflow: auto;
        color: ${({ textColor }) => textColor};

        .dx-area-description-cell {
            background-color: ${(p) => (p.primaryColor ? `${p.primaryColor}` : 'inherit')};
        }

        .dx-pivotgrid-area.dx-pivotgrid-horizontal-headers {

            thead.dx-pivotgrid-horizontal-headers td { //not an accident, child has the same class as the parent
                background-color: ${(p) => (p.primaryColor ? `${p.primaryColor}` : 'inherit')};

                color: ${(p) => (p.primaryTextColor ? `${p.primaryTextColor}` : 'inherit')};
            }

            .dx-expand-icon-container {
                .dx-expand {
                    color: ${(p) => (p.primaryTextColor ? `${p.primaryTextColor}` : 'inherit')};
                }
                font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px !important` : 'inherit')};
                line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px !important` : 'inherit')};
            }
            font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px !important` : 'inherit')};   
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px !important` : 'inherit')};
        }

        .dx-pivotgrid-area.dx-pivotgrid-vertical-headers {
            .dx-expand-icon-container {
                font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px !important` : 'inherit')};
                line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px !important` : 'inherit')};
            }
            font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px !important` : 'inherit')};   
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px !important` : 'inherit')};
        }

        .dx-pivotgrid-area.dx-pivotgrid-area-data {
            font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px !important` : 'inherit')};   
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px !important` : 'inherit')};
        }

        font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px !important` : 'inherit')};
        line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px !important` : 'inherit')};
        
        .dx-word-wrap .dx-pivotgrid-area {
            color: ${({ textColor }) => textColor};
            font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px !important` : 'inherit')};
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px !important` : 'inherit')};
        }
        
        .dx-pivotgrid-area-data tbody td {
            color: inherit;
        }
    }
`;

const styledComponents = { PivotTableWrapper };
export default styledComponents;
