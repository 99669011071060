import React, { useEffect, useMemo, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Bar, Button, Section } from '@liasincontrol/ui-basics';
import { ConnectingDialog } from '@liasincontrol/ui-elements';
import { ContextMenu, GridColumn, LsGrid, ScrollView } from '@liasincontrol/ui-devextreme';

type Props = {
    userGroup?: Domain.Shared.UserGroup,
    users: Domain.Shared.User[],
    disabled: boolean,
    onUsersAdded?: (userIds: string[]) => void,
};

/**
 * Represents a UI component that renders the user group form.
 */
export const UserInGroup: React.FC<Props> = (props) => {
    const [userIds, setUserIds] = useState<string[]>([]);
    const [addUser, setAddUser] = useState<boolean>(false);

    const availableColumns: GridColumn<Domain.Shared.User>[] = [
        {
            name: 'name',
            title: 'Leden',
        },
        {
            name: 'id',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => {
                if (props.disabled) return null;
                return <ContextMenu<Domain.Shared.UserGroup>
                    keyExpr='id'
                    item={data}
                    actions={[
                        {
                            action: () => {
                                const newUserIds = userIds.filter(id => id !== data.id);
                                props.onUsersAdded(newUserIds);
                            },
                            actionName: `delete-${data.id}`,
                            displayName: 'Verwijderen',
                            ariaLabel: `Verwijder ${data.name}`
                        }
                    ]}
                />
            },
        }
    ];

    const assignedusers = useMemo(() => props.users.filter(user => userIds.includes(user.id)), [props.users, userIds]);

    useEffect(() => {
        if (!props.userGroup) {
            return;
        }
        setUserIds(props.userGroup?.userIds || []);
    }, [props.userGroup]);

    const getAssignmentDialogElement = (userGroup: Domain.Shared.UserGroup) => {

        const listItems = props.users
            .filter((user) => !userIds.includes(user.id))
            .map((user) => { return { id: user.id, label: user.name, value: false } });

        return (
            <ConnectingDialog
                disableSaveButton={false}
                mandatory={false}
                title={`Gebruikers koppelen naar ${userGroup.name}`}
                listItems={listItems}
                selectedItems={assignedusers.map(user => ({ label: user.name, id: user.id, value: false }))}
                onCancelled={() => setAddUser(false)}
                onConfirmed={(users) => {
                    const selected = users.map(user => user.id);
                    setAddUser(false);
                    props.onUsersAdded(selected);
                }
                }
            />
        );
    };

    return (<>
        {!props.disabled && <Bar look='toolbar'>
            <Bar start>
                <Button id='btn-add-user-to-group' btnbase='textbuttons' btntype='medium_icon' onClick={() => setAddUser(true)}>
                    Leden toevoegen
                </Button>
            </Bar>
        </Bar>}

        {assignedusers &&
            <Section look='white'>
                <ScrollView
                    elementAttr={{
                        class: 'lias-modal-dialog-content'
                    }}
                    bounceEnabled={false}
                    showScrollbar="always"
                    useNative={true}
                    scrollByThumb={true}
                    direction="vertical"
                >
                    <div className='p-150'>
                        <LsGrid
                            dataSource={assignedusers}
                            columns={availableColumns}
                            enableColumnChooser={false}
                            searching={false}
                            showRowLines={true}
                            enableFilterRow={true}
                        />
                    </div>
                </ScrollView>
            </Section>
        }

        {addUser && getAssignmentDialogElement(props.userGroup)}
    </>
    );
};
