import axios, { AxiosRequestConfig } from 'axios';
import AuthService from '@liasincontrol/auth-service';
import { AppSettingsService } from '@liasincontrol/config-service';

const config: AxiosRequestConfig = {
    baseURL: '',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    timeout: AppSettingsService.getAppSettings().Api.Timeout
};

export const HttpClient = axios.create(config);

HttpClient.interceptors.request.use(async request => {
    await setBearerToken(request);

    return request;
});

HttpClient.interceptors.response.use(response => response, async error => {
    switch (error.response?.status) {
        case 401:
        case 403:
            if (error.response?.status === 403 && error.response?.data?.type?.startsWith("http://omnia-error/forbidden")) {
                // Custom 403/Forbidden response messages can be raised IF either:
                //  - the calling user is not an administrator
                //  - the tenant doesn't have a license for the functionality.
                //
                // In either case, there's no point to retry the call after a silent login.
                throw error;
            }

            const authInstance = await AuthService.getInstance();
            const userIdentity = await authInstance.getUser();

            //extract current route and supply it in case we need to do a full login.
            const currentUrl = window?.location?.pathname;

            if (userIdentity) {
                await authInstance.attemptSilentLogin(currentUrl);
            } else {
                // Will cause a full redirect. Interceptor code will not continue after this.
                await authInstance.signinRedirect({ state: { redirectUri: currentUrl } });
            }
            // Automatically retry the call. Should normally only happen after a successfull silent signin, otherwise we'll be redirected to a login page.
            if (error.config) {
                await setBearerToken(error.config);
                return axios.request(error.config);
            }

            // If we're here it means the silent sign in has failed and we didn't redirect for a full login.
            // So whatever error caused it, just let it flow to the caller.
            throw error;
    }
    throw error;
});

/**
 * Helper method to set the Bearer token on the http request.
 * @param request Axios HTTP request.
 */
const setBearerToken = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const authInstance = await AuthService.getInstance();
    const user = await authInstance.getUser();

    if (user?.access_token) {
        request.headers.Authorization = `Bearer ${user.access_token}`;
    }

    return request;
}

export default HttpClient;
