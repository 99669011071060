import React from "react";
import { GridColumn, LsGrid } from "@liasincontrol/ui-devextreme";
import { BudgetJournal } from "@liasincontrol/domain/src/aggregates/Finance/BudgetJournal/index";

export interface GridProps {
    loading: boolean;
    data: BudgetJournal[];
    onExport: (selectedRowKeys: string[]) => void;
}

export const Grid: React.FC<GridProps> = (props) => {
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);

    const selectionChanged = (e: string[]) => {
        setSelectedRowKeys(e);
    }

    const onExporting = () => {
        props.onExport(selectedRowKeys);
    }

    return (<LsGrid showToolbar={true} disabled={props.loading} allowSelectAll={true} dataSource={props.data} keyExpr={'id'} columns={getColumnConfiguration()}
        selectionMode="multiple"
        export={selectedRowKeys.length > 0}
        onExporting={onExporting}
        onSelectionChanged={selectionChanged}
        selectedRowKeys={selectedRowKeys}
    />);
}

const getColumnConfiguration = (): GridColumn<BudgetJournal>[] => {
    return [
        {
            name: 'code',
            title: 'Code',
            width: 150,
        },
        {
            name: 'name',
            title: 'Naam',
        },
        {
            name: 'budgetJournalGroupName',
            title: 'Journaalgroep',
        },
        {
            name: 'lastExportedOn',
            title: 'Exportdatum',
            dataType: 'date',
            formatter: 'datetime',
        },
        {
            name: 'lastExportedBy',
            title: 'Geëxporteerd door',
        },
    ];
};