import React, { useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { Button, ElementLabel, IconSize, SVGIcon, palette } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import DeleteIcon from '@mui/icons-material/Delete';
import { TemplateIcon, TemplateName, TemplateNameContainer } from '../../index.styled';
import { PageIconDialog } from '../../PageIconDialog';

type PageIconSettingsProps = {
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly icons: Record<string, Domain.Shared.SvgIcon>,
    readonly disabled: boolean,
    readonly onUpdateIcon: (iconId: string) => Promise<void>,
}

export const PageIconSettings: React.FC<PageIconSettingsProps> = (props) => {
    const [choosingIcon, setChoosingIcon] = useState<boolean>(false);
    const selectedIcon = props.sitemapNode.iconId ? props.icons[props.sitemapNode.iconId] : null;

    const onSavePageIcon = (iconId: string) => {
        props.onUpdateIcon(iconId).then(() => setChoosingIcon(false));
    };

    return (<>
        <MuiGrid item xs={12} md={8}>
            <ElementLabel>Pagina icoon</ElementLabel>
        </MuiGrid>
        <MuiGrid item xs={12} md={4}>
            {!selectedIcon && <Button
                id="btn-select-pageicon"
                btnbase="primarybuttons"
                btntype="small_noicon"
                onClick={() => setChoosingIcon(true)}
                aria-label="Selecteren"
                disabled={props.disabled}
            >
                Selecteren
            </Button>}
        </MuiGrid>
        <MuiGrid item xs={12}>
            <TemplateName className="grid">
                {selectedIcon
                    ? <>
                        <TemplateIcon>
                            <Button
                                id="btn-change-icon"
                                icon={<SVGIcon value={selectedIcon.svg} size={IconSize.medium} color={palette.grey1} />}
                                btnbase="iconbuttons"
                                btntype="medium_transparentmain"
                                onClick={() => setChoosingIcon(true)}
                                disabled={props.disabled}
                            />
                        </TemplateIcon>
                        <TemplateNameContainer>
                            <div className='icon-name'>
                                {selectedIcon?.name}
                            </div>
                            <Button
                                id="btn-delete-pageicon"
                                icon={<DeleteIcon />}
                                btnbase="iconbuttons"
                                btntype="medium_transparentmain"
                                onClick={() => onSavePageIcon(null)}
                                aria-label="Icoon wijzigen"
                                disabled={props.disabled}
                            />
                        </TemplateNameContainer>
                    </>
                    : <>
                        <TemplateIcon>
                            <CircleIcon sx={{ color: palette.grey3, fontSize: IconSize.medium }} />
                        </TemplateIcon>
                        <TemplateNameContainer>
                            <div className='icon-name'>
                                Niet geselecteerd
                            </div>
                        </TemplateNameContainer>
                    </>
                }
            </TemplateName>
        </MuiGrid>

        {choosingIcon && (
            <PageIconDialog
                icons={props.icons}
                currentIconId={selectedIcon?.id}
                onChange={onSavePageIcon}
                onCancel={() => setChoosingIcon(false)}
            />)
        }
    </>
    );
};
