import React, { useMemo } from 'react';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { AttachmentElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { AttachmentsHelper, FormInfo, ValueType } from '@liasincontrol/core-service';
import { StudioElementAttachmentItem } from './StudioElementAttachmentItem';
import Styled from './index.styled';

type Props = {
    fields: FormInfo<ValueType>,
    elementDefinition: Domain.Shared.ElementDefinition,
    audit: Domain.Dto.Shared.AuditEvent[],
    users: Domain.Shared.User[],
    disabled?: boolean,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onRemoveAttachment: (attachmentId: string) => void,
    onStartMultipleUpload: () => void,
    onFinishMultipleUpload: () => void,
};

const allowedStudioAttachmentTypes = [
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //  .docx
    "image/jpeg", // .jpg & .jpeg
    "application/pdf", // .pdf
    "image/png", // .png
    "application/vnd.ms-powerpoint", // .ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "application/vnd.ms-excel", // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "text/csv", // .csv
    "text/plain", // .txt
];

const maxAttachmentSize = 20 * 1024 * 1024; // 20 Mb

/**
 * Represents a UI component that renders the attachments section of a studio hierarchy item.
 */
export const StudioElementAttachments: React.FC<Props> = (props) => {
    // #region private helpers...
    const onDownloadAttachment = (attachmentId: string) => {
        const attachmentsList = _.cloneDeep(props.fields.attachments);
        const attachment = attachmentsList.find(item => item.id === attachmentId);

        AttachmentsHelper.getAttachmentInfo(attachmentId)
            .then(async (response) => {
                const fileResponse = await fetch(response.data.urlWithSasToken, { method: 'GET' });
                const blob = await fileResponse.blob();

                const file = new File([blob], `${attachment.name}.${attachment.fileExtension}`, { type: blob.type });
                return file;
            })
            .then((file) => {
                saveAs(file, file.name);
            });
    };
    // #endregion private heplers...

    const attachmentItemsElements = useMemo(() => {
        const attachments = props.fields.attachments && props.fields.attachments.filter(item => !item.deleted);
        if (attachments.length === 0) {
            return null;
        }

        const attchmentElements = attachments.map((attachment) => {
            const audit = props.audit?.filter(auditItem => auditItem.fieldValue === attachment.id) || [];
            return (
                <StudioElementAttachmentItem
                    attachment={attachment}
                    key={attachment.id}
                    audit={audit}
                    users={props.users}
                    disabled={props.disabled}
                    onDownloadAttachment={onDownloadAttachment}
                    onRemoveAttachment={props.onRemoveAttachment}
                />
            );
        });

        return <Styled.ContentAttachments>
            {attchmentElements}
        </Styled.ContentAttachments>;
    }, [props.fields.attachments, props.audit]);

    return (<>
        <Styled.Content>
            <Styled.Wrapper>
                {attachmentItemsElements}
                <AttachmentElement
                    id='attachments'
                    editorSettings={{
                        onChange: () => { /* Do nothing because patching is handled on upload attachment. */ },
                        restrictions:
                        {
                            maxFileSize: maxAttachmentSize,
                            allowedFileTypes: allowedStudioAttachmentTypes,
                        },
                        validationErrors: [],
                        disabled: props.disabled,
                    }}
                    allowMultiple={true}
                    value={undefined} // the existing attachments shouldn't be loaded into the attachment editor.
                    onLoadAttachment={() => { return null; }}
                    onUploadAttachment={props.onUploadAttachment}
                    onStartMultipleUpload={props.onStartMultipleUpload}
                    onFinishMultipleUpload={props.onFinishMultipleUpload}
                />
            </Styled.Wrapper>
        </Styled.Content>
    </>);
};