import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { FloaterButtons as Presentation } from './Presentation';
import { IFloaterButtonsProps } from './Props';

const thresholdScrollTop = 200; // px

export interface IFloaterButtonsState {
    readonly active: boolean;
    readonly bottom: number;
    readonly right: number;
}

const emptyState = {
    active: false,
    bottom: 0,
    right: 0,
};

const hasWindow = typeof window !== 'undefined'; // for unit tests & server rendering

export const FloaterButtons = ({ editable, onBackToTop, onEdit, scrollToElement }: IFloaterButtonsProps) => {
    const [state, setState] = useState(emptyState);

    const refInner = useRef<Element>(null);
    const refScrolling = useScrollingAncestor(refInner);

    const scrollToTargetElement = (element: Element) => {
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const onScrollToTop = useCallback(() => {
        if (scrollToElement) {
            if (typeof scrollToElement === 'string') {
                const targetElement = document.getElementById(scrollToElement);
                if (targetElement) {
                    scrollToTargetElement(targetElement);
                }
            } else if (scrollToElement instanceof HTMLElement) {
                scrollToTargetElement(scrollToElement);
            }
        } else if (refScrolling) {
            refScrolling.scrollTo({ behavior: 'smooth', top: 0 });
        }

        if (onBackToTop) {
            onBackToTop();
        }
    }, [onBackToTop, refScrolling, scrollToElement]);

    const onUpdate = useCallback(() => {
        if (!refScrolling || !refInner) {
            return null;
        }
        const withinElement = isHtmlElement(refScrolling) && refScrolling !== document.scrollingElement;
        const { bottom, right } = withinElement ? getBottomRightWithinViewport(refScrolling) : getWindowBounds();
        const active = refScrolling.scrollTop > thresholdScrollTop;
        if (bottom !== state.bottom || right !== state.right || active !== state.active) {
            setState({ bottom, right, active });
        }
    }, [state, refScrolling, refInner]);

    useLayoutEffect(() => {
        // Keep eye on window
        if (hasWindow) {
            window.addEventListener('scroll', onUpdate, { passive: true });
            return () => {
                window.removeEventListener('scroll', onUpdate);
            };
        }
    }, [onUpdate]);

    useLayoutEffect(() => {
        // Keep eye on container
        if (refScrolling) {
            refScrolling.addEventListener('scroll', onUpdate, { passive: true });
            return () => {
                refScrolling.removeEventListener('scroll', onUpdate);
            };
        }
    }, [onUpdate, refScrolling]);

    return (
        <Presentation
            active={state.active}
            editable={editable}
            onEdit={onEdit}
            onBackToTop={onScrollToTop}
            right={state.right}
            bottom={state.bottom}
            ref={refInner} />
    );
};

const useScrollingAncestor = (ref: React.MutableRefObject<Element>) => {
    const [container, setContainer] = useState<Element | null>(null);
    useLayoutEffect(() => {
        const parent = ref.current && ref.current.parentNode;
        const newContainer = isHtmlElement(parent) ? findScrollingAncestor(ref.current) : null;
        setContainer(newContainer);
    }, [ref]);
    return container;
};

// tslint:disable:no-magic-numbers
const isHtmlElement = (el: Node): el is HTMLElement => el && el.nodeType === 1;
const isDocumentNode = (el: Node): el is Document => el && el.nodeType === 9;
// tslint:enable:no-magic-numbers

const getWindowBounds = hasWindow
    ? () => ({
        right: window.innerWidth,
        bottom: window.innerHeight,
    })
    : () => ({
        right: 0,
        bottom: 0,
    });

const findScrollingAncestor = (container: Element) => {
    while (container && hasWindow && window.getComputedStyle(container).overflow === `visible`) {
        if (isDocumentNode(container.parentNode)) {
            return document.scrollingElement;
        } else if (isHtmlElement(container.parentNode)) {
            container = container.parentNode as Element;
        } else {
            container = null;
        }
    }
    // Document scrolling element / element with overflow != visible / null
    return container;
};

const getBottomRightWithinViewport = (ref: Element) => {
    const bounds = ref.getBoundingClientRect();
    const transformed = Boolean(hasWindow && getComputedStyle(ref).transform);
    if (transformed) {
        // HACK: dumb exception, due to transform placed on scrolling container of isolation; other layers not taken into consideration
        return { right: bounds.width, bottom: bounds.height };
    }
    return { right: bounds.right, bottom: bounds.bottom };
};
