import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,        // phone
            sm: 768,      // tablet
            md: 1024,     // laptop
            lg: 1366,     // desktop
            xl: 1920,     // widescreen
            xxl: 2560,    // ultra wides
        },
    },
});


export default theme;