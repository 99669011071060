import * as Domain from '@liasincontrol/domain';
import { JsonUtils } from '@liasincontrol/core-service';
import { IDataItemProps } from '@liasincontrol/ui-basics';

const getFieldEditorSettings = (fieldDefinition: Domain.Shared.FieldDefinition): Domain.Studio.FieldEditorControlSettings => {
    return JsonUtils.toJson<Domain.Studio.FieldEditorControlSettings>(fieldDefinition?.editorSettings, undefined);
};

const getFieldDisplayType = (fieldDefinition: Domain.Shared.FieldDefinition): string => {
    switch (fieldDefinition.dataType) {
        case Domain.Shared.FieldDataType.String.toString():
            const editorSettings: Domain.Studio.FieldEditorControlSettings = getFieldEditorSettings(fieldDefinition);
            switch (editorSettings?.stringDisplayFormat) {
                case Domain.Studio.StringDisplayType.SingleLine:
                    return "Enkele regel tekst";
                case Domain.Studio.StringDisplayType.MultiLine:
                    return "Meerdere regels tekst";
                case Domain.Studio.StringDisplayType.HTML:
                    return "Tekst met opmaak";
                case Domain.Studio.StringDisplayType.HyperLink:
                    return "Hyperlink";
                default: return "Tekst";
            }
        case Domain.Shared.FieldDataType.DateTimeOffset.toString(): return "Datum";
        case Domain.Shared.FieldDataType.Decimal.toString(): return "Numeriek";
        case Domain.Shared.FieldDataType.Integer.toString(): return "Geheel getal";
        case Domain.Shared.FieldDataType.Option.toString(): return "Lijst met waarden";
        case Domain.Shared.FieldDataType.Boolean.toString(): return "Ja/Nee";
    }
    return fieldDefinition.dataType;
};

const formatOptionItems: IDataItemProps<string>[] = [
    {
        label: '123,00',
        value: '##0.##',
    },
    {
        label: '1.234,00',
        value: '#,##0.##',
    },
    {
        label: '123',
        value: '#',
    },
    {
        label: '€ 123,00',
        value: '€ ##0.##',
    },
    {
        label: '€ 1.234,00',
        value: '€ #,##0.##',
    },
    {
        label: '€ 123',
        value: '€ #',
    },
    {
        label: '123,00000',
        value: '#0.#####',
    }
];

export { getFieldDisplayType, getFieldEditorSettings, formatOptionItems };
