import React, { useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { Button, ElementLabel, palette, IconSize } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { ValidationUtils } from '@liasincontrol/core-service';
import LinkIcon from '@mui/icons-material/Link';
import { TemplateIcon, TemplateName, TemplateNameContainer } from '../../index.styled';
import { PageTemplateDialog } from '../../PageTemplateDialog';

type PageTemplateSettingsProps = {
    readonly rootId: string,
    readonly publicationId: string,
    readonly pageDesigns: Domain.Publisher.PageDesign[],
    readonly disabled: boolean,
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly selectedTemplateName: string,
    readonly elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    readonly onChangeTemplate: (newTemplateId: string, newTemplateName: string, pageIds: string[], parentId?: string) => void;
}

export const PageTemplateSettings: React.FC<PageTemplateSettingsProps> = (props) => {
    const [choosingTemplate, setChoosingTemplate] = useState<boolean>(false);

    const onInformationTemplateChange = (templateId: string, templateName: string) => {
        setChoosingTemplate(false);
        props.onChangeTemplate(templateId, templateName, [props.sitemapNode.elementId], null);
    };
    const noTemplate = ValidationUtils.isEmptyGuid(props.sitemapNode.pageTemplateId);

    return (<>
        <MuiGrid item xs={12} md={8}>
            <ElementLabel>Gekoppeld sjabloon</ElementLabel>
        </MuiGrid>
        <MuiGrid item xs={12} md={4}>
            <Button
                id="btn-select-template"
                btnbase={noTemplate ? 'primarybuttons' : 'ghostbuttons'}
                btntype="small_noicon"
                onClick={() => setChoosingTemplate(true)}
                aria-label="Sjabloon selecteren"
                disabled={props.disabled}
            >
                {noTemplate ? 'Selecteren' : 'Bewerken'}
            </Button>
        </MuiGrid>
        <MuiGrid item xs={12}>
            <TemplateName className="grid">
                {noTemplate
                    ? <>
                        <TemplateIcon>
                            <LinkIcon sx={{ color: palette.grey3, fontSize: IconSize.medium }} />
                        </TemplateIcon>
                        <TemplateNameContainer>
                            <div className='icon-name'>
                                Niet geselecteerd
                            </div>
                        </TemplateNameContainer>
                    </>
                    : <>
                        <TemplateIcon>
                            <Button
                                id="btn-change-icon"
                                icon={<LinkIcon />}
                                btnbase="iconbuttons"
                                btntype="medium_transparentmain"
                                onClick={() => setChoosingTemplate(true)}
                                disabled={props.disabled}
                            />
                        </TemplateIcon>
                        <TemplateNameContainer>
                            <div className='icon-name'>
                                {props.selectedTemplateName}
                            </div>
                        </TemplateNameContainer>
                    </>
                }
            </TemplateName>
        </MuiGrid>

        {choosingTemplate && (
            <PageTemplateDialog
                publicationId={props.publicationId}
                pageDesigns={props.pageDesigns}
                elementDefinitions={props.elementdefinitions}
                id={props.rootId}
                currentTemplateId={props.sitemapNode.pageTemplateId}
                onChange={onInformationTemplateChange}
                onCancel={() => setChoosingTemplate(false)}
            />
        )}
    </>);
};