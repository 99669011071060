import React, { useState } from "react";
import {
    ErrorOverlay, Heading2, PageTitle, ResetZIndex,
    Section, Wrapper, WrapperContent
} from '@liasincontrol/ui-basics';
import { Finance as DataAccess } from '@liasincontrol/data-service';
import { BudgetJournal } from "@liasincontrol/domain/src/aggregates/Finance/BudgetJournal/index";
import { ApiErrorReportingHelper, DomUtils, useUserSettings } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { Filter } from "./Filter";
import { Grid } from "./Grid";

export const Export: React.FC = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<BudgetJournal[]>(null);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const userSettingsContext = useUserSettings();
    
    const onFilter = (baseYear: number, measureMomentId: string) => {
        setLoading(true);
        DataAccess.BudgetJournalDataAccessor.getExportableBudgetJournals(baseYear, measureMomentId).then((result: BudgetJournal[]) => {
            setData(result);
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        }).finally(() => {
            setLoading(false);
        });
    };

    const onExport = async (selectedRowKeys: string[]) => {
        setLoading(true);
        try {
            const response = await DataAccess.BudgetJournalDataAccessor.exportBudgetJournals(selectedRowKeys)
            DomUtils.download(response.blob, response.fileName);
            const data = await DataAccess.BudgetJournalDataAccessor.getExportableBudgetJournals(userSettingsContext.baseYear, userSettingsContext.measureMomentId);
            setData(data);
        } catch (error) {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error));
        } finally {
            setLoading(false);
        }
    }

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Exporteren</Heading2>
                </PageTitle>
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <Section look='white'>
                        <Filter onFilter={onFilter} loading={loading} />
                    </Section>
                    {data &&
                        <Section look="white" fixedWidth={true}>
                            <ResetZIndex>
                                <Grid loading={loading} data={data} onExport={onExport} />
                            </ResetZIndex>
                        </Section>
                    }
                </ErrorOverlay>
            </WrapperContent>
        </Wrapper >
    );
}