import styled from 'styled-components';

const Wrapper = styled.div`
    user-select: auto;
    border-bottom: 1px solid #f4f4f8;
    transition: border-color 0.3s ease;

    .htmlEditor {
        min-height: min(15vh, 150px);
        max-height: max(35vh, 500px);
    }
`;

const styledComponents = { Wrapper };

export default styledComponents;
