import React from 'react';
import _, { Dictionary } from 'lodash';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { HyperlinkElement, RichTextElement, TextElement } from '@liasincontrol/ui-elements';
import { AnyFormData, ValidatorsDictionary, ValidationUtils } from '@liasincontrol/core-service';
import { SystemFieldDefinitions } from '@liasincontrol/domain';

type Props = {
    activeRichtextEditor: string,
    fieldDefinitions: Dictionary<Domain.Shared.FieldDefinition>,
    isEditing: boolean,
    form: AnyFormData,
    validators: ValidatorsDictionary,
    onChange: (value: string, fieldDefinitionId: string) => void,
    setActiveRichtextEditor: (toolbarId: string) => void,
};

/**
 * Represents a UI component that renders the Webinfo section form of the Publication information page.
 */
export const WebinfoSection: React.FC<Props> = (props) => {
    if (_.isEmpty(props.form) || _.isEmpty(props.form.values)) {
        return null;
    }

    const accessibilityStatementDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.AccessibilityStatement];
    const privacyStatementDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.PrivacyStatement];
    const portalUrlDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.PortalUrl];
    const googleAnalyticsCodeFieldDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.GoogleAnalyticsCode];

    return (
        <MuiGrid container spacing={2} justifyContent="flex-start" alignItems="flex-end">
            <MuiGrid item xs={12} md={6} key={portalUrlDefinition.id}>
                <HyperlinkElement
                    id='pub-portalUrl'
                    label={portalUrlDefinition.label ? portalUrlDefinition.label : portalUrlDefinition.name}
                    helpText={{ title: portalUrlDefinition.helpTextTitle, text: portalUrlDefinition.helpText }}
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, portalUrlDefinition.systemId)}
                    value={props.form.values[portalUrlDefinition.systemId] as string}
                />
            </MuiGrid>
            <MuiGrid item xs={12} md={6} key={googleAnalyticsCodeFieldDefinition.id}>
                <TextElement
                    id='pub-google-analytics-code'
                    label={googleAnalyticsCodeFieldDefinition.label ? googleAnalyticsCodeFieldDefinition.label : googleAnalyticsCodeFieldDefinition.name}
                    helpText={{ title: googleAnalyticsCodeFieldDefinition.helpTextTitle, text: googleAnalyticsCodeFieldDefinition.helpText }}
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, googleAnalyticsCodeFieldDefinition.systemId)}
                    value={props.form.values[googleAnalyticsCodeFieldDefinition.systemId] as string} />
            </MuiGrid>

            <MuiGrid item xs={12} md={12} key={accessibilityStatementDefinition.id}>
                <RichTextElement
                    id='pub-accessibilityStatement'
                    label={accessibilityStatementDefinition.label ? accessibilityStatementDefinition.label : accessibilityStatementDefinition.name}
                    helpText={{ title: accessibilityStatementDefinition.helpTextTitle, text: accessibilityStatementDefinition.helpText }}
                    isToolbarHidden={!props.isEditing || _.isEmpty(props.activeRichtextEditor) || props.activeRichtextEditor !== 'pub-accessibilityStatement'}
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, accessibilityStatementDefinition.systemId)}
                    value={props.form.values[accessibilityStatementDefinition.systemId] as string}
                    height={270}
                />
            </MuiGrid>
            <MuiGrid item xs={12} md={12} key={privacyStatementDefinition.id}>
                <RichTextElement
                    id='pub-privacyStatement'
                    label={privacyStatementDefinition.label ? privacyStatementDefinition.label : privacyStatementDefinition.name}
                    helpText={{ title: privacyStatementDefinition.helpTextTitle, text: privacyStatementDefinition.helpText }}
                    isToolbarHidden={!props.isEditing || _.isEmpty(props.activeRichtextEditor) || props.activeRichtextEditor !== 'pub-privacyStatement'}
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, privacyStatementDefinition.systemId)}
                    value={props.form.values[privacyStatementDefinition.systemId] as string}
                    height={270}
                />
            </MuiGrid>
        </MuiGrid>
    );
};
