import React from "react";
import { LsPivotTable, PivotCell, PivotGridDataSource, ToolbarItem } from "@liasincontrol/ui-devextreme";
import { CheckboxElement } from '@liasincontrol/ui-elements';
import { Section } from '@liasincontrol/ui-basics';

export type BudgetDevGridProps = {
    dataSource: PivotGridDataSource;
    onCellClick?: (e) => void;
    divideBy: number;
    setDivideBy: (val: number) => void;
}

const bgRed = "#fff2f2";
const fgRed = "#620000";

const bgGreen = "#f2fff2";
const fgGreen = "#004400";

const isColumnFullyExpanded = (cell: PivotCell) => {
    if (!cell.columnPath) return false;
    return (cell.columnPath[cell.columnPath.length - 1] === 'Baten' || cell.columnPath[cell.columnPath.length - 1] === 'Lasten');
}

const isColumnDataCell = (cell: PivotCell) => {
    return cell.columnType === "D" && cell.area === "data";
}

const isColumnTotalCell = (cell: PivotCell) => {
    return cell.columnType === "T" && cell.area === "data";
}

const isRowOrColumnTotal = (cell: PivotCell) => {
    return cell.columnType === 'T' || cell.rowType === 'T' || cell.rowType === 'GT';
}

const applyColors = (cell: PivotCell, cellElement: HTMLElement) => {
    if (cell.value > 0) {
        cellElement.style.backgroundColor = bgRed;
        cellElement.style.color = fgRed;
    }

    if (cell.value < 0) {
        cellElement.style.backgroundColor = bgGreen;
        cellElement.style.color = fgGreen;
    }
}

const onCellPrepared = (cell: PivotCell, cellElement: HTMLElement) => {
    if (!cell.value) return;

    if (!isColumnFullyExpanded(cell) && (isColumnDataCell(cell) || isColumnTotalCell(cell))) {
        applyColors(cell, cellElement);
    }

    if (isRowOrColumnTotal(cell)) {
        cellElement.style.fontWeight = 'bold';
    }
};

export const BudgetDevelopmentGrid: React.FC<BudgetDevGridProps> = (props: BudgetDevGridProps) => {
    return (<Section look='white'>
        <LsPivotTable
            dataSource={props.dataSource}
            showColumnGrandTotals={false}
            showColumnTotals={true}
            scrolling={true}
            onCellPrepared={onCellPrepared}
            onCellClick={props.onCellClick}
            showToolbar={true}
            customToolbarItems={[
                <ToolbarItem location="before" locateInMenu="auto" cssClass='pr-100'>
                    <CheckboxElement
                        id='input-divideBy'
                        label='Bedragen delen'
                        altLabel='Delen door 1000'
                        value={props.divideBy === 1000 ? true : false}
                        editorSettings={{
                            withoutFeedback: true,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (val) => {
                                props.setDivideBy(val ? 1000 : 1);
                            },
                        }}
                    />
                </ToolbarItem>
            ]}
        />
    </Section>);
};
