import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { DeviceFrame, EditingToolbar, ErrorOverlay, IDataItemProps, ModalDialog } from '@liasincontrol/ui-basics';
import { Actions, ActionType } from '@liasincontrol/userrights-service';
import { SitemapSettings } from './Settings';
import { HierarchyChanges } from '../index';
import { PageLayoutCol, PageLayoutGrid } from '../../../../../_shared/EditorLayoutGrid';
import { TreeUtils } from '../../../../../../helpers';
import { noop } from 'lodash';

const MAX_TREE_DEPTH = 4;

type Props = {
    readonly publicationId: string,
    readonly selectedTemplateElement: Domain.Publisher.TemplateElement,
    readonly selectedPageElement: Domain.Publisher.PageElement,
    readonly selectedPublishProfiles: IDataItemProps<string>[],
    readonly availablePublishProfiles: IDataItemProps<string>[],
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    readonly fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>,
    readonly rootNode: Domain.Publisher.SitemapNode,
    readonly childTemplate?: { id: string, name: string },
    readonly error: Domain.Shared.ErrorInfo,
    readonly pageDesigns: Domain.Publisher.PageDesign[],
    readonly hasAvailableTemplates: boolean,
    readonly icons: Record<string, Domain.Shared.SvgIcon>,
    readonly attachment: { [x: string]: Domain.Shared.Attachment },
    readonly variables: { label: string, values: Domain.Shared.ComplexFieldItem[] },
    readonly onUpdatePageVariable: (variable: Record<string, string>) => void,
    readonly onChangeRequest: (changes: HierarchyChanges) => void,
    readonly onCreate: (ownerId: string, name: string) => void,
    readonly onChangeTemplate: (newTemplateId: string, newTemplateName: string, pageIds: string[], childTemplateFor?: string) => void,
    readonly onChangePagePublish: (publishProfiles: IDataItemProps<string>[]) => void,
    readonly onMove: (ownerId: string, movedPageId: string, beforePageId?: string) => void,
    readonly onNavigate: (to: string) => void,
    readonly onCancelChanges: () => void,
    readonly onResetError?: () => void,
    readonly onRetryError?: () => void,
    readonly canPerformAction?: (action: Actions, actionType: ActionType) => boolean,
    readonly onRefresh: () => void,
    readonly onLoadAttachment: (id: string) => Promise<Blob>,
    readonly onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    readonly onRemoveAttachment: (id: string) => void,
    children?: React.ReactNode,

};

/**
 * Represents a UI component that renders the manipulation page of a publication's sitemap.
 */
export const SitemapEditor: React.FC<Props> = (props) => {
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const maxDepthReached = TreeUtils.findDepth(props.rootNode, props.sitemapNode.elementId, 0) >= MAX_TREE_DEPTH;

    return (
        <ModalDialog
            customPadding
            edgeToolbar={
                props.sitemapNode && !props.error &&
                <SitemapSettings
                    {...props}
                    rootId={props.rootNode.elementId}
                    isVisible={isVisible}
                    maxDeptReached={maxDepthReached}
                    onBack={() => {
                        const parent = TreeUtils.findParent(props.rootNode, props.sitemapNode.elementId);
                        props.onNavigate(parent?.elementId);
                    }}
                    onToggleVisibility={() => setIsVisible((prevValue => !prevValue))}
                />}
            toolbars={<EditingToolbar
                id={props.sitemapNode.elementId}
                look="default"
                isVisible={true}
                readonly={true}
                onSave={noop}
                onCancel={props.onCancelChanges}
            />}>
            <PageLayoutGrid wide={!isVisible}>
                <DeviceFrame device="desktop" />
                <PageLayoutCol className="desktop" pose="desktop" withParent={false}>
                    <ErrorOverlay error={props.error?.message} errorDetails={props.error?.details} onRetry={props.error?.canRetry && props.onRetryError ? props.onRetryError : null} onBack={props.error?.canGoBack && props.onResetError ? props.onResetError : null}>
                        {props.children}
                    </ErrorOverlay>
                </PageLayoutCol>
            </PageLayoutGrid>
        </ModalDialog>
    );
};
