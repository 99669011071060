import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Grid as MuiGrid } from '@mui/material';
import { AutoFocus, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import { ValueType, AnyFormData, FormHelper, ValidatorsDictionary, TextValidator, ValidationErrorData, FormMode } from '@liasincontrol/core-service';
import { TextElement } from '@liasincontrol/ui-elements';

type Props = {
    userGroupNames?: string[],
    mode: FormMode,
    group?: Domain.Shared.UserGroup,
    onSave: (name: string) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the user group create page.
 */
export const UserGroupAdd: React.FC<Props> = (props) => {
    const [form, setForm] = useState<AnyFormData>(initFormData(undefined));

    useEffect(() => {
        if (!props.group) {
            return;
        }
        setForm(initFormData(props.group));
    }, [props.group]);

    const validators = getValidators(props.userGroupNames);

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators) => {
        const newForm = FormHelper.validateAndStoreFormValue<AnyFormData>(form, value, validators, systemId);
        setForm(newForm);
    };

    return (
        <ModalDialog
            settings={{
                look: 'interactive',
                title: 'Gebruikersgroep aanmaken',
                footer:
                    <ModalDialogFooter
                        leftButtonText="Sluiten"
                        rightButtonText="Opslaan"
                        onLeftButtonClick={props.onCancel}
                        onRightButtonClick={() => props.onSave(form.values['name'])}
                        rightButtonDisabled={!form?.isValid || !form?.touched} />
            }}
        >
            <MuiGrid container justifyContent="flex-start" alignItems="flex-start" spacing={2} columns={1}>
                <MuiGrid item xs={1}>
                    <AutoFocus>
                        <TextElement
                            id='userGroup-nameField'
                            label='Naam'
                            editorSettings={{
                                restrictions: { required: true, minLength: 2, maxLength: 200 },
                                validationErrors: form.validationErrors['name'],
                                onChange: (value: string) => storeFormValue(value, 'name'),
                            }}
                            value={form.values['name'] as string}
                        />
                    </AutoFocus>
                </MuiGrid>
            </MuiGrid>
        </ModalDialog >
    );
};

/**
 * Initialises the validators for the form.
 */
const getValidators = (userGroupNames: string[] = []): ValidatorsDictionary => {
    return {
        'name': new TextValidator({
            required: true,
            stringMaxLength: 200,
            stringType: Domain.Shared.StringType.SingleLine,
        }, (value: string): ValidationErrorData[] => {
            const lowerCaseNamelist = userGroupNames.map(name => name.toLocaleLowerCase());
            if (lowerCaseNamelist.includes(value.toLocaleLowerCase())) {
                return [{ error: 'Naam is niet uniek.' }];
            }
            return [];
        }),
    };
};

/**
 * Initialises the form data with user group data.
 */
const initFormData = (userGroup: Domain.Shared.UserGroup): AnyFormData => {
    return {
        values: {
            'name': userGroup?.name || '',
        },
        touched: {},
        validationErrors: {},
        isValid: false,
    };
};