import React from 'react';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { ToggleElement } from '@liasincontrol/ui-elements';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { ValidationErrorData } from '@liasincontrol/core-service';

type Props = {
    element: Domain.Publisher.Element,
    fieldDefinitions: Domain.Shared.FieldDefinition[],
    fieldValues: Record<string, string>,
    disabled: boolean,
    validationErrors: ValidationErrorData[],
    onChange: (change: Domain.Publisher.FieldPatch) => void,
}

/**
 * Represents a UI component that renders an editor for the 4 toggle buttons for control spacing.
 */
const WhitespaceEditor: React.FC<Props> = (props) => {
    const whitespaceFieldSystemIds = [SystemFieldDefinitions.Pub.ShowWhitespaceTop, SystemFieldDefinitions.Pub.ShowWhitespaceBottom,
    SystemFieldDefinitions.Pub.ShowWhitespaceLeft, SystemFieldDefinitions.Pub.ShowWhitespaceRight] as string[];

    return (
        <>
            <h3>Witruimte</h3>
            <MuiGrid container justifyContent="flex-start" alignItems="flex-start" spacing={2} columns={4}>
                {whitespaceFieldSystemIds.map((systemFieldId, index) => {
                    const fieldDefinition = props.fieldDefinitions.find((field) => field.systemId === systemFieldId);
                    const fieldValue = props.fieldValues[fieldDefinition.id];
                    return (<MuiGrid item xs={1}>
                        <ToggleElement
                            id={fieldDefinition.id}
                            label={fieldDefinition.name}
                            booleanTrueLabel={fieldDefinition.booleanTrueLabel}
                            booleanFalseLabel={fieldDefinition.booleanFalseLabel}
                            isSmall={true}
                            editorSettings={{
                                disabled: props.disabled,
                                restrictions: { required: fieldDefinition.required },
                                validationErrors: props.validationErrors,
                                onChange: (val) => props.onChange({
                                    elementId: props.element.elementId,
                                    fieldId: fieldDefinition.id,
                                    value: (String(val).charAt(0).toUpperCase() + String(val).slice(1))
                                })
                            }}
                            value={fieldValue ? fieldValue.toLowerCase() === 'true' : false}
                        />
                    </MuiGrid>)
                })}

            </MuiGrid>
        </>
    );
}

export { WhitespaceEditor };