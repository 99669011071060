import { Dictionary } from 'lodash';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import * as Domain from '@liasincontrol/domain';
import { TextValidator, BasicValidator, ValidatorsDictionary, FormData, ValueType, FormHelper } from '@liasincontrol/core-service';

const measureMomentDefinitionId = 'measure-moment-id';
const workflowTemplateFieldDefinitionId = 'workflow-template-id';
const copyPublicationSettingsFieldDefinitionId = 'copy-publication-settings-id';
const copyDataSourcesFieldDefinitonId = 'copy-data-sources-id';
const copyTemplatesFieldDefinitionId = 'copy-templates-id';
const copySitemapFieldDefinitionId = 'copy-sitemap-id';
const copyContentFieldDefinitionId = 'copy-content-id';
const copyUsersFieldDefinitionId = 'copy-users-id';

/**
 * Initialises the validators for the form.
 */
const getPublicationFormValidators = (
  fieldDefinitions: Dictionary<Domain.Shared.FieldDefinition>,
  workflowTemplateFieldDefinition: Domain.Shared.FieldDefinition,
  measureMomentDefinition: Domain.Shared.FieldDefinition,
  copyPublicationSettingsFieldDefinition?: Domain.Shared.FieldDefinition,
  copyDataSourcesFieldDefiniton?: Domain.Shared.FieldDefinition,
  copyTemplatesFieldDefinition?: Domain.Shared.FieldDefinition,
  copySiteSetupFieldDefinition?: Domain.Shared.FieldDefinition,
  copyContentFieldDefinition?: Domain.Shared.FieldDefinition,
  copyUsersFieldDefinition?: Domain.Shared.FieldDefinition
): ValidatorsDictionary => {
  if (!fieldDefinitions) return {};

  const validators = {
    [SystemFieldDefinitions.Pub.Name]: new TextValidator({
      required: fieldDefinitions[SystemFieldDefinitions.Pub.Name].required,
      stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMaxLength,
      stringMinLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMinLength,
      stringType: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringType
    }),
    [SystemFieldDefinitions.Pub.Title]: new TextValidator({
      required: fieldDefinitions[SystemFieldDefinitions.Pub.Title].required,
      stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.Title].stringMaxLength,
      stringMinLength: fieldDefinitions[SystemFieldDefinitions.Pub.Title].stringMinLength,
      stringType: fieldDefinitions[SystemFieldDefinitions.Pub.Title].stringType
    }),
    [measureMomentDefinitionId]: new TextValidator({
      required: true,
      stringMaxLength: measureMomentDefinition.stringMaxLength,
      stringType: measureMomentDefinition.stringType
    })
  };

  const clonePublicationValidators =
    copyPublicationSettingsFieldDefinition && copyDataSourcesFieldDefiniton && copyTemplatesFieldDefinition && copySiteSetupFieldDefinition && copyContentFieldDefinition
      ? {
        [copyPublicationSettingsFieldDefinitionId]: new BasicValidator({ required: copyPublicationSettingsFieldDefinition.required }),
        [copyDataSourcesFieldDefinitonId]: new BasicValidator({ required: copyDataSourcesFieldDefiniton.required }),
        [copyTemplatesFieldDefinitionId]: new BasicValidator({ required: copyTemplatesFieldDefinition.required }),
        [copySitemapFieldDefinitionId]: new BasicValidator({ required: copySiteSetupFieldDefinition.required }),
        [copyContentFieldDefinitionId]: new BasicValidator({ required: copyContentFieldDefinition.required }),
        [copyUsersFieldDefinitionId]: new BasicValidator({ required: copyUsersFieldDefinition.required }),
      }
      : null;

  return !clonePublicationValidators
    ? {
      ...validators,
      [workflowTemplateFieldDefinitionId]: new TextValidator({
        required: true,
        stringMaxLength: workflowTemplateFieldDefinition.stringMaxLength,
        stringType: workflowTemplateFieldDefinition.stringType
      }),
      [SystemFieldDefinitions.Pub.SiteDesignId]: new TextValidator({
        required: fieldDefinitions[SystemFieldDefinitions.Pub.SiteDesignId].required,
        stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.SiteDesignId].stringMaxLength,
        stringType: fieldDefinitions[SystemFieldDefinitions.Pub.SiteDesignId].stringType
      })
    }
    : { ...validators, ...clonePublicationValidators };
};

/**
 * Initialise the form values, touched and validation error objects.
 */
const initPublicationForm = (
  workflowTemplates: Domain.Shared.FieldDefinitionOptionItem[],
  name = '',
  title = '',
  isClonePublicationForm = false,
  validate = false,
  validators = {}
): FormData<ValueType> => {
  const values = {
    [SystemFieldDefinitions.Pub.Name]: name,
    [SystemFieldDefinitions.Pub.Title]: title,
    [measureMomentDefinitionId]: '',
    [copyPublicationSettingsFieldDefinitionId]: false,
    [copyDataSourcesFieldDefinitonId]: false,
    [copyTemplatesFieldDefinitionId]: false,
    [copySitemapFieldDefinitionId]: false,
    [copyContentFieldDefinitionId]: false,
    [copyUsersFieldDefinitionId]: false,
  };

  const newForm = {
    values: isClonePublicationForm
      ? { ...values, [SystemFieldDefinitions.Pub.SiteDesignId]: '' }
      : {
        ...values,
        [workflowTemplateFieldDefinitionId]: workflowTemplates?.length > 0 ? workflowTemplates[0]?.id : '',
      },
    touched: {},
    validationErrors: {},
    isValid: false,
  };

  if (validate) {
    const { errors, hasErrors } = FormHelper.validateForm(validators, newForm.values, newForm.validationErrors, []);
    newForm.validationErrors = errors;
    newForm.isValid = !hasErrors;
  }
  return newForm;
};

export {
  workflowTemplateFieldDefinitionId,
  copyDataSourcesFieldDefinitonId,
  copyPublicationSettingsFieldDefinitionId,
  copyTemplatesFieldDefinitionId,
  copySitemapFieldDefinitionId,
  copyContentFieldDefinitionId,
  copyUsersFieldDefinitionId,
  measureMomentDefinitionId,
  getPublicationFormValidators,
  initPublicationForm,
};
