export class DataUtils {
    /**
      * Maps a string to devexpress data types.
      * @param value Defines the string to get a devexpress data type for. 
      * @param isDateTime Defines a flag for datetime values.
      */
    public static mapStringToDxType = (value: string, isDateTime = false, useDateType = false): 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' | undefined => {
        let rightType: 'string' | 'number' | 'boolean' | 'object' | 'date' | 'datetime';
        switch (value) {
            case 'string':
            case 'number':
            case 'boolean':
            case 'object':
            case 'date':
            case 'datetime':
                if (isDateTime) {
                    rightType = useDateType ? 'date' : 'datetime';
                } else {
                    rightType = value;
                }
                break;
            case 'integer':
            case 'float':
            case 'bigint':
            case 'double':
                rightType = 'number';
                break;
            default:
                rightType = undefined;
                break;
        }
        return rightType;
    };


    /**
     * Returns the default column alignment of a devexpress grid depending on the data type.
     * @param dataType Defines the column data type.
     */
    public static getDefaultDxAlignment = (dataType: string) => {
        let defaultAlignment: 'center' | 'left' | 'right' | undefined;
        switch (dataType) {
            case 'number':
                defaultAlignment = 'right';
                break;
            case 'boolean':
                defaultAlignment = 'center';
                break;
            case 'string':
            case 'date':
            case 'datetime':
                defaultAlignment = 'left';
                break;
            default:
                defaultAlignment = 'center';
                break;
        }
        return defaultAlignment;
    };

}
