import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { MultiSelectItem } from '@liasincontrol/ui-basics';
import { MultiOptionsListsElement, FilterBuilderElement } from '@liasincontrol/ui-elements';
import { ValidatorsDictionary, FormData, BasicValidator, FormMode, JsonUtils, FormHelper, ApiErrorReportingHelper, ValueType } from '@liasincontrol/core-service';
import { LsFilterBuilderField } from '@liasincontrol/ui-devextreme';
import { DataSourceControlsUtils } from '../../../../../../../helpers';

type Props = {
    datasourceId?: string,
    form: FormData<ValueType>,
    mode: FormMode,
    selectedDataStore: { id: string, kind: string, element: Domain.Publisher.DataStoreElement },
    elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    onChange: (subForm: FormData<ValueType>) => void,
    onError?: (error: Domain.Shared.ErrorInfo, showAsWarning?: boolean) => void,
};

/**
 * Represents a UI component that renders a sub form to view/edit a data source with dataplatform.
 */
const DataPlatformForm: React.FC<Props> = (props) => {
    const complexFieldDefinitions: Record<string, Domain.Shared.FieldDefinition>
        = useMemo(() => props.elementdefinitions[Domain.SystemElementDefinitions.Pub.DataSource].complexFields[0].fields.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {}), [props.elementdefinitions]);
    const dataSourceFilterDefinition = useMemo(() => complexFieldDefinitions[SystemFieldDefinitions.Pub.Filter], [complexFieldDefinitions]);
    const dataSourceColumnsDefinition = useMemo(() => complexFieldDefinitions[SystemFieldDefinitions.Pub.DataSourceColumns], [complexFieldDefinitions]);

    const validators = getValidators(complexFieldDefinitions);

    const [allItems, setAllItems] = useState<MultiSelectItem[]>([]);
    const [mappedData, setMappedData] = useState<{ columnFilterFields: MultiSelectItem[], rowFilterFields: LsFilterBuilderField[] }>({
        columnFilterFields: [],
        rowFilterFields: []
    });

    useEffect(() => {
        if (!props.selectedDataStore || !props.selectedDataStore.kind || !props.form) return;
        initExtraParameters(props.selectedDataStore.id, props.form.values[SystemFieldDefinitions.Pub.DataSourceColumns]);
    }, [props.selectedDataStore, props.form.values[SystemFieldDefinitions.Pub.DataSourceColumns]]);

    // Initialize complete column list & Filter based on schema
    const initExtraParameters = (dataStoreId: string, initialColumns?: string): void => {
        const selectedColumns = JsonUtils.toJson<string[]>(initialColumns, []);

        DataAccess.DataStores.getDataPlatformSchema(dataStoreId).then((response) => {
            const allItems = response.data.map((field) => ({ id: field.columnName, label: field.columnName, value: false }));
            const rows = response.data.map(field => ({ dataType: DataSourceControlsUtils.mapStringToDxType(field.dataType), dataField: field.columnName, caption: field.columnName }));
            setAllItems(allItems);
            setMappedData((prev) => ({
                ...prev,
                columnFilterFields: allItems.filter((item) => !selectedColumns.includes(item.id)),
                rowFilterFields: rows,
            }));
        }).catch((err) => {
            setAllItems([]);
            setMappedData({
                columnFilterFields: [],
                rowFilterFields: [],
            });
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.DataPlatformColumnsError)) {
                props.onError(ApiErrorReportingHelper.generateErrorInfo(Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.DataPlatformColumnsError], err), true);
            }
        });
    };
    // Map for visualize multiselections
    const mapColumnValue = (columns: string): string => {
        const selectedColumnNames = JsonUtils.toJson<string[]>(columns, []);
        const selectedColumns = allItems.filter((item) => selectedColumnNames.includes(item.id));
        return selectedColumns.length > 0 ? JSON.stringify(selectedColumns) : '';
    };

    const storeFormValues = (values: Record<string, ValueType>, resetExternalErrorFieldSystemIds: string[] = []): void => {
        const clonedForm = FormHelper.handleFormValuesChanged(values, props.form);
        const { errors } = FormHelper.validateForm(validators, clonedForm.values, clonedForm.validationErrors, resetExternalErrorFieldSystemIds);
        clonedForm.validationErrors = { ...props.form.validationErrors, ...errors };
        clonedForm.isValid = !Object.values(clonedForm.validationErrors).some(a => a.length > 0);
        props.onChange(clonedForm);
    };

    // Change selected columns
    const changeColumnSettings = (items: string): void => {
        const selectedColumns = JsonUtils.toJson<MultiSelectItem[]>(items, []);
        const fields = {
            [SystemFieldDefinitions.Pub.DataSourceColumns]: _.isEmpty(selectedColumns) ? '' : JSON.stringify(selectedColumns.map(i => i.label)),
        };
        storeFormValues(fields);
    };

    const setExternalFieldError = (error: string, fieldSystemId: keyof typeof validators, fieldValue?: string): void => {
        const newForm = FormHelper.setExternalFieldError(error, fieldSystemId, validators, props.form, fieldValue) as FormData<ValueType>;
        props.onChange(newForm);
    };

    const onFilterDataChanged = (filterValue: string) => {
        // Store filter value only if BE validates it too.
        DataAccess.DataSources.validateDataSourceFilter(filterValue, props.selectedDataStore.id, props.datasourceId)
            .then(() => {
                const fields = {
                    [SystemFieldDefinitions.Pub.Filter]: filterValue,
                };
                storeFormValues(fields, [SystemFieldDefinitions.Pub.Filter]);
            }).catch(err => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.DataSourceTooLarge)) {
                    setExternalFieldError(
                        Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.DataSourceTooLarge],
                        SystemFieldDefinitions.Pub.Filter,
                        filterValue);
                } else if (props.onError) {
                    props.onError(errorInfo);
                }
            });
    };

    return (<>
        <MuiGrid item xs={1} sm={2} md={4} key={dataSourceFilterDefinition.id}>
            {/* Datastore-filter-field - visible on view-mode, addnew-mode and edit-mode if selected datastore is 'dataplatform */}
            <FilterBuilderElement
                id='datasource-filter'
                label={dataSourceFilterDefinition.label ? dataSourceFilterDefinition.label : dataSourceFilterDefinition.name}
                fields={mappedData.rowFilterFields}
                helpText={{ title: dataSourceFilterDefinition.helpTextTitle, text: dataSourceFilterDefinition.helpText }}
                editorSettings={{
                    disabled: props.mode === FormMode.View,
                    restrictions: validators[SystemFieldDefinitions.Pub.Filter] ? validators[SystemFieldDefinitions.Pub.Filter].getRestrictions() : undefined,
                    validationErrors: props.form.touched[SystemFieldDefinitions.Pub.Filter] ? props.form.validationErrors[SystemFieldDefinitions.Pub.Filter] : [],
                    onChange: onFilterDataChanged
                }}
                value={props.form.values[SystemFieldDefinitions.Pub.Filter]}
            />
        </MuiGrid>
        {/* Datastore-Column-filter-field - visible on view-mode, addnew-mode and edit-mode if selected datastore is 'dataplatform */}
        <MuiGrid item xs={1} sm={2} md={4} key={dataSourceColumnsDefinition.id}>
            <MultiOptionsListsElement
                id='datasource-columns'
                label={dataSourceColumnsDefinition.label ? dataSourceColumnsDefinition.label : dataSourceColumnsDefinition.name}
                title={dataSourceColumnsDefinition.label ? dataSourceColumnsDefinition.label : dataSourceColumnsDefinition.name}
                helpText={{ title: dataSourceColumnsDefinition.helpTextTitle, text: dataSourceColumnsDefinition.helpText }}
                editorSettings={{
                    disabled: props.mode === FormMode.View,
                    restrictions: validators[SystemFieldDefinitions.Pub.DataSourceColumns] ? validators[SystemFieldDefinitions.Pub.DataSourceColumns].getRestrictions() : undefined,
                    validationErrors: props.form.touched[SystemFieldDefinitions.Pub.DataSourceColumns] ? props.form.validationErrors[SystemFieldDefinitions.Pub.DataSourceColumns] : [],
                    onChange: changeColumnSettings
                }}
                allItems={mappedData.columnFilterFields}
                value={mapColumnValue(props.form.values[SystemFieldDefinitions.Pub.DataSourceColumns])}
                labelSearchList='Geselecteerde kolommen'
                placeholderEmptySearchList='Geen geselecteerde kolommen'
            />
        </MuiGrid>
    </>);
};

/**
 * Initialises the subform validators.
 */
const getValidators = (complexFieldDefinitions: Record<string, Domain.Shared.FieldDefinition>): ValidatorsDictionary => {
    if (!complexFieldDefinitions) return {};
    const validators = {
        [SystemFieldDefinitions.Pub.DataSourceColumns]: new BasicValidator<string>({ required: complexFieldDefinitions[SystemFieldDefinitions.Pub.DataSourceColumns].required }),
        [SystemFieldDefinitions.Pub.Filter]: new BasicValidator<string>({ required: complexFieldDefinitions[SystemFieldDefinitions.Pub.Filter].required })
    };
    return validators;
};

export { DataPlatformForm };
