import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

interface UserSettingsContextProps {
    baseYear?: number;
    setBaseYear?: (value: number) => void;
    measureMomentId?: string;
    setMeasureMomentId?: (value: string) => void;
    structureRK?: string;
    setStructureRK?: (value: string) => void;
    structureNodeRK?: string;
    setStructureNodeRK?: (value: string) => void;
    costBenefitFilter?: string[];
    setCostBenefitFilter?: (value: string[]) => void;
}

const UserSettingsContext = createContext<UserSettingsContextProps>({});

interface UserSettingsProviderProps {
    children: ReactNode;
}

export const UserSettingsProvider: React.FC<UserSettingsProviderProps> = ({
    children,
}) => {
    const [baseYear, setBaseYear,] = useState<number>();
    const [measureMomentId, setMeasureMomentId] = useState<string>();
    const [structureRK, setStructureRK] = useState<string>();
    const [structureNodeRK, setStructureNodeRK] = useState<string>();
    const [costBenefitFilter, setCostBenefitFilter] = useState<string[]>();

    useEffect(() => {
        setMeasureMomentId(undefined);
        setStructureRK(undefined);
    }, [baseYear]);

    useEffect(() => {
        setStructureNodeRK(undefined);
    }, [structureRK]);

    return (
        <UserSettingsContext.Provider value={{
            baseYear, setBaseYear,
            measureMomentId, setMeasureMomentId,
            structureRK, setStructureRK,
            structureNodeRK, setStructureNodeRK,
            costBenefitFilter, setCostBenefitFilter: setCostBenefitFilter,
        }}>
            {children}
        </UserSettingsContext.Provider>
    );
};

export const useUserSettings = () => {
    const context = useContext(UserSettingsContext);
    return context;
};

