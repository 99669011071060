import React from 'react';
import _, { Dictionary } from 'lodash';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { FieldDefinitionOptionItem } from '@liasincontrol/domain/src/aggregates/Shared/FieldDefinition';
import { AttachmentElement, ColorPickerElement, SelectElement } from '@liasincontrol/ui-elements';
import { Label, palette, IDataItemProps } from '@liasincontrol/ui-basics';
import { AnyFormData, ValidatorsDictionary, ValidationUtils } from '@liasincontrol/core-service';
import { CUSTOM_PALETTE, DEFAULT_PALETTE, getLiasPalette } from '@liasincontrol/ui-devextreme';
import Styled from './index.styled';
import { CustomColorPalette } from './CustomColorPalette';

type Props = {
    fieldDefinitions: Dictionary<Domain.Shared.FieldDefinition>,
    isEditing: boolean;
    form: AnyFormData,
    validators: ValidatorsDictionary,
    onLoadAttachment: (id: string) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onChange: (value: any, fieldDefinitionId: string, resetExternalErrorFieldSystemIds?: string[]) => void,
    onError?: (error: string, fieldSystemId: string, fieldValue?: string) => void,
};

/**
 * Represents a UI component that renders the Identity styles section form of the Publication information page.
 */
export const IdentitySection: React.FC<Props> = (props) => {
    if (_.isEmpty(props.form) || _.isEmpty(props.form.values)) {
        return null;
    }

    const onAttachmentChanged = (value: string, fieldDefinitionId: string) => {
        props.onChange(value, fieldDefinitionId, [fieldDefinitionId]);
    };

    const logoDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.Logo];
    const faviconDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.Favicon];
    const primaryColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.PrimaryColor];
    const primaryContrastColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.PrimaryContrastColor];
    const secondaryColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.SecondaryColor];
    const secondaryContrastColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.SecondaryContrastColor];
    const textSizeDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.TextSize];
    const textColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.TextColor];
    const h1TextSizeDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H1TextSize];
    const h1TextColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H1TextColor];
    const h2TextSizeDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H2TextSize];
    const h2TextColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H2TextColor];
    const h3TextSizeDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H3TextSize];
    const h3TextColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H3TextColor];
    const colorPaletteDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.ColorPalette];
    const menuDepthLevelDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.MenuLevels];
    const defaultColorPalette = colorPaletteDefinition?.optionItems?.find(cp => cp.name === DEFAULT_PALETTE);
    const customPalette = colorPaletteDefinition?.optionItems?.find(cp => cp.name === CUSTOM_PALETTE);
    const customColors = props.form.values[SystemFieldDefinitions.Pub.CustomColorPaletteColors]
        ? props.form.values[SystemFieldDefinitions.Pub.CustomColorPaletteColors] as string[]
        : ['#000000'];

    const setCustomColors = (colors: string[]) => {
        props.onChange(colors, SystemFieldDefinitions.Pub.CustomColorPaletteColors);
    };

    const renderColorPalettePreview = (paletteId: string) => {
        if (paletteId === customPalette?.id) {
            return <CustomColorPalette isEditing={props.isEditing} colorPalette={customColors} setCustomColors={setCustomColors} />
        } else {
            const paletteName = colorPaletteDefinition?.optionItems?.find(cp => cp.id === paletteId)?.name || defaultColorPalette?.name;
            const colorSet = getLiasPalette(paletteName, props.form.values[SystemFieldDefinitions.Pub.PrimaryColor] as string).simpleSet;
            const colorTiles = colorSet.map((color) => (
                <Styled.PaletteItemWrapper key={`${paletteId}-${color}`} color={color} />
            ));
            return <div style={{ width: '100%', maxWidth: '28rem' }}>{colorTiles}</div>
        }
    };

    const commonProps = {
        isEditing: props.isEditing,
        validators: props.validators,
        form: props.form,
        onChange: props.onChange,
    };

    return (<>
        <MuiGrid container spacing={2} justifyContent="flex-start" alignItems="flex-end">
            <MuiGrid item xs={12} md={6}>
                <AttachmentElement
                    id='pub-logo'
                    label={logoDefinition.label ? logoDefinition.label : logoDefinition.name}
                    editorSettings={ValidationUtils.getEditorSettings(true, !props.isEditing, props.validators, props.form, onAttachmentChanged, SystemFieldDefinitions.Pub.Logo)}
                    maxPreviewHeight={256}
                    value={props.form.values[SystemFieldDefinitions.Pub.Logo] as string}
                    onLoadAttachment={props.onLoadAttachment}
                    onUploadAttachment={props.onUploadAttachment}
                    onAddFileError={(error) => {
                        props.onError(error, SystemFieldDefinitions.Pub.Logo);
                    }}
                />
            </MuiGrid>
            <MuiGrid item xs={12} md={6}>
                <AttachmentElement
                    id='pub-favicon'
                    label={faviconDefinition.label ? faviconDefinition.label : faviconDefinition.name}
                    maxImageSize={{ height: 16, width: 16 }}
                    editorSettings={ValidationUtils.getEditorSettings(true, !props.isEditing, props.validators, props.form, onAttachmentChanged, SystemFieldDefinitions.Pub.Favicon)}
                    onLoadAttachment={props.onLoadAttachment}
                    onUploadAttachment={props.onUploadAttachment}
                    value={props.form.values[SystemFieldDefinitions.Pub.Favicon] as string}
                    onAddFileError={(error) => {
                        props.onError(error, SystemFieldDefinitions.Pub.Favicon);
                    }}
                />
            </MuiGrid>
            <MenuDepthLevel {...commonProps} menuDepthLevel={menuDepthLevelDefinition} />
            {/* colors */}
            <ColorField {...commonProps} colorFieldDefinition={{ ...primaryColorDefinition, label: 'Primaire achtergrondkleur' }} />
            <ColorField {...commonProps} colorFieldDefinition={{ ...primaryContrastColorDefinition, label: 'Primaire contrastkleur' }} />
            <ColorField {...commonProps} colorFieldDefinition={{ ...secondaryColorDefinition, label: 'Secundaire achtergrondkleur' }} />
            <ColorField {...commonProps} colorFieldDefinition={{ ...secondaryContrastColorDefinition, label: 'Secundaire contrastkleur' }} />

            {/* text with colors */}
            <ColorWithSizeField {...commonProps} colorFieldDefinition={{ ...textColorDefinition, label: 'Standaard tekst' }} sizeFieldDefinition={textSizeDefinition} />
            <ColorWithSizeField {...commonProps} colorFieldDefinition={{ ...h1TextColorDefinition, label: 'Titel' }} sizeFieldDefinition={h1TextSizeDefinition} />
            <ColorWithSizeField {...commonProps} colorFieldDefinition={{ ...h2TextColorDefinition, label: 'Subtitel groot' }} sizeFieldDefinition={h2TextSizeDefinition} />
            <ColorWithSizeField {...commonProps} colorFieldDefinition={{ ...h3TextColorDefinition, label: 'Subtitel klein' }} sizeFieldDefinition={h3TextSizeDefinition} />

            {/* charts colors */}
            <MuiGrid container item xs={12} md={12} key={colorPaletteDefinition.id}>
                <MuiGrid item xs={12} md={12} key={`${colorPaletteDefinition.id}-label`}>
                    <Label text={colorPaletteDefinition.label} />
                </MuiGrid>
                <MuiGrid item xs={6} md={4} key={`${colorPaletteDefinition.id}-name`}>
                    <SelectElement<FieldDefinitionOptionItem>
                        displayExpr='name'
                        id='pub-colorPalettes'
                        editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form,
                            (e: Domain.Shared.FieldDefinitionOptionItem) => props.onChange(e?.id, SystemFieldDefinitions.Pub.ColorPalette),
                            SystemFieldDefinitions.Pub.ColorPalette)}
                        searchable={false}
                        clearable={false}
                        optionItems={colorPaletteDefinition.optionItems}
                        value={props.form.values[SystemFieldDefinitions.Pub.ColorPalette]
                            ? colorPaletteDefinition.optionItems.find(cp => cp.id === props.form.values[SystemFieldDefinitions.Pub.ColorPalette])
                            : defaultColorPalette}
                    />
                </MuiGrid>
                <MuiGrid item xs={6} md={8} key={`${colorPaletteDefinition.id}-preview`} paddingLeft='1rem'>
                    {renderColorPalettePreview(props.form.values[SystemFieldDefinitions.Pub.ColorPalette] as string)}
                </MuiGrid>
            </MuiGrid>
        </MuiGrid >
    </>);
};

const MenuDepthLevel = (props) => (
    <MuiGrid container item xs={12} md={12} justifyContent="flex-end">
        <MuiGrid item key="menu-depth-level-input-wrapper" xs={12} md={6} top={props.top}>
            <SelectElement<number>
                id="menu-depth-level-input"
                label={props.menuDepthLevel.label}
                editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form,
                    (e) => props.onChange(e, SystemFieldDefinitions.Pub.MenuLevels), SystemFieldDefinitions.Pub.MenuLevels)}
                searchable={false}
                clearable={false}
                optionItems={[1, 2, 3, 4]}
                value={+props.form.values[SystemFieldDefinitions.Pub.MenuLevels] || +props.menuDepthLevel.integerStartValue}
            />
        </MuiGrid>
    </MuiGrid>
);

const ColorField = (props) => (
    <MuiGrid item xs={12} md={6} key={props.colorFieldDefinition.id}>
        <ColorPickerElement
            title={props.colorFieldDefinition.label}
            helpText={{ title: props.colorFieldDefinition.helpTextTitle, text: props.colorFieldDefinition.helpText }}
            id={`pub-${props.colorFieldDefinition.id}`}
            editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, props.colorFieldDefinition.systemId)}
            ariaLabel={props.colorFieldDefinition.label}
            size='small'
            withBorder={true}
            defaultColor={palette.grey4}
            value={props.form.values[props.colorFieldDefinition.systemId] as string}
        />
    </MuiGrid >
);

const ColorWithSizeField = (props) => (
    <MuiGrid container item xs={12} md={6} key={props.colorFieldDefinition.id}>
        <MuiGrid item xs={12} md={12} key={`${props.colorFieldDefinition.id}-label`}>
            <Label text={props.colorFieldDefinition.label} />
        </MuiGrid>
        <MuiGrid item xs={6} md={2} key={`${props.colorFieldDefinition.id}-color`}>
            <ColorPickerElement
                id={`pub-${props.colorFieldDefinition.id}`}
                editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, props.colorFieldDefinition.systemId)}
                ariaLabel={props.colorFieldDefinition.label}
                size='small'
                defaultColor={palette.grey2}
                value={props.form.values[props.colorFieldDefinition.systemId] as string}
            />
        </MuiGrid>
        <MuiGrid item xs={6} md={4} key={`${props.sizeFieldDefinition.id}-value`}>
            <SelectElement<IDataItemProps<string>>
                displayExpr='label'
                id={`pub-${props.sizeFieldDefinition.id}`}
                editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, (e: IDataItemProps<string>) => props.onChange(e?.value, props.sizeFieldDefinition.systemId), props.sizeFieldDefinition.systemId)}
                searchable={false}
                clearable={false}
                optionItems={fontsizes}
                value={props.form.values[props.sizeFieldDefinition.systemId]
                    ? fontsizes.find(fs => fs.value === props.form.values[props.sizeFieldDefinition.systemId])
                    : fontsizes.find(fs => fs.value === props.sizeFieldDefinition.integerStartValue.toString())
                }
            />
        </MuiGrid>
    </MuiGrid>
);

const fontsizes = [
    { value: '14', label: '14 px' },
    { value: '16', label: '16 px' },
    { value: '18', label: '18 px' },
    { value: '20', label: '20 px' },
    { value: '22', label: '22 px' },
    { value: '24', label: '24 px' },
    { value: '26', label: '26 px' },
    { value: '28', label: '28 px' },
    { value: '30', label: '30 px' },
    { value: '32', label: '32 px' },
    { value: '34', label: '34 px' },
    { value: '36', label: '36 px' },
];
