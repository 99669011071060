import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { oDataResponse, oDataResponseStructured } from '../../Shared/oData';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of budget journal items.
 */
export class BudgetJournalDataAccessor {

    /**
     * Creates a budget Journal item.
     * @param budgetJournal Defines the budget Journal that has to be created.
     */
    public static create = async (budgetJournal: Domain.Dto.Finance.CreateBudgetJournal): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>('/api/finance/budgetjournal', budgetJournal, config);
    };

    /**
     * Updates a budget Journal item.
     * @param budgetJournal Defines the budget Journal that has to be updated.
     */
    public static update = async (budgetJournal: Domain.Finance.BudgetJournalDetail): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<string>(`/api/finance/budgetjournal/${budgetJournal.id}`, budgetJournal, config);
    };

    /**
     * Gets the collection of budget Journal items.
     * @param query Defines the odata query.
     * @param structureRK Defines the strukture rk.
     * @param structureNodeRk Defines the strukture node rk from strukture.
     */
    public static getAll = async (structureRK?: string, structureNodeRk?: string, query = ''): Promise<AxiosResponse<oDataResponse<Domain.Finance.BudgetJournal[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const extraParams = `${structureRK ? `&structureRK=${structureRK}` : ''}${structureNodeRk ? `&structureNodeRk=${structureNodeRk}` : ''}`;
        return HttpClient.get<oDataResponse<Domain.Finance.BudgetJournal[]>>(`/api/finance/budgetjournal${query}${extraParams}`, config);
    };

    /**
     * Gets the collection of budget Journal items.
     * @param budgetJournalId Defines the budget journal id (budgetJournal).
     */
    public static get = async (budgetJournalId: string): Promise<AxiosResponse<Domain.Finance.BudgetJournalDetail>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Finance.BudgetJournalDetail>(`/api/finance/budgetjournal/${budgetJournalId}`, config);
    };

    /**
     * Delete a budget journal item.
     * @param budgetJournalId Defines the budget journal that has to be deleted.
     */
    public static delete = async (budgetJournalId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`/api/finance/budgetjournal/${budgetJournalId}`, config);
    };

    /**
     * Get a budget summary rows.
     * @param budgetJournalId  Defines the budget journal id (budgetJournal).
     * @returns 
     */
    public static getSummary = async (budgetJournalId: string): Promise<oDataResponseStructured<Domain.Finance.BudgetJournalSummaryValue>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const response = await HttpClient.get<oDataResponseStructured<Domain.Finance.BudgetJournalSummaryValue>>(`/api/finance/budgetjournal/${budgetJournalId}/budgetline/summary`, config);
        return response.data;
    };

    /**
     * Get budget journals that can be exported.
     * @param baseYear Base year of the budget journal
     * @param measureMomentId Measure moment id of the budget journal
     * @returns List of budget journals that can be exported
     */
    public static getExportableBudgetJournals = async (baseYear: number, measureMomentId: string): Promise<Domain.Finance.BudgetJournal[]> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const response = await HttpClient.get<Domain.Finance.BudgetJournal[]>(`/api/finance/budgetjournal/exportoverview/${baseYear}/${measureMomentId}`, config);
        return response.data;
    };

    /**
     * Export budget journals based on the selected ids.
     * @param ids budget journal ids
     * @returns Export file as a blob
     */
    public static exportBudgetJournals = async (ids: string[]): Promise<{ blob: Blob, fileName: string }> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const response = await HttpClient.post(`/api/finance/budgetjournal/export`, { budgetJournalIds: ids }, config);

        // Get the content-disposition header to extract the filename
        const contentDisposition = response.headers['content-disposition'];

        let fileName = 'budgetjournalen.csv'; // Default filename
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            if (filenameMatch && filenameMatch[1]) {
                fileName = filenameMatch[1].replace(/['"]/g, '');
            }
        }

        // Create a blob from the response
        const fileType = response.headers['content-type'] || 'text/csv';
        const fileBlob = new Blob([response.data], { type: fileType });

        return { blob: fileBlob, fileName };
    };
}
