import React, { useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { Button } from '@liasincontrol/ui-basics';
import { TextValidator, ValidationErrorData } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { MultiLineTextElement } from '@liasincontrol/ui-elements';

type Props = {
    disabled: boolean,
    onSave: (commentText: string) => void,
}

/**
 * Represents a UI component that renders the add comment form.
 */
const AddComment: React.FC<Props> = (props) => {
    const [comment, setComment] = useState<string>('');
    const validator = new TextValidator({ required: false, stringMaxLength: 4000, stringType: Domain.Shared.StringType.MultiLine });
    const [error, setError] = useState<ValidationErrorData[]>([]);
    return (<>
        <MuiGrid container justifyContent="flex-start" alignItems="flex-start" spacing={2} columns={2}>
            <MuiGrid item xs={2}>
                <MultiLineTextElement
                    id='comment-text-field'
                    label='Reageer'
                    key='comment-key'
                    editorSettings={{
                        disabled: props.disabled,
                        restrictions: validator.getRestrictions(),
                        validationErrors: error,
                        onChange: (value: string) => {
                            setComment(value);
                            setError(validator.validate(value));
                        },
                    }}
                    value={comment} />
            </MuiGrid>
            <MuiGrid item xs={2} textAlign='right'>
                <Button id={`btn-add-comment`} disabled={props.disabled || comment.length <= 0 || error.length > 0} btnbase="primarybuttons" btntype="small_icon" onClick={() => {
                    props.onSave(comment);
                    setComment('');
                }}>
                    Plaatsen
                </Button>
            </MuiGrid>
        </MuiGrid>

    </>
    );
}
export { AddComment };