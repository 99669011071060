import React, { useState } from 'react';
import { Bar, Button, Feedback } from '@liasincontrol/ui-basics';
import { StyledAddOptions, StyledIconDrag, StyledInput, StyledItem } from '../index.styled';
import Add from '@mui/icons-material/Add';

type Props = {
    maxValueLength: number,
    onChange: (name: string) => void;
    onCancel: () => void;
};

/**
 * Represents a UI component that renders a form to add a new child page to the current page.
 */
export const AddPageForm: React.FC<Props> = (props) => {
    const [name, setName] = useState<string>('');
    const [error, setError] = useState<string>('');
    const inputWithCounter = isCounterFeedback(`${name.length}/${props.maxValueLength}`) && name.length > 0;

    return (
        <StyledAddOptions>
            <StyledItem className='add' canRestore={false}>
                <StyledIconDrag />
                <StyledInput
                    id="input-page-name"
                    autoFocus
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.currentTarget.value.length > props.maxValueLength) {
                            setError(`Maximaal ${props.maxValueLength} tekens`);
                        } else {
                            setError(``);
                        }
                        setName(event.currentTarget.value)
                    }}
                    value={name}
                    onKeyPress={(e) => {
                        if (e.nativeEvent.key === "Enter" && e.currentTarget.value.length <= props.maxValueLength) {
                            props.onChange(name)
                            setName('');
                        }
                    }}
                    aria-label="Voeg een nieuwe pagina toe"
                />
            </StyledItem>
            <Feedback
                error={!!error}
                children={error}
                withCounter={inputWithCounter}
                counter={`${name.length}/${props.maxValueLength}`}
                id={`name-feedback`}
            />
            <Bar>
                <Bar start>
                    <Button
                        id="btn-add-page"
                        btnbase="textbuttons"
                        btntype="small_icon"
                        icon={<Add />}
                        onClick={() => { setName(''); props.onChange(name) }}
                        disabled={name.length === 0 || name.length > props.maxValueLength}>
                        Voeg toe
                    </Button>
                    <Button id="btn-cancel-page" btnbase="textbuttons" btntype="small_noicon" type="button" onClick={() => { setName(''); props.onCancel(); }}>
                        Annuleren
                    </Button>
                </Bar>
            </Bar>
        </StyledAddOptions>
    );
};

/**
 * Matches number/number eg `"10/100"`
 */
const isCounterFeedback = (input: any) =>
    typeof input === 'string' && /^\d+\/\d+$/.test(input);