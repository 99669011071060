import styled from 'styled-components';

const TabHeader = styled.div`
    white-space: nowrap;
    padding: 6px 12px;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
`;

const TabName = styled.div<{ orientation?: 'top' | 'end' | 'bottom' | 'start' }>`
    display: inline-flex;
    width: auto;
    align-items: center;
    flex-grow: 1;
    justify-content: start;
    flex-direction: ${({ orientation }) =>
        orientation === 'bottom' ? 'column-reverse' :
            orientation === 'end' ? 'row-reverse' :
                orientation === 'start' ? 'row' :
                    'column'
    };
`;

const TabField = styled.div<{ withBorder?: boolean }>`;
    border-bottom: ${({ withBorder }) => withBorder ? '1px solid #F4F4F8' : 'none'};  
`;

const TabFieldLabel = styled.label<{ withMargin: boolean }>`
    display: block;
    margin-bottom: ${({ withMargin }) => withMargin ? '0.5rem' : '0'};
`;

const styledComponents = {
    TabHeader, TabName, TabField, TabFieldLabel
};
export default styledComponents;