import React from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { Administrator } from '../..';

const Menu = (
    <>
        <Route path="usermanagement/*" element={<PrivateRoute component={Administrator.menu} />} />

        <Route path="datastore/list" element={<PrivateRoute component={Administrator.menu} />} />
        <Route path="datastore/:id/information" element={<PrivateRoute component={Administrator.menu} />} />

        <Route path="workflow/list" element={<PrivateRoute component={Administrator.menu} />} />
        <Route path="workflow/:id/information" element={<PrivateRoute component={Administrator.menu} />} />

        <Route path="measuremoment/list" element={<PrivateRoute component={Administrator.menu} />} />

    </>
);

export { Menu };
