import React, { useEffect, useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { AutoFocus, Button } from '@liasincontrol/ui-basics';
import { TextElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { FormHelper, TextValidator, ValidationErrorData } from '@liasincontrol/core-service';
import _ from 'lodash';

type PageImageSettingsProps = {
    readonly pageElement: Domain.Publisher.PageElement,
    readonly disabled: boolean,
    readonly updateTitle: (newTitle: string) => Promise<void>,
    readonly isEditing: () => void,
}

export const PageTitleSettings: React.FC<PageImageSettingsProps> = (props) => {
    const [pageTitle, setPageTitle] = useState<{ isEditing: boolean, value: string }>({ isEditing: false, value: props.pageElement.title });
    const [validationErrors, setValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>,
        hasErrors: boolean,
    }>({ errors: {}, hasErrors: false });

    useEffect(() => {
        if (props.pageElement.title === pageTitle.value) return;

        setPageTitle({ isEditing: false, value: props.pageElement.title });
    }, [props.pageElement]);

    const onSaveNewPageName = (pageTitle: string) => {
        props.updateTitle(pageTitle)
            .then(() => setPageTitle({ isEditing: false, value: pageTitle }));
    };

    return (
        <>
            <MuiGrid item xs={12} md={8}>
                <AutoFocus>
                    <TextElement
                        id='alternative-title-field'
                        label='Alternatieve titel'
                        editorSettings={{
                            disabled: !pageTitle.isEditing,
                            validationErrors: validationErrors.errors["pageTitle"],
                            clearable: true,
                            restrictions: { required: false, minLength: 0, maxLength: 200 },
                            onChange: (value: string) => {
                                if (value === props.pageElement.title) {
                                    return;
                                }
                                setPageTitle({ isEditing: true, value });
                                const temporaryValidationError = _.cloneDeep(validationErrors);
                                const validationResult = validatePageTitle(value, validationErrors.errors);
                                temporaryValidationError.errors['pageTitle'] = validationResult.errors['pageTitle'];
                                temporaryValidationError.hasErrors = validationResult.hasErrors;
                                setValidationErrors(temporaryValidationError);
                            },
                        }}
                        value={pageTitle.value}
                    />
                </AutoFocus>
            </MuiGrid>
            <MuiGrid item xs={12} md={4}>
                {pageTitle.isEditing ?
                    <Button
                        id="btn-icon-save-title"
                        btnbase="ghostbuttons"
                        btntype="small_noicon"
                        disabled={validationErrors.hasErrors}
                        onClick={() => onSaveNewPageName(pageTitle.value)}
                    >
                        Opslaan
                    </Button>
                    : <Button
                        id="btn-icon-change-title"
                        btnbase="ghostbuttons"
                        btntype="small_noicon"
                        disabled={props.disabled}
                        onClick={() => {
                            setPageTitle({ isEditing: true, value: props.pageElement.title });
                            props.isEditing?.();
                        }}
                    >
                        Bewerken
                    </Button>}
            </MuiGrid>
        </>
    );
};

const validatePageTitle = (pageTitle: string, errors: Record<string, ValidationErrorData[]>) => {
    return FormHelper.validateForm(validators, { pageTitle }, errors);
};

const validators = {
    'pageTitle': new TextValidator({ required: false, stringMaxLength: 200, stringType: Domain.Shared.StringType.SingleLine }),
};
