import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Grid as MuiGrid } from '@mui/material';
import { AutoFocus, IDataItemProps, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import { SelectElement } from '@liasincontrol/ui-elements';
import { typeInputCustomOptions } from '../../../../../_shared/OptionItem/FieldDefinitionOptionItem';

type Props = {
    isVisible: boolean,
    columnIndex: number,
    fieldDefinitions: Domain.Shared.FieldDefinition[],
    closeModal: () => void,
    onAddingExistingField: (fieldId: string, columnIndex: number) => void,
};

type SelectItem = IDataItemProps<string> & { required?: boolean };

/**
 * Represents a UI component that renders a field picker modal from element definition.
 */
const FieldPickerModal: React.FC<Props> = (props) => {
    const [fieldsSelectItems, setFieldsSelectItems] = useState<{ items: IDataItemProps<string>[], selectedItem: IDataItemProps<string> }>({ items: [], selectedItem: undefined });

    useEffect(() => {
        if (!props.fieldDefinitions) {
            return;
        }

        const newState: SelectItem[] = [...props.fieldDefinitions]
            .filter((item) => item.systemId !== Domain.SystemFieldDefinitions.Studio.Name && item.systemId !== Domain.SystemFieldDefinitions.Studio.Number)
            .map((item) => ({
                value: item.id,
                label: item.name,
                required: item.required,
            }));
        setFieldsSelectItems({ items: newState, selectedItem: undefined });
    }, [props.fieldDefinitions]);

    const dialogFooter: JSX.Element = (
        <ModalDialogFooter
            leftButtonText='Annuleren'
            onLeftButtonClick={props.closeModal}
            rightButtonText='Voeg toe'
            onRightButtonClick={() => {
                props.onAddingExistingField(fieldsSelectItems.selectedItem.value, props.columnIndex);
                props.closeModal();
            }}
            rightButtonDisabled={!fieldsSelectItems.selectedItem}
        />
    );

    return (
        <ModalDialog
            settings={{
                look: 'interactive',
                title: 'Veld toevoegen',
                width: 768,
                footer: dialogFooter,
            }}
        >
            <MuiGrid container
                columns={1}
                justifyContent="flex-start"
                alignItems="flex-start">
                <MuiGrid item xs={1}>
                    <AutoFocus>
                        <SelectElement<IDataItemProps<string>>
                            id='add-existing-field-selector'
                            label='Bestaand veld'
                            optionItems={fieldsSelectItems.items}
                            value={fieldsSelectItems.selectedItem}
                            clearable={true}
                            searchable={true}
                            editorSettings={{
                                restrictions: { required: true },
                                validationErrors: [],
                                onChange: (value) => setFieldsSelectItems((oldState) => ({ ...oldState, selectedItem: value })),
                            }}
                            customOptions={(customProps) => typeInputCustomOptions({ ...customProps })}
                            customSingleValue={(customProps) => typeInputCustomOptions({ ...customProps }, { isFieldTemplate: true, placeholder: 'Kies...' })}
                        />
                    </AutoFocus>
                </MuiGrid>
            </MuiGrid>
        </ModalDialog>
    );
};

export default FieldPickerModal;