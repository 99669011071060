import { createContext, useContext } from 'react';
import * as Domain from '@liasincontrol/domain';

type PublicationContextProps = {
    settings: Domain.Publisher.Publication;
    setSettings: (publication: Domain.Publisher.Publication) => void;
    workflowStates: Domain.Publisher.TaskManagerWorkflowState[];
    setWorkflowStates: (workflowStates: Domain.Publisher.TaskManagerWorkflowState[]) => void;
    loadDataSourceElement: (dataSourceId: string) => Promise<Domain.Publisher.DataSourceElement>;
    colorPalette: string;
    customColors: string[];
    studioHierarchies: Domain.Studio.HierarchyListItem[];
    setStudioHierarchies: (studioHierarchies: Domain.Studio.HierarchyListItem[]) => void;
};

const PublicationContext = createContext<PublicationContextProps>(null);

const usePublicationSettings = () => {
    const context = useContext(PublicationContext);
    return context.settings;
};

const usePublicationWorkflowStates = () => {
    const context = useContext(PublicationContext);
    return context.workflowStates;
};

const useStudioHierarhies = () => {
    const context = useContext(PublicationContext);
    return context.studioHierarchies;
};

export { PublicationContext, usePublicationSettings, usePublicationWorkflowStates, useStudioHierarhies };
