import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { Actions } from '@liasincontrol/userrights-service';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import {
    Cockpit, Overview, JournalElementKinds, BudgetJournalKinds, BudgetJournals, JournalElements, BudgetElementGroups,
    BudgetJournalKindDetails, TaskList, Obligations, BudgetAmounts, RealizationAmounts, Structures, BudgetJournalGroups, BudgetDevelopment,
    Export
} from '..';

const Contents = (
    <>
        <Route index element={<PrivateRoute component={Cockpit.index} />} />
        <Route path="admin/journalelementkinds" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={JournalElementKinds.index} />} />
        <Route path="admin/budgetjournalkinds" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={BudgetJournalKinds.index} />} />
        <Route path="admin/budgetjournalkind/:id" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={BudgetJournalKindDetails.index} />} />
        <Route path="admin/journalelements" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={JournalElements.index} />} />
        <Route path="admin/budgetelementgroups" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={BudgetElementGroups.index} />} />
        <Route path="admin/obligations" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={Obligations.index} />} />
        <Route path="admin/budgetamounts" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={BudgetAmounts.index} />} />
        <Route path="admin/realizationamounts" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={RealizationAmounts.index} />} />
        <Route path="admin/structures" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={Structures.Overview} />} />
        <Route path="admin/structures/:id" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={Structures.StructureDetails} />} />
        <Route path="admin/structures/:id/unlinked" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={Structures.UnlinkedJournalElements} />} />
        <Route path="admin/budgetjournalgroups" element={<PrivateRoute permissions={Actions.CRUD_ManageFinance} component={BudgetJournalGroups.index} />} />
        <Route path="budgets/overview" element={<PrivateRoute permissions={Actions.COMPLEX_FinanceBudget} component={Overview.index} />} />
        <Route path="budgets/export" element={<PrivateRoute permissions={Actions.COMPLEX_FinanceBudget} component={Export} />} />
        <Route path="budgets/budgetjournals" element={<PrivateRoute permissions={Actions.CRUD_Finance} component={BudgetJournals.index} />} />
        <Route path="budgets/budget-development" element={<PrivateRoute permissions={Actions.COMPLEX_FinanceExportBudgetJournals} component={BudgetDevelopment.index} />} />
        <Route path="tasks/overview" element={<PrivateRoute component={TaskList.index} />} />
        <Route path='*' element={<Navigate to='/notfound' />} />
    </>
);

export { Contents };
