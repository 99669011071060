import React, { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { WrapperContent, PageTitle, Heading1, ErrorOverlay, ResetZIndex } from '@liasincontrol/ui-basics';
import { LsTabs, TabType } from '@liasincontrol/ui-devextreme';

type Props = {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the usergroups list page.
 */
const Index: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [lastRefresh, setLastRefresh] = useState<number>();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const componentProps = {
        userIdentity: props.userIdentity,
        lastRefresh: lastRefresh,
        onError: setError,
        onRefresh: setLastRefresh,
        setSelectedTab: setSelectedTab
    };

    return (
        <WrapperContent>
            <PageTitle>
                <Heading1>Gebruikers & rollen</Heading1>
            </PageTitle>
            <ResetZIndex>
                <LsTabs
                    tabs={tabList.sort((a, b) => a.id - b.id)}
                    direction='horizontal'
                    selectedTab={selectedTab}
                    onItemClick={(e) => {
                        if (e.itemData?.id === selectedTab) return;
                        navigate(e.itemData?.path);
                    }}
                />
            </ResetZIndex>
            <ErrorOverlay
                error={error?.message}
                errorDetails={error?.details}
                onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null}
                onBack={error?.canGoBack ? () => setError(undefined) : null}>
                <ResetZIndex>
                    <Outlet context={componentProps} />
                </ResetZIndex>
            </ErrorOverlay>
        </WrapperContent>
    );
};

const tabList: TabType[] = [
    { id: 0, text: "Gebruikers", path: '/admin/usermanagement/users' },
    { id: 1, text: "Rollen", path: '/admin/usermanagement/roles' },
    { id: 2, text: "Gebruikersgroepen", path: '/admin/usermanagement/usergroup' }
];

export { Index as index };
