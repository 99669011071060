import React from "react";
import { Button } from '@liasincontrol/ui-basics';
import { SelectElement } from '@liasincontrol/ui-elements';
import { useUserSettings } from '@liasincontrol/core-service';
import { useBaseYears, useMeasureMoments } from "../../shared/hooks";
import { MeasureMoment } from '@liasincontrol/domain';
import { Grid as MuiGrid } from '@mui/material';

export interface FilterProps {
    loading: boolean;
    onFilter: (baseYear: number, measureMomentId: string) => void;
}

export const Filter: React.FC<FilterProps> = (props) => {

    const baseYears = useBaseYears();
    const measureMoments = useMeasureMoments();
    const userSettingsContext = useUserSettings();

    const measureMomentOptions = (userSettingsContext.baseYear
        ? measureMoments.items?.filter((moment) => moment.baseYear === userSettingsContext.baseYear)
        : []);

    return <MuiGrid container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 1, sm: 6, md: 12 }}
        justifyContent="flex-start"
        alignItems="flex-end">
        <MuiGrid item xs={1} sm={2}>
            <SelectElement<number>
                id='filter-base-year'
                label='Basisjaar'
                placeholder='Kies...'
                optionItems={baseYears.items}
                value={userSettingsContext.baseYear}
                clearable={true}
                searchable={false}
                editorSettings={{
                    disabled: baseYears.items?.length <= 0,
                    withoutFeedback: true,
                    onChange: (val) => {
                        userSettingsContext.setBaseYear(val);
                    },
                }}
            />
        </MuiGrid>
        <MuiGrid item xs={1} sm={3}>
            <SelectElement<MeasureMoment>
                id='select-measure-moment'
                label='Meetmoment'
                displayExpr='name'
                optionItems={measureMomentOptions}
                value={measureMomentOptions.find(mm => mm.id === userSettingsContext.measureMomentId)}
                clearable={true}
                searchable={false}
                editorSettings={{
                    disabled: !userSettingsContext.baseYear,
                    withoutFeedback: true,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (item) => {
                        userSettingsContext.setMeasureMomentId(item?.id);
                    },
                }}
            />
        </MuiGrid>
        <MuiGrid item xs={1}>
            <Button
                disabled={!userSettingsContext.baseYear || !userSettingsContext.measureMomentId || props.loading}
                btnbase="textbuttons"
                btntype="medium_noicon"
                onClick={() => props.onFilter(userSettingsContext.baseYear, userSettingsContext.measureMomentId)}>
                Ophalen
            </Button>
        </MuiGrid>
    </MuiGrid>
};
