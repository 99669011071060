/**
 * Enum with all known Publisher field definition unique identifiers (System IDs).
 */
export enum Pub {
    // The common system unique identifier of the fields that are used in multiple controls.
    AllowPatchContent = 'c409490c-21be-4c88-ad94-fb370816c47b',
    Name = 'f05ac6b7-2128-480c-88ea-3ce7ae371fdb',
    Title = '02d2d1a8-cb22-48b2-88fa-7b44eee836e5',
    HelpText = '63d38532-afb1-49a0-9c2b-a880e55e8533',
    TextualContent = '1516fb58-9d64-4160-aba1-9e9c55afae36',
    TextHtmlContent = 'c0fcf3cb-b9ba-4a63-bd24-b14802a49e0b',
    AltText = '5231ad6f-6695-4dbe-ac5e-b599bb3aa6c4',
    // The system unique identifier of the fields that are used for formatting Container Items.
    ShowBackground = 'e42497ee-91fb-4604-847e-27b1c393defc',
    ShowShadow = 'da7eec5c-19cb-4cef-8e09-8128bcfd7d63',
    ShowWhitespaceTop = 'd488cd39-4444-4101-a55a-5282c9ee64bb',
    ShowWhitespaceBottom = '69ac732f-cfe8-4dc7-94da-c67456fccf11',
    ShowWhitespaceLeft = '08e17dfa-3221-4e11-a038-d40138687fde',
    ShowWhitespaceRight = 'ef431410-2915-4923-a555-4917790d4976',
    PrintAsLandscape = 'f1c9e91c-5735-4705-a1ae-ec1fc3803339',
    // The system unique identifier of the fields of various user controls.
    StackContainerDirection = 'c242f178-1bd8-46b2-a52f-34bc58f2f5ce',
    TitleStyling = 'dc7979fb-793a-42a6-8542-a00e8bcd2422',
    ShowCompleteImage = 'bc1c27a0-01ba-40af-96a5-584b6e085d25',
    ImageFocusPoint = '5b422c1f-c674-4e51-81ff-aaf6dd797d5d',
    ImageCaption = 'aebf1712-14e2-4d00-a5bb-2bfedfa41712',
    ImageSize = 'ad7e01a0-e6eb-475a-b6a2-0dd08e5acbb2',
    ImageSource = '5cbfbd87-a311-4415-8904-3f14f5fe28ad',
    HtmlContent = '31d87756-b4cc-4310-a9e9-8df245f06b65',
    TableColumnSettings = 'b0fa3c33-e937-4496-bb5e-7bd6fbd072cf',
    PieChartArgument = '24cf9c88-8b89-4f1e-9a49-df3323425ecd',
    PieChartValue = 'b860aca9-4b4b-4306-baaa-b2cb2165c2dc',
    PieChartValueFormat = '3b5abab3-d8d3-4c09-892f-f7856e407f9c',
    PieChartType = '66c50b31-a08f-4f47-9a17-4b9502499ffa',
    PieChartCustomLabel = '6d0f9177-b550-46e7-9524-78c543baf219',
    PieChartMaximumSlices = 'd730a553-a497-4b32-a5b6-3a7aa73fbe8c',
    PieChartCombinedSliceLabel = '102a6b64-d758-4786-a429-66b98e2259f0',
    //bar chart
    BarChartArgument = '81eb826f-11dd-42ce-a5d6-eda8553e3d7e',
    BarChartValue = 'f9f870bf-039c-4f59-8e3e-da2a86f5ba8e',
    BarChartValueFormat = '8dd0fcaa-55d1-400a-8d98-020ae75f6f55',
    BarChartOrientation = '80724c41-4dcb-408e-bbc4-c6a1b6da8680',
    ChartLegend = '16b1db78-365e-4e5b-8331-20086cd31b40',
    ChartShowLabels = '10bed44d-2ef3-4394-9a3a-d18cf9e96d32',
    DataSource = 'c2c2e938-3c75-4385-b5a5-ad39c14eac98',
    ColumnsAutoHide = '9bbdf5d9-5165-48c9-ba15-26ccb16dc027',
    Filter = '61a87f36-a0bf-4ca0-b4dd-93d52286d99f',
    DataSourceMeasureMomentId = '934ec81b-efa9-4570-97ef-82129bab7a19',
    DataSourceMeasureMomentIds = '7a56e4d5-a7a2-4e3e-9578-12d95b83e2e3',
    DataSourceElementDefinitionId = 'a596b23a-a957-4add-a19e-9f8aea8d0b40',

    // The system unique identifier of the fields of the datastore.
    DataSourceDataFile = '50f9b624-d677-4fa4-a7d5-7c1cf797e946',
    DataSourceDataSchemaFile = '3800f5c4-e89c-4609-9975-5c760a2a3177',
    DataSourceLastRefreshDate = '773d6fc4-6791-4a71-aa18-aa25a5a77191',
    DataSourceAutoRefresh = '69537d67-c7ec-4473-8b26-234fb1332c31',
    DataSourceFailedRefresh = '5596305e-f60a-4fe2-a34a-63600ae33538',
    Datastore = '82c7f2fd-53c8-4b37-b456-3ab9e13e645a',
    DataTableComplexField = '208a109d-2688-46d5-b4cf-cef7ec4a34dd',
    DataSourceElement = '1492696a-64e5-4b1a-b5d9-3931b0fb86b9',
    DataSourceColumns = 'e0e207f7-e4b6-41ef-baf6-7ad32bc923bf',
    // The system unique identifier of the fields that are related to a publication.
    EndDate = '8850233b-b0d4-43df-b7cd-40885c6d9336',
    ReminderDate = 'd930982b-4c68-4ff3-a684-cb7fd786ed6a',
    AccessibilityStatement = 'b46e8f30-398b-40d0-8b4c-5329fbec291b',
    PrivacyStatement = '89f6317d-5736-4a53-b589-eef8d6a58832',
    PortalUrl = 'e6faf71a-cd1b-4380-aec7-07160a84bef5',
    H1TextColor = '7adb79bf-3222-4298-83a1-e37c06260e97',
    H1TextSize = '60b92226-534a-4107-9e5b-2de6aaee2b3a',
    H2TextColor = '743e9860-e56a-47a5-96be-de89800cb514',
    H2TextSize = 'b0ada0ca-06ca-47c4-84cc-3980997f0198',
    H3TextColor = '747c6eed-63ad-408c-8464-6e00ff4d51db',
    H3TextSize = '57d0aeae-b3db-47bd-ad0a-593a528794d8',
    MenuLevels = '4c279f96-755b-4b8c-9571-e5963a95a8e9',
    Logo = '90970f59-0734-4ea5-98da-ca7af7e0dbdf',
    Favicon = 'b22519ac-1b21-48ff-b978-10be24f09b5e',
    PrimaryColor = '4f530e2d-5e92-47ab-a7a7-47f51716f52f',
    PrimaryContrastColor = 'ed1be002-1be4-4a06-a531-500ddda5621c',
    Revisions = 'b7179e8f-8b58-4e61-b5d2-bc1d68b64860',
    SecondaryColor = '5a3a7656-1f67-4fc9-bca7-80759ae5daae',
    SecondaryContrastColor = '34f9de25-1912-403c-af33-a897dcba431a',
    SiteDesignId = '431700ef-bbc0-44c7-a86d-add6231f6e2a',
    TextColor = 'b718114c-0f5d-47a7-8d68-14382858cc41',
    TextSize = '384926e1-5423-4fa5-89de-68d847bcbde2',
    VersionName = '4745dcd5-528d-46c0-83ce-9b08b8b02836',
    PublicationWorkflowId = '2dc26d2e-792c-435f-94e7-f94ccb8cbfd1',
    PublicationMeasureMomentId = 'e81ccb2f-0641-4249-9009-643de96c847a',
    GoogleAnalyticsCode = '933e6147-0d6a-4c90-91fd-3054e9eee8ab',
    IsClosedPublication = '300cb5a3-d8fc-4852-abc0-ce136b2ecb56',
    ColorPalette = 'be3bf2f9-1e3c-4852-bbaa-13e0535d2ea8',
    CustomColorPaletteColors = '2b5c5e54-e9b5-4c5e-9fa0-84176c505ad2',
    CoverImage = '5f3f6640-188e-4de5-9e30-d0eb06e57567',
    OrganizationName = '136670c3-e136-4c6d-855f-1e7f7f8f49f6',
    // The system unique identifier of the fields that are related to a page.
    PageDescription = '32152c80-5de5-455c-8504-98ebb53d5c4d',
    PageImageFocalPoint = '9f263bb6-9386-4e1b-81b4-c1dc4b8f7cda',
    PageImage = 'cba745f7-665d-49c6-89f3-8dff26ed74d2',
    // The system unique identifier of the fields that are related to a datastore.
    Type = '1dc36ddb-452a-4118-89b8-1324bb2a3bae',
    ConnectionInformation = 'f4e89ae8-60ea-412a-980f-160d88ba501c',
    SourceFile = '5e03baa4-7481-4fc6-8746-90dd24bba7d6',
    DataPlatformView = '8606fe0c-3268-4dd5-bf32-b3f4b7dc011f',
    HierarchyDefinition = '2e6cc82c-a4fb-4815-b6d3-9f41d2839956',
    FinanceTable = '9f7029a3-94a8-4b61-8dc2-ef88787a4250',
    // The system unique identifier of the fields that are related to a template.
    Layout = 'ae5a4f28-e3ba-4c57-8154-eb711d35697f',
    PageDesignId = '5b0177c8-f473-4fb5-a908-bb4d5aa477ce',
    // The system unique identifier of the fields that are related to a menu element.
    MenuType = 'bab4bf69-9cde-463d-a992-9a195c994298',
    // The system unique identifier of the fields that are related to a section container element.
    SectionContainerKind = 'a52ce50d-8d98-43e0-9582-134dca92282d',
    // Client-side generated GUIDs, that are only used to group together related fields.
    VirtualWhitespaceGroupField = '72e3e50a-b2d2-4251-948a-0a1f8890bd35',
    VirtualVariablesField = '1aaeee95-6a7c-42ba-95af-4750b99f3d24',
    //Workflow fields
    WorkflowIsActive = 'b86b8a98-dac4-451a-a9fd-b8b38cf83be8',
    // Workflow State Definition fields
    WorkflowStateCategory = '548f4d29-d482-4973-a096-80f8562fdae1',
    WorkflowStateParentWorkflowId = 'bcb2f1ff-74a9-4601-bcad-eefb60d26bed',
    // ElementWorkflowStatus fields
    ElementWorkflowState = 'a0eda035-6627-4cef-8d62-06b6315ac593',
    ElementWorkflowAssignedUserId = 'e8a3d939-3734-4b37-b12e-8cada3ed90a9',
    // Reference attachments fields
    AttachmentsComplex = 'b88946ea-97f8-473d-afe8-ba161b09e8f6',
    FileSource = '9d0353a0-e918-4d4d-9f4e-c181a13e1ef2',
    FileName = '604ce124-3ae1-4e2d-9d83-69de3a5d1701',
    FileSize = 'c2539b63-8c65-4847-96fc-948a69ce04a1',
    // Datasource Text fields
    DataSourceTitleColumn = '637745a5-9cfe-47e8-b0d8-a745d4a16e88',
    DataSourceTextColumn = '2325beae-28ff-4e7b-b53a-3ed4efa5d909',
    DataSourceStructure = 'cba96510-c982-4ab4-ab36-8fc0774d5d90',
    // Performance controls shared fields
    MeasureMomentId = 'ecfd2068-ef5c-4d47-9c81-a7ee8656e608',
    EntityTypeId = 'f88effb8-f980-41e8-a316-5864a05e4208',
    // Performance information control fields
    EntityId = '275bf636-7e77-4362-b28b-f72979cd6ede',
    FieldId = 'dab259f5-9813-441d-a5f9-83aeee8b4012',
    // Performance data control fields
    ParentEntityTypeId = 'da6f5526-6eaa-4272-a4d8-2ac0cc23e083',
    ParentEntityId = '1a8affb8-c3bf-4aa0-a8ed-38b5b00bd0dd',
    HeaderFieldId = 'c53faad8-fc70-431b-aa6d-6ace840b818d',
    FieldsListJson = '0132f121-22d8-4ede-a3d5-497d0421ffcf',
    HierarchyDefinitionId = '0e09cd03-6f22-4cb0-b2b1-2a6ccd30b380',
    IsStudioControl = 'dad7aeca-7606-427e-89c3-78f807d24eef',
    // TileMenu control
    TilesComplex = '4c2f4c4c-1994-466f-bfb7-da2b22eadba1',
    TilePage = 'e87b90bf-fb47-4214-995e-a31d8e9b80da',
    TileDescription = '3e8de8b7-ba18-400d-81b2-f68ab139a53b',
    TileImage = '3ed5a0f4-d12f-4077-83cf-075ad9f6fc3f',
    TileFocusPoint = 'aa233689-beaa-4b56-85a1-346212d3efad',
    TileImageKind = '6ed1e739-be7d-4069-ac6e-2cb69d1ec8e4',
    // DataTable
    TablePanelIsVisible = '136e4375-da0c-4b28-8994-981e3f1cb3d3',
    HeaderField = 'a29b014d-01c8-4fd5-8010-75ff9c562228',
    DataTableDescription = '9318c535-1819-4599-a683-9831f04b0cbb',
    GrandSummaryText = '58a30764-f8f6-42b4-af93-09aff6b48acb',
    //Map
    MapDescription = 'f06cd70b-fd92-4615-b152-cec66a42614a',
    Latitude = '76c78943-69cc-4b8d-a70e-63909fcdc329',
    Longitude = '928e9c2d-15dd-4a82-b813-08aa0db19812',
    LabelColumnName = '63794689-6723-4542-a272-2b9de9c237c7',
    MapSize = 'cc70cd33-ba38-409a-a685-47acbaff67ca',
    //Pivottable
    PivotTableDescription = '8dbf7e87-26de-41b1-9e6f-a17e8174f71f',
    PivotGridFields = 'f38fa8d1-88f3-4ad6-a85f-c94771179cb7',
    ShowColumnGrandTotals = '7da9e057-cd9b-4e4f-99bd-f2ad425e2c8c',
    ShowColumnTotals = 'e3ff3e6b-e120-4f0e-911b-a18de87097f7',
    ShowRowGrandTotals = '84b001d8-47b1-4201-a579-13f3888d99bf',
    ShowRowTotals = '12470d0b-15ae-40f0-ae6b-27898c872987',
    PivotTableGrandTotalCaption = '14020444-9ffc-4c48-81be-39eb96b5ca96',

    //Tab
    TabPosition = '29d1f5c3-e9bc-49f1-a0f3-4c85b61c9e69',
    TabIconPosition = '875444fc-b07a-424a-b746-473392e0ef7a',
    TabIcon = 'aec268ff-8efc-40a8-8874-5eb9339f1401',
    TitleColumn = 'e41d2245-2ae4-4438-96bc-3fc89b45b3ca',

    // Client-side generated GUIDs, that are only used to make a secondary field for pivottable advanced tab.
    VirtualPivotGridFields = 'f1d61263-7e54-4ea6-91e3-ae0af7ba4ac8',

    //Variables
    VariablesComplex = '2c8c2903-4ea9-4e46-ab60-a3252cf0538d',
    VariableNameField = '6400e96e-55d5-40ce-acf9-450f47cfb0ad',
    VariableValueField = '5ddfe976-bddc-4145-bee2-639907a6e81f'

}


/**
 * Enum with all known Core field definition unique identifiers (System IDs).
 */
export enum Core {
    // Report period field system unique identifiers.
    ReportPeriodName = '2c09ae46-decd-4d8a-bb43-ac9572445bc6',
    BaseYear = '4b6e6cd8-3ea0-45b1-905c-4b7a9c6d3c4f',
    StartDate = '2dbae683-bdea-479a-a435-824a5c14d2e7',
    EndDate = '6ba2bb69-3dbe-404e-afb0-d153cc10c2c5',
}

/**
 * Enum with all known Performance Management field definition unique identifiers (System IDs).
 */
export enum Performance {
    // The system unique identifier of the fields that are related to a performance hierarchy item.
    Number = 'ab916003-b863-4ced-977c-bb3314529b8a',
    Name = 'f05ac6b7-2128-480c-88ea-3ce7ae371fdb',
    Description = '51a71e84-d99f-47ae-8a13-e227950d66fc',
    Amount = 'c4075df0-29b2-4f93-9c50-35581b2cd47a',
    Progress = 'd4ef22e2-8e1d-4b25-bcbc-3442670287ee',
    Explanation = 'e08e8abf-557c-4686-a72f-c9d54e530f75',
    Result = 'a9c600ba-bf3a-440c-b1fa-fd453af9f0e2',
    Attachment = '05597cba-3efb-4b1c-b2a7-69bf1b539aba',
    AttachmentFile = '5feadba8-4573-446d-9da3-37bea044fd29',

    GoalStatus = 'ddcf1439-42a9-44b1-b9a0-f6f3631a5a33',
    AchievementStatus = '73438479-bdb3-43dc-bc55-fad70207d413',
    ActivityStatus = '54e79129-4a8e-41c4-a2b1-2a00c62b08b4',
    MeasureMomentStatus = 'e59490fa-5914-492e-a734-8aff215f6ef5',
    MeasureMomentOrder = '4fc42261-21ad-478c-95f4-d992dcd6b7dd',
    MeasureMomentBaseYear = 'be48ffee-8869-46be-ae7b-c4738096fdd1',

    StartDate = '1ce7125d-eb4f-478d-b23c-3da011850290',
    EndDate = '24f37557-93e0-4d62-9a05-7d8a7879b0c9',
}

/**
 * Enum with all known Studio field definition unique identifiers (System IDs).
 */
export enum Studio {
    // The system unique identifier of the fields that are related to a studio hierarchy item.
    Number = 'a7d7bcad-f20d-450a-8d5b-affbd479c788',
    Name = 'f05ac6b7-2128-480c-88ea-3ce7ae371fdb',
    Dynamic = '16b179bc-597f-4116-a345-2b15fc58e345',
}
