import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { State, AjaxRequestStatus, FinanceBaseYearsActionCreator } from '@liasincontrol/redux-service';
import { Finance } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { SelectElement, TextElement } from '@liasincontrol/ui-elements';
import { ErrorOverlay, Heading2, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { FilterModel, JournalElementsGrid } from '../../components/JournalElementsGrid';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const Index: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>();
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [journalElementKinds, setJournalElementKinds] = useState<Domain.Finance.JournalElementKind[]>([]);
    const [filter, setFilter] = useState<FilterModel>({});

    useEffect(() => {
        const journalElementKinds = Finance.JournalElementKindDataAccessor.getAll();
        journalElementKinds.then((journalElementKindsResult) => {
            setJournalElementKinds(journalElementKindsResult.data);
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        });
    }, [lastRefresh]);

    if (props.baseYears.status === AjaxRequestStatus.NotSet) {
        props.fetchBaseYears();
        return null;
    }

    return (
        <>
            <Wrapper>
                <WrapperContent>
                    <PageTitle>
                        <Heading2>Boekingselementen </Heading2>
                    </PageTitle>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        <Section look='white'>
                            <MuiGrid container
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 1, md: 8 }}
                                justifyContent="flex-start"
                                alignItems="flex-end">
                                <MuiGrid item xs={1} md={1}>
                                    <SelectElement<number>
                                        id='select-base-year'
                                        label='Basisjaar'
                                        optionItems={props.baseYears.items}
                                        value={filter.baseYear}
                                        clearable={true}
                                        searchable={false}
                                        editorSettings={{
                                            disabled: false,
                                            restrictions: { required: false },
                                            validationErrors: [],
                                            onChange: (item) => setFilter(prev => ({ ...prev, baseYear: item })),
                                        }}
                                    />
                                </MuiGrid>
                                <MuiGrid item xs={1} md={2}>
                                    <SelectElement<Domain.Finance.JournalElementKind>
                                        id='select-journalkind'
                                        label='Elementsoort'
                                        displayExpr='name'
                                        optionItems={journalElementKinds}
                                        value={filter.journalElementKind}
                                        clearable={true}
                                        searchable={false}
                                        editorSettings={{
                                            disabled: false,
                                            restrictions: { required: false },
                                            validationErrors: [],
                                            onChange: (item) => setFilter(prev => ({ ...prev, journalElementKind: item })),
                                        }}
                                    />
                                </MuiGrid>
                                <MuiGrid item xs={1} md={2}>
                                    <TextElement id='search-externalCode' value={filter.code}
                                        label='Externe code'
                                        editorSettings={{
                                            disabled: false,
                                            restrictions: { required: false },
                                            validationErrors: [],
                                            onChange: (value: string) => setFilter(prev => ({ ...prev, code: value })),
                                        }}
                                    />
                                </MuiGrid>
                                <MuiGrid item xs={1} md={2}>
                                    <TextElement id='search-name' value={filter.name}
                                        label='Naam'
                                        editorSettings={{
                                            disabled: false,
                                            restrictions: { required: false },
                                            validationErrors: [],
                                            onChange: (value: string) => setFilter(prev => ({ ...prev, name: value })),
                                        }}
                                    />
                                </MuiGrid>
                            </MuiGrid>
                            <JournalElementsGrid
                                journalElementKinds={journalElementKinds}
                                filter={filter}
                                onError={setError}
                            />
                        </Section>
                    </ErrorOverlay>
                </WrapperContent>
            </Wrapper>
        </>
    );
};

const mapStateToProps = (state: State) => {
    return {
        baseYears: {
            items: state.finance.baseYears.items,
            status: state.finance.baseYears.status,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBaseYears: () => {
            dispatch(FinanceBaseYearsActionCreator.set())
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };

