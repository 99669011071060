import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '@liasincontrol/redux-service';

import { useNavigate, useOutletContext } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { Bar, Button, Section } from '@liasincontrol/ui-basics';
import { ContextMenu, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { ApiErrorReportingHelper, FormMode } from '@liasincontrol/core-service';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { UserGroupAdd } from './UserGroupAdd';
import { ActionSource, ModulesActionCreator, State, UserGroupsActionCreator, UsersActionCreator } from '@liasincontrol/redux-service';
import { ConfirmDialog } from '../../_shared/ConfirmDialog';

type Props = {
    userIdentity: UserIdentity,
    lastRefresh?: number;
    onError: (error: Domain.Shared.ErrorInfo) => void;
    onRefresh?: (tick: number) => void;
    setSelectedTab?: (tabIndex: number) => void;
};

/**
 * Represents a UI component that renders the usergroups list page.
 */
const Index: React.FC = () => {
    const props = useOutletContext<Props>();
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
    const modules = useSelector((state: State) => state.modules[ActionSource.Publication]);
    const [userGroupAddDialog, setUserGroupAddDialog] = useState<{ showDialog: boolean, mode: FormMode, isSaving: boolean, groupData?: Domain.Shared.UserGroup }>({ showDialog: false, mode: FormMode.View, isSaving: false });
    const [isGroupDeleting, setIsGroupDeleting] = useState<{ visible: boolean, data?: Domain.Shared.UserGroup }>({ visible: false });
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [userGroups, setUserGroups] = useState<Domain.Shared.UserGroup[]>([]);
    const userGroupNames = useMemo(() =>
        userGroups?.reduce((names, ug) => {
            if (ug.name !== userGroupAddDialog.groupData?.name) {
                names.push(ug.name);
            }
            return names;
        }, [] as string[]),
        [userGroups, userGroupAddDialog.groupData]);

    const hasManageUsersAccess = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups);
    const canCreate = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Create);
    const canUpdate = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Update);
    const canDelete = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Delete);

    const availableColumns: GridColumn<Domain.Shared.UserGroup>[] = getColumnConfiguration(
        canUpdate ? (item: Domain.Shared.UserGroup) => onEdit(item) : null,
        canDelete ? (item: Domain.Shared.UserGroup) => setIsGroupDeleting({ visible: true, data: item }) : null
    );

    useEffect(() => {
        props.setSelectedTab(2);
        SharedDataAccess.UserGroups.get()
            .then((response) => {
                props.onError(undefined);
                setUserGroups(response.data);
            }).catch((err) => {
                props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });
    }, [props.lastRefresh]);

    if (!modules) {
        dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} })).unwrap();
        return null;
    }

    const onSaveChanges = (userGroupName: string, mode: FormMode) => {
        setUserGroupAddDialog({ showDialog: true, mode: FormMode.AddNew, isSaving: true });
        if (mode === FormMode.AddNew)
            SharedDataAccess.UserGroups.create(userGroupName)
                .then(() => {
                    props.onRefresh(Date.now());
                    dispatch(UserGroupsActionCreator.set()).unwrap();
                }).catch((err) => {
                    props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
                }).finally(() => {
                    setUserGroupAddDialog({ showDialog: false, mode: FormMode.View, isSaving: false });
                });
        else if (mode === FormMode.Edit) {
            const changedData: Domain.Dto.Shared.UpdateUserGroup = {
                name: userGroupName,
                userIds: userGroupAddDialog.groupData.userIds,
            };

            SharedDataAccess.UserGroups.update(userGroupAddDialog.groupData.id, changedData)
                .then(() => {
                    dispatch(UserGroupsActionCreator.set()).unwrap();
                    dispatch(UsersActionCreator.set()).unwrap();
                    props.onRefresh(Date.now());
                }).catch((err) => {
                    props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err));
                }).finally(() => {
                    setUserGroupAddDialog({ showDialog: false, mode: FormMode.View, isSaving: false });
                });
        }
    };

    const onEdit = (userGroup: Domain.Shared.UserGroup) => {
        setUserGroupAddDialog({ showDialog: true, mode: FormMode.Edit, isSaving: false, groupData: userGroup });
    };

    const onDelete = (userGroupId: string) => {
        setIsBusy(true);
        SharedDataAccess.UserGroups.delete(userGroupId)
            .then(() => {
                props.onRefresh(Date.now());
                dispatch(UserGroupsActionCreator.set()).unwrap();
            }).catch((err) => {
                props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, err));
            }).finally(() => {
                setIsGroupDeleting({ visible: false });
                setIsBusy(false);
            });
    };

    return (
        <>
            {hasManageUsersAccess && <Bar look='toolbar'>
                <Bar start>
                    <Button
                        id='usergroup-create-btn'
                        btnbase='textbuttons'
                        btntype='medium_icon'
                        icon={<Add />}
                        disabled={!canCreate}
                        onClick={() => setUserGroupAddDialog({ showDialog: true, mode: FormMode.AddNew, isSaving: false, groupData: undefined })}>
                        Nieuw
                    </Button>
                </Bar>
            </Bar>
            }
            <Section look='white'>
                {userGroups && availableColumns &&
                    <LsGrid
                        dataSource={userGroups}
                        columns={availableColumns}
                        enableColumnChooser={false}
                        searching={false}
                        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                        showRowLines={true}
                        onClickRow={(item: Domain.Shared.UserGroup) => {
                            navigate(`/admin/usermanagement/usergroup/${item.id}/information`);
                        }}
                        enableFilterRow={true}
                    />
                }
            </Section>
            {canCreate && userGroupAddDialog.showDialog && <UserGroupAdd
                userGroupNames={userGroupNames}
                mode={userGroupAddDialog.mode}
                group={userGroupAddDialog.groupData}
                onSave={(name: string) => onSaveChanges(name, userGroupAddDialog.mode)}
                onCancel={() => setUserGroupAddDialog({ showDialog: false, mode: FormMode.View, isSaving: false, groupData: undefined })}
            />
            }
            {canDelete && isGroupDeleting.visible ? (
                <ConfirmDialog
                    look="message"
                    title="Verwijder gebruikersgroep"
                    text="Weet u zeker dat u de gebruikersgroep wilt verwijderen?"
                    confirmButtonText="Verwijderen"
                    disableConfirmation={isBusy}
                    onCancel={() => setIsGroupDeleting({ visible: false })}
                    onConfirm={() => onDelete(isGroupDeleting.data?.id)} />
            ) : null}
        </>
    );
};

const getColumnConfiguration = (
    onEdit: (item: Domain.Shared.UserGroup) => void,
    onDelete: (item: Domain.Shared.UserGroup) => void
): GridColumn<Domain.Shared.UserGroup>[] => {
    return [
        {
            name: 'name',
            title: 'Gebruikersgroep',
            width: '50%',
        },
        {
            name: 'userIds',
            title: 'Leden',
            calculateDisplayValue: (item) => item.userIds.length,
        },
        {
            name: 'id',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => ((onEdit || onDelete) && (
                <ContextMenu<Domain.Shared.UserGroup>
                    keyExpr='id'
                    item={data}
                    actions={[
                        {
                            action: onEdit,
                            actionName: `edit-${data.id}`,
                            displayName: 'Bewerken',
                            ariaLabel: `Bewerk ${data.name}`,
                            hidden: !onEdit
                        },
                        {
                            action: onDelete,
                            actionName: `delete-${data.id}`,
                            displayName: 'Verwijderen',
                            ariaLabel: `Verwijder ${data.name}`,
                            hidden: !onDelete
                        }
                    ]}
                />))
        }
    ];
}

export { Index as index };
