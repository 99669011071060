import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import Styled from './index.styled';

type Props = {
    publicationElement: Domain.Publisher.PublicationElement;
    sitemap: Domain.Publisher.Sitemap;
    selectedElementId?: string,
}

/**
 * Represents UI component that renders the primary menu of a publication.
 */
const PrimaryMenuControl: React.FC<Props> = (props) => {
    const location = useLocation();
    const [isMenuExpanded, setMenuExpanded] = useState(false);

    const onItemButtonClick = (e) => {
        const button = e.target;
        const isExpanded = button.getAttribute('aria-expanded') === 'true';
        button.setAttribute('aria-expanded', !isExpanded);
    };

    if (!props.publicationElement || !props.sitemap) {
        return null;
    }
    const selectedPageId = location.pathname.split("/").pop();

    // The default value comes from the field definition, where it is set to 2, 
    // but it's a big overhead to drag fieldDefinitions in this scope just to get that number.
    const menuDepthLevel = props.publicationElement.menuLevels || 2;

    const handleMouseEnter = (e) => {
        const target = e.target;
        const button = target.getElementsByTagName('Button')[0];
        if (button) {
            button.setAttribute('aria-expanded', "true");
        }
    };

    const handleMouseLeave = (e) => {
        const target = e.target;
        const button = target.getElementsByTagName('Button')[0];
        if (button) {
            button.setAttribute('aria-expanded', false);
        }
    };

    const createAnchorElement = (item: Domain.Publisher.SitemapNode, currentPageId: string, iconName?: string) => {
        const newPath = location.pathname.replace(/([^/]*)$/, item.elementId);
        return <Link to={newPath} aria-current={item.elementId === currentPageId} >
            {iconName ? <span className="material-icons">{iconName}</span> : item.elementName}
        </Link>
    };

    const createDropDownButton = (item: Domain.Publisher.SitemapNode) => {
        return <button
            aria-haspopup="true"
            aria-expanded="false"
            aria-label={`Schakel navigatie voor ${item.elementName ?? "pagina"}`}
            aria-controls={`menu-${item.elementId}`}
            onClick={onItemButtonClick}
        >
        </button>
    };

    const createSubNavigation = (item: Domain.Publisher.SitemapNode, currentLevel: number) => {
        return (<ul
            className="submenu"
            id={`menu-${item.elementId}`}
            aria-label={`Navigatie voor ${item.elementName ?? "pagina"}`}
        >
            {item.children.map(i => createListItemElement(i, selectedPageId, true, currentLevel))}
        </ul>);
    };

    const createListItemElement = (page: Domain.Publisher.SitemapNode, currentPageId: string, includeChildren: boolean, currentLevel: number, iconName?: string) => {
        return (
            <li
                className={page.elementId === currentPageId ? "menu-item-link_active" : ""}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="menu-item-wrapper">
                    {createAnchorElement(page, currentPageId, iconName)}
                    {includeChildren && page.children.length > 0 && currentLevel < menuDepthLevel ? createDropDownButton(page) : null}
                </div>
                {includeChildren && page.children.length > 0 && currentLevel < menuDepthLevel ? createSubNavigation(page, currentLevel + 1) : null}
            </li>);
    };

    const homePageElement = createListItemElement(props.sitemap.node, selectedPageId, false, 1, 'home');
    const listItemElements = props.sitemap.node.children.map(item => createListItemElement(item, selectedPageId, true, 1));

    return (<Styled.MainNavigationWrapper>
        <Styled.MainNavigation
            className="site-primary-menu"
            aria-label="Hoofdnavigatie"
            primaryColor={props.publicationElement.primaryColor}
            primaryTextColor={props.publicationElement.primaryContrastColor}
        >
            <button
                id="hamburger-button"
                className="site-primary-menu-hamburger"
                aria-label="Toggle menu"
                aria-expanded={isMenuExpanded}
                onClick={() => setMenuExpanded(!isMenuExpanded)}>
            </button>

            <ul id="main-menu" className="menu">
                {homePageElement}
                {listItemElements.map(item => item)}
            </ul>
        </Styled.MainNavigation>
    </Styled.MainNavigationWrapper>
    );
};

export default PrimaryMenuControl;
