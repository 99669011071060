import React, { useEffect, useMemo } from 'react';
import buildQuery from 'odata-query';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus } from '@liasincontrol/ui-basics';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { TextElement, ToggleElement, LookupEditor } from '@liasincontrol/ui-elements';
import { TextValidator, ValidatorsDictionary, FormData, BasicValidator, DateUtils, FormMode, FormHelper, FieldsHelper, ValueType } from '@liasincontrol/core-service';
import { DxCustomStore, DxDataSource, } from '@liasincontrol/ui-devextreme';
import { getType } from '../../DataSourcesList/index.helper';

type Props = {
    form: FormData<ValueType>,
    mode: FormMode,
    selectedDataStore: { id: string, kind: string },
    elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    onChange: (formData: FormData<ValueType>) => void,
    onDataStoreChange?: (id: string, kind: string, element: Domain.Publisher.DataStoreElement) => void,
    onError?: (error: Domain.Shared.ErrorInfo) => void,
    inValidateForm?: () => void,

};

/**
 * Represents a UI component that renders a sub form with shared fields.
 */
const SharedForm: React.FC<Props> = (props) => {
    const fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>
        = useMemo(() => props.elementdefinitions[Domain.SystemElementDefinitions.Pub.DataSource].fields.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {}), [props.elementdefinitions]);
    const complexFieldDefinitions: Record<string, Domain.Shared.FieldDefinition>
        = useMemo(() => props.elementdefinitions[Domain.SystemElementDefinitions.Pub.DataSource].complexFields[0].fields.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {}), [props.elementdefinitions]);
    const validators = getValidators(fieldDefinitions, props.mode);

    useEffect(() => {
        props.inValidateForm?.();
    }, []);

    const storeFormValues = (values: Record<string, ValueType>): void => {
        const clonedForm = FormHelper.handleFormValuesChanged(values, props.form);
        const { errors } = FormHelper.validateForm(validators, clonedForm.values, clonedForm.validationErrors, []);
        clonedForm.validationErrors = { ...props.form.validationErrors, ...errors };
        clonedForm.isValid = !Object.values(clonedForm.validationErrors).some(a => a.length > 0);
        props.onChange(clonedForm);
    };

    const nameDefinition = fieldDefinitions[SystemFieldDefinitions.Pub.Name];
    const lastRefreshDateDefinition = fieldDefinitions[SystemFieldDefinitions.Pub.DataSourceLastRefreshDate];
    const dataSourceAutoRefreshDefinition = fieldDefinitions[SystemFieldDefinitions.Pub.DataSourceAutoRefresh];
    const dataStoreDefinition = complexFieldDefinitions[SystemFieldDefinitions.Pub.Datastore];

    const customDataSource = useMemo(() => {
        const source = new DxDataSource({
            paginate: true,
            pageSize: 15,
            store: new DxCustomStore({
                key: 'id',
                loadMode: 'processed',
                cacheRawData: false,
                load: (loadOptions) => {
                    if (!loadOptions) {
                        return Promise.resolve([]);
                    }

                    const selectedColumns = Object.keys(new Domain.Publisher.DataStoreListItem());
                    const escapedSearchTerm = !!loadOptions.searchValue ? `${encodeURIComponent(`"${loadOptions.searchValue.replace(/[\\"]/g, '\\$&')}"`)}` : '';

                    const query = buildQuery({
                        search: escapedSearchTerm,
                        select: selectedColumns.join(','),
                        top: loadOptions.take,
                        count: true,
                        skip: loadOptions.skip,
                    });

                    return DataAccess.DataStores.getDataStores(query)
                        .then((response) => {
                            return { data: response.data.value };
                        });
                },
                byKey: (key) => {
                    if (!key) {
                        return Promise.resolve({});
                    }

                    return DataAccess.DataStores.getDataStore(key).then((response) => {
                        const dataStoreItem = new Domain.Publisher.DataStoreElement();
                        const dataStoreElementDefinition = props.elementdefinitions[Domain.SystemElementDefinitions.Pub.DataStore];
                        FieldsHelper.mapObject<Domain.Publisher.DataStoreElement>(
                            dataStoreItem,
                            dataStoreElementDefinition.fields,
                            response.data.fields
                        );
                        return dataStoreItem;
                    });
                }
            }),
        });
        return source;
    }, [props.elementdefinitions]);

    return (<>
        {/* Name Field - visible on view-mode, addnew-mode and edit-mode */}
        <MuiGrid item xs={1} sm={2} md={4} key={nameDefinition.id}>
            <AutoFocus>
                <TextElement
                    id='datasource-name'
                    key='key-datasource-name'
                    label={nameDefinition.label ? nameDefinition.label : nameDefinition.name}
                    editorSettings={{
                        disabled: props.mode === FormMode.View,
                        restrictions: validators[SystemFieldDefinitions.Pub.Name] ? validators[SystemFieldDefinitions.Pub.Name].getRestrictions() : undefined,
                        validationErrors: props.form.touched[SystemFieldDefinitions.Pub.Name] ? props.form.validationErrors[SystemFieldDefinitions.Pub.Name] : [],
                        onChange: (val: string) => {
                            const fields = {
                                [SystemFieldDefinitions.Pub.Name]: val,
                            };
                            storeFormValues(fields);
                        }
                    }}
                    value={props.form.values[SystemFieldDefinitions.Pub.Name]}
                />
            </AutoFocus>
        </MuiGrid>
        {/* Last RefreshField - visible on view-mode */}
        {props.mode === FormMode.View ? (
            <MuiGrid item xs={1} sm={2} md={4} key={lastRefreshDateDefinition.id}>
                <TextElement
                    id='datasource-lastRefresh'
                    key='key-datasource-lastRefresh'
                    label={lastRefreshDateDefinition.label ? lastRefreshDateDefinition.label : lastRefreshDateDefinition.name}
                    value={props.form.values[SystemFieldDefinitions.Pub.DataSourceLastRefreshDate] ? DateUtils.formatDateTime(new Date(props.form.values[SystemFieldDefinitions.Pub.DataSourceLastRefreshDate])) : ''}
                    editorSettings={{}}
                />
            </MuiGrid>
        ) : null}
        {/* Datastore-field - visible on view-mode and addnew-mode */}
        {props.mode === FormMode.AddNew ? (
            <MuiGrid item xs={1} sm={2} md={4} key={dataStoreDefinition.id}>
                <LookupEditor
                    id={`datasource-datastore-${dataStoreDefinition.id}`}
                    key={`key-${dataStoreDefinition.id}`}
                    label={dataStoreDefinition.label ? dataStoreDefinition.label : dataStoreDefinition.name}
                    placeholder={'Kies…'}
                    value={props.form.values[SystemFieldDefinitions.Pub.Datastore]}
                    displayExpr='name'
                    valueExpr='id'
                    dataSource={customDataSource}
                    editorSettings={{
                        disabled: false,
                        restrictions: validators[SystemFieldDefinitions.Pub.Datastore] ? validators[SystemFieldDefinitions.Pub.Datastore].getRestrictions() : undefined,
                        validationErrors: props.form.touched[SystemFieldDefinitions.Pub.Datastore] ? props.form.validationErrors[SystemFieldDefinitions.Pub.Datastore] : [],
                        onChange: (val) => {
                            //TODO, how to get this without loadByKey?
                            customDataSource.store().byKey(val).then((res) => {
                                const selectedType = getType(
                                    props.elementdefinitions[Domain.SystemElementDefinitions.Pub.DataStore].fields.reduce(
                                        (collection, item) => ({ ...collection, [item.systemId]: item }),
                                        {}
                                    ), res?.typeId) as Domain.Publisher.DataStoreTypes;

                                const mappedType = Object.keys(Domain.Publisher.DataStoreTypesDictionary).find((key) => Domain.Publisher.DataStoreTypesDictionary[key] === selectedType);
                                props.onDataStoreChange(val, mappedType, res);
                            });

                            const fields = {
                                [SystemFieldDefinitions.Pub.Datastore]: val,
                                [SystemFieldDefinitions.Pub.DataSourceMeasureMomentId]: '',
                                [SystemFieldDefinitions.Pub.DataSourceElementDefinitionId]: '',
                                [SystemFieldDefinitions.Pub.DataSourceColumns]: '',
                                [SystemFieldDefinitions.Pub.Filter]: '',
                            };
                            storeFormValues(fields);
                        }
                    }}
                />
            </MuiGrid>
        ) : null}
        <MuiGrid item xs={1} md={4} key={dataSourceAutoRefreshDefinition.id}>
            <ToggleElement
                id='datasource-autorefresh'
                key='key-datasource-autorefresh'
                label='Automatisch bijwerken'
                editorSettings={{
                    disabled: props.mode === FormMode.View,
                    restrictions: validators[SystemFieldDefinitions.Pub.DataSourceAutoRefresh] ? validators[SystemFieldDefinitions.Pub.DataSourceAutoRefresh].getRestrictions() : undefined,
                    validationErrors: props.form.touched[SystemFieldDefinitions.Pub.DataSourceAutoRefresh] ? props.form.validationErrors[SystemFieldDefinitions.Pub.DataSourceAutoRefresh] : [],
                    onChange: (val: boolean) => {
                        const fields = {
                            [SystemFieldDefinitions.Pub.DataSourceAutoRefresh]: val,
                        };
                        storeFormValues(fields);
                    }
                }}
                value={props.form.values[SystemFieldDefinitions.Pub.DataSourceAutoRefresh]}
            />
        </MuiGrid>
    </>
    );
}

const getValidators = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>, mode: FormMode): ValidatorsDictionary => {
    if (!fieldDefinitions) return {};
    const validators = {
        [SystemFieldDefinitions.Pub.Name]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.Name].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMaxLength,
            stringMinLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMinLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringType
        }),
        [SystemFieldDefinitions.Pub.Datastore]: new BasicValidator<string>({ required: mode === FormMode.AddNew }),
    };
    return validators;
};

export { SharedForm };
