/**
 * Represents the possible permissions that can be assigned to a UserRole.
 * 
 * WARNING: Enum must be in sync with the pub API backend (use the same names and order as the Pub API backend enum AppPermissions).
 */
export enum UserPermissions {
    None = 0,

    // Global Administration category
    ManageUsers = 1,
    ManageRoles = 2,
    ManageDataStores = 3,
    ManageWorkflows = 4,
    ManageMeasureMoments = 5,

    // Publisher category
    ManagePublication = 6,
    PublishPublication = 7,
    ManagePublicationSitemap = 8,
    ManagePublicationTemplates = 9,
    ManagePublicationDataSources = 10,
    RefreshPublicationDataSources = 11,
    ManagePublicationProfiles = 12,
    ApplyPublicationProfiles = 13,

    // Performance,
    ManagePerformanceElementDefinitions = 15,
    GlobalPerformanceElementsContributer = 16,

    // Studio
    ManageStudioElementDefinitions = 17,
    ManageStudioHierarchyDefinitions = 18,
    GlobalStudioElementsContributer = 19,

    // Finance
    ViewFinanceBudgetOverview = 20,

    // Task
    ManageTasks = 21,
    TasksOverview = 22,

    // Finance
    ManageFinance = 23,
    ChangeBudgetJournalWorkflowState = 24,

    // Studio hierarchies
    ManageStudioHierarchies = 25,

    // Dataloader
    ViewDataloaderStatus = 26,
    GenerateDataloaderUploadUri = 27,

    // Copy Performance hierarchy
    CopyPerformanceHierarchy = 28,

    ViewFinanceBudgetDevelopment = 29,
    ExportBudgetJournal = 30,
}
