import React, { useMemo, useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { FormData, FormMode, TextValidator, ValidationErrorData, ValidationUtils, ValidatorsDictionary, ValueType } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus, IconSize, Label, palette, SVGIcon } from '@liasincontrol/ui-basics';
import { ColorPickerElement, IconSelectElement, TextElement } from '@liasincontrol/ui-elements';
import Styled from './index.styled';

type Props = {
    elementDefinition?: Domain.Shared.ElementDefinition,
    elementDefinitionNames: string[],
    mode: FormMode,
    form: FormData<ValueType>,
    onChange?: (value: string, systemId: string, validators: ValidatorsDictionary, resetExternalErrorFieldSystemIds?: string[]) => void,
    icons?: Record<string, Domain.Shared.SvgIcon>,
};

/**
 * Represents a UI component that renders the form with the fields for creating or editing a element definition.
 */
export const ElementDefinitionForm: React.FC<Props> = (props) => {
    const [selectedIconId, setSelectedIconId] = useState<string>(props.elementDefinition?.icon || Domain.Shared.DefaultIconId);

    const nameFieldDefinition = Domain.FieldDefinitions.Shared.nameFieldDefinition;
    const descriptionFieldDefinition = Domain.FieldDefinitions.Shared.descriptionFieldDefinition;
    const iconFieldDefinition = Domain.FieldDefinitions.Studio.ElementDefinition.iconFieldDefinition;
    const iconColorFieldDefinition = Domain.FieldDefinitions.Studio.ElementDefinition.iconColorFieldDefinition;

    const validators = getValidators(nameFieldDefinition, descriptionFieldDefinition, props.elementDefinitionNames, props.elementDefinition);
    const availableIcons = useMemo(() => {
        const icons = Object.values(props.icons);
        if (props.elementDefinition?.icon) {
            const idx = icons.findIndex(i => i.id === props.elementDefinition.icon);
            return [icons[idx], ...icons.slice(0, idx), ...icons.slice(idx + 1)];
        }
        return icons;
    }, [props.icons, props.elementDefinition?.icon]);

    const iconButtonClickHandler = (iconId: string) => {
        setSelectedIconId(iconId);
        props.onChange(iconId, iconFieldDefinition.id, validators)
    };

    return (<MuiGrid container
        spacing={{ xs: 2 }}
        columns={{ xs: 1 }}
        justifyContent="flex-start"
        alignItems="flex-end">
        <MuiGrid key={nameFieldDefinition.id} item xs={1}>
            <AutoFocus>
                <TextElement
                    id={nameFieldDefinition.id}
                    label={nameFieldDefinition.label ? nameFieldDefinition.label : nameFieldDefinition.name}
                    editorSettings={ValidationUtils.getEditorSettings(props.mode !== FormMode.View, false, validators, props.form, (val: string) => {
                        props.onChange(val, nameFieldDefinition.id, validators, [nameFieldDefinition.id])
                    }, nameFieldDefinition.id)}
                    value={props.form.values[nameFieldDefinition.id] as string} />
            </AutoFocus>
        </MuiGrid>
        <MuiGrid key={descriptionFieldDefinition.id} item xs={1}>
            <TextElement
                id={descriptionFieldDefinition.id}
                label={descriptionFieldDefinition.label ? descriptionFieldDefinition.label : descriptionFieldDefinition.name}
                editorSettings={ValidationUtils.getEditorSettings(props.mode !== FormMode.View, false, validators, props.form, (val: string) => { props.onChange(val, descriptionFieldDefinition.id, validators) }, descriptionFieldDefinition.id)}
                value={props.form.values[descriptionFieldDefinition.id] as string} />
        </MuiGrid>
        {props.mode !== FormMode.View && (
            <MuiGrid key={iconColorFieldDefinition.id} item xs={1}>
                <ColorPickerElement
                    id={iconColorFieldDefinition.id}
                    label={iconColorFieldDefinition.label ? iconColorFieldDefinition.label : iconColorFieldDefinition.name}
                    editorSettings={ValidationUtils.getEditorSettings(true, false, validators, props.form, (val: string) => { props.onChange(val, iconColorFieldDefinition.id, validators) }, iconColorFieldDefinition.id)}
                    ariaLabel='Icoon kleur'
                    title={iconColorFieldDefinition.name}
                    size='small'
                    value={props.form.values[iconColorFieldDefinition.id] as string}
                />
            </MuiGrid>
        )}
        <MuiGrid key={iconFieldDefinition.id} item xs={1}>
            {props.mode === FormMode.View ? (
                <>
                    <Styled.LabelContainer>
                        <Label id='icons-label' text='Icoon' />
                    </Styled.LabelContainer>
                    <SVGIcon value={props.icons[selectedIconId]?.svg} size={IconSize.large} color={props.elementDefinition?.color} />
                </>
            ) : (
                <IconSelectElement
                    id='icon-field'
                    label='Selecteer icoon'
                    direction='horizontal'
                    items={availableIcons}
                    value={selectedIconId}
                    height='300px'
                    baseWidth={IconSize.large}
                    baseHeight={IconSize.large}
                    keyExpr='id'
                    searchEnabled={true}
                    searchExpr='keywords'
                    itemRender={(item) => {
                        const hexColor = props.form.values[iconColorFieldDefinition.id] || props.elementDefinition?.color || palette.grey1;
                        return <Styled.CenteredDiv>
                            <SVGIcon value={item.svg} size={IconSize.large} color={hexColor} />
                        </Styled.CenteredDiv>;
                    }}
                    editorSettings={{
                        disabled: false,
                        validationErrors: [],
                        restrictions: { required: false },
                        onChange: (item) => {
                            iconButtonClickHandler(item?.id);
                        }
                    }}
                />)}
        </MuiGrid>
    </MuiGrid>);
};

const getValidators = (
    nameFieldDefinition: Domain.Shared.FieldDefinition,
    descriptionFieldDefinition: Domain.Shared.FieldDefinition,
    elementDefinitionNames: string[],
    elementDefinition: Domain.Shared.ElementDefinition
): ValidatorsDictionary => {
    return {
        [nameFieldDefinition.id]: new TextValidator({
            required: true,
            stringMaxLength: nameFieldDefinition.stringMaxLength,
            stringType: nameFieldDefinition.stringType
        },
            (value: string): ValidationErrorData[] => {
                if (elementDefinition && elementDefinition.name && elementDefinitionNames.map(name => name.toLocaleLowerCase()).includes(value.toLocaleLowerCase()) &&
                    value.toLocaleLowerCase().trim() !== elementDefinition.name.toLocaleLowerCase()) {
                    return [{ error: 'Naam moet uniek zijn' }];
                }
                return [];
            }
        ),
        [descriptionFieldDefinition.id]: new TextValidator({
            required: descriptionFieldDefinition.required,
            stringMaxLength: descriptionFieldDefinition.stringMaxLength,
            stringType: descriptionFieldDefinition.stringType
        }),
    }
};
