import React from 'react';
import styled from 'styled-components';
import { Field } from '../field';
import { InputRadio } from '../primitives';
import { cssFontNormal, px } from '../style';

interface IPresentationProps {
    readonly alignment?: string;
    readonly radioPosition?: string;
}

/* tslint:disable:no-magic-numbers no-shadowed-variable */
const ListWrap = styled.ul`
    list-style: none;
    display:block;
    width: auto;
    /* max-width: ${(p: IPresentationProps) => (p.alignment === 'vertical' && '350px') || '1050px'}; */
    padding:0;
    margin: ${px(8)} 0 ${px(12)} 0;
`;
const Li = styled.li`
    ${cssFontNormal(18, 28, 30)}
    padding:${px(4)} ${px(36)} ${px(4)} ${px(6)};
    white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
    text-align:left;
    display: inline-block;
    width: ${(p: IPresentationProps) => (p.alignment === 'vertical') ? '100%' : 'auto'};
    /* max-width: ${(p: IPresentationProps) => (p.alignment === 'vertical') ? '350px' : '1050px'}; */
`;

export interface IRadioGroupFieldItem {
    readonly disabled?: boolean;
    readonly label: string;
    readonly value: any;
    readonly content?: any;
}

export const RadioGroupField = ({ id, label, feedback, error, success, value, items, alignment, onChange, radioPosition }: {
    id: string;
    label?: string | null;
    feedback?: string | null;
    error?: string | null;
    success?: string | null;
    value: string;
    items: IRadioGroupFieldItem[];
    alignment?: 'auto' | 'horizontal' | 'vertical';
    radioPosition?: 'top' | 'middle';

    onChange: (value: any) => void;
}) => (
    <Field
        id={id}
        label={label}
        feedback={feedback}
        error={error}
        success={success}
        value={value}
        onChange={onChange}>
        {({ onFocus, onBlur, onChange, value, success, error, focused }) => (
            <ListWrap alignment={alignment} radioPosition={radioPosition}>
                {
                    items.map(({ value: valueRadio, disabled: disabledRadio, label: labelRadio, content: contentRadio }, index) => (
                        <Li key={index} alignment={alignment}>
                            <InputRadio
                                selected={value === valueRadio}
                                value={value}
                                disabled={disabledRadio}
                                id={id}
                                label={labelRadio}
                                content={contentRadio}
                                onClick={() => onChange(valueRadio)}
                                radioPosition={radioPosition} />
                        </Li>
                    ))
                }
            </ListWrap>
        )}
    </Field>
);
