import styled from 'styled-components';

const ContainerItemWrapper = styled.div<{ isFirst: boolean }>`
    margin: ${(p) => (p.isFirst ? `0` : `0`)};
`;

const ContainerWrapper = styled.div<{
    readonly preview: boolean;
    readonly showWhitespaceTop: boolean;
    readonly showWhitespaceRight: boolean;
    readonly showWhitespaceBottom: boolean;
    readonly showWhitespaceLeft: boolean;
    readonly showBackground: boolean;
    readonly showShadow: boolean;
}>`
    display: inline-block; 
    min-width: 0;
    box-sizing: border-box;
    background-color: ${(p) => (p.showBackground ? 'white' : 'none')};
    box-shadow: ${(p) => (p.showShadow ? '0px 3px 6px rgba(0,0,0,0.16)' : 'none')};
    margin-top: ${(p) => (p.showWhitespaceTop ? (p.preview ? '16px' : `${12 + 16}px`) : (p.preview ? '0' : `${8 + 16}px`))};
    margin-bottom: ${(p) => (p.showWhitespaceBottom ? (p.preview ? '8px' : `12px`) : (p.preview ? '0' : `8px`))};
    margin-right: ${(p) => (p.showWhitespaceRight ? (p.preview ? '24px' : `24px`) : (p.preview ? '0' : `8px`))};
    margin-left: ${(p) => (p.showWhitespaceLeft ? (p.preview ? '24px' : `24px`) : (p.preview ? '0' : `8px`))};

    width: calc(100% - 
        ${(p) => (p.showWhitespaceRight ? (p.preview ? '24px' : `24px`) : (p.preview ? '0' : `8px`))} - 
        ${(p) => (p.showWhitespaceLeft ? (p.preview ? '24px' : `24px`) : (p.preview ? '0' : `8px`))}
    );

    max-width: calc(100% - 
        ${(p) => (p.showWhitespaceRight ? (p.preview ? '24px' : `24px`) : (p.preview ? '0' : `8px`))} - 
        ${(p) => (p.showWhitespaceLeft ? (p.preview ? '24px' : `24px`) : (p.preview ? '0' : `8px`))}
    );
  
`;

const styledComponents = { ContainerItemWrapper, ContainerWrapper };
export default styledComponents;