/**
 * A utility class for DOM-related operations.
 */
export class DomUtils {

    /**
     * Triggers the download of a file using a Blob object.
     *
     * @param fileBlob - The Blob object representing the file to be downloaded.
     * @param filename - The name to be given to the downloaded file.
     */
    public static download = (fileBlob: Blob, filename: string) => {
        if (!window || !document) return;

        // Create a link element, use it to trigger download
        const downloadUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement('a');
        link.href = downloadUrl;

        // Set the filename
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link element
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    };

    /**
     * Smoothly scrolls the window to a specified element.
     *
     * @param selector - The CSS selector of the element to scroll to.
     * @param offset - An optional offset to adjust the final scroll position. Default is 0.
     */
    public static scrollToElement = (selector: string, offset = 0) => {
        if (!window || !document || !selector) return;

        const domElement = document.querySelector(selector);
        if (domElement) {
            const topPos = domElement.getBoundingClientRect().top + window.scrollY - offset;
            window.scrollTo({ top: topPos, behavior: 'smooth' });
        }
    };
}
