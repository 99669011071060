
import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Class that allows interaction with the user-specific backend APIs.
 */
export class Users {
    /**
     * Gets a list with all the users that are enabled to login in the application.
     * @param query Defines the query that can be used for filtering the data on the server side.
     */
    public static get = async (): Promise<AxiosResponse<Domain.Shared.User[]>> => {

        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Shared.User[]>(`/api/user`, config);
    }

    /**
    *  Updates group membership of the user.
    */
    public static addUserToGroups = async (userId: string, groupIds: string[]): Promise<AxiosResponse<void>> => {

        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/user/${userId}/groups`, { "groupIds": groupIds }, config);
    }
}

export default Users;