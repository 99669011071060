import React, { useEffect, useRef } from 'react';
import { IAccordionOptions } from 'devextreme-react/accordion';
import * as Domain from '@liasincontrol/domain';
import { IconSize } from '@liasincontrol/ui-basics';
import { StringUtils } from '@liasincontrol/core-service';
import { groupCellRender } from '../../helpers/renderModes';
import { ILsColumnProps } from '../..';
import * as TypeFormatter from '../helper/formatters';
import * as Styled from './index.styled';

export type LsAccordionProps = IAccordionOptions & {
    readonly icons?: Record<string, Domain.Shared.SvgIcon>,
    readonly columns?: ILsColumnProps[],
    readonly headerField?: string,
    readonly iconSize?: IconSize,
    readonly onContentReady?: (e: any) => void;
};

export const LsAccordionDs = (props: LsAccordionProps) => {
    const { columns, headerField } = props;
    const headerColumn = columns?.find(c => c.dataField === headerField);
    const detailColumns = columns?.filter(column => column.visible);
    const accordionRef = useRef(null);

    useEffect(() => {
        accordionRef.current?.instance()?.option('selectedItems', []);
        accordionRef.current?.instance()?.option('selectedIndex', 0);
    }, [props.dataSource]);

    return <Styled.BorderedAccordion
        ref={accordionRef}
        dataSource={props.dataSource}
        itemTitleRender={(data) => itemTitleRender(data, headerColumn, props.icons, props.iconSize)}
        itemRender={(data) => itemRender(data, detailColumns, props.icons, props.iconSize)}
        collapsible={props.collapsible}
        multiple={props.multiple}
        disabled={props.disabled}
        animationDuration={300}
        deferRendering={false}
        noDataText='Geen gegevens beschikbaar'
        repaintChangesOnly={false}
        onContentReady={props.onContentReady}
    />;
};

const itemTitleRender = (data, headerColumn, icons, iconSize) => {
    if (!data || (isCombinedField(headerColumn) ? !data?.[`${headerColumn?.dataField}Name`] : !data?.[headerColumn?.dataField]))
        return <>Titel veld</>;
    return <Styled.AccordionField withBorder={false}>
        {headerColumn?.groupRenderMode
            ? groupCellRender(data, headerColumn, icons, iconSize)
            : cellRender(data, headerColumn)}
    </Styled.AccordionField>;
};

const itemRender = (data, columns, icons, iconSize) => {
    if (!columns || columns.length === 0 || !data) return <></>;
    const panelNodes = columns?.map((column) =>
    (!!data?.[column?.dataField] || (isCombinedField(column) && !!data?.[`${column?.dataField}Name`])
        ? <Styled.AccordionField>
            {!!column.showCaption && <Styled.FieldLabel withMargin={true}>{column.caption}</Styled.FieldLabel>}
            {column?.groupRenderMode
                ? groupCellRender(data, column, icons, iconSize)
                : cellRender(data, column)}
        </Styled.AccordionField>
        : null
    ))?.filter(x => !!x);

    return (
        <>
            {panelNodes}
        </>
    );
};

const cellRender = (data, column) => {
    const text = data?.[column?.dataField];
    if (!text) return;
    switch (column.dataType) {
        case "boolean":
            return <>{text ? 'Ja' : 'Nee'}</>;
        case "number":
            const computedNumber = column.divide && column.divide > 1 ? Math.round(text / column.divide) : text;
            return <>{TypeFormatter.decimalFormatter(computedNumber, column.format)}</>;
        case "datetime":
            return <>{TypeFormatter.dateTimeFormatter(text, 'DD-MM-YYYY')}</>;
        case "html":
        case "string":
            if (column.format === "hyperlink") {
                const modifiedHtml = text.replace(/<a([^>]+)>/, '<a$1 target="_blank">');
                return (<span dangerouslySetInnerHTML={StringUtils.toRawMarkup(modifiedHtml)} style={{ overflowY: 'hidden', boxSizing: 'border-box' }}></span>);
            }
            return (<span dangerouslySetInnerHTML={StringUtils.toRawMarkup(text)} style={{ overflowY: 'hidden', boxSizing: 'border-box' }}></span>);
        default:
            return <>{text}</>;
    }
};


const isCombinedField = (column) => {
    return (column?.propertyGroupKind === 'OptionField' || column?.propertyGroupKind === 'ElementDefinition');
};