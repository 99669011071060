import styled from 'styled-components';
import CheckBox from "devextreme-react/check-box";
import { palette } from '@liasincontrol/ui-basics';

const StyledCheckBoxContainer = styled.div`
   display: flex;
   align-items: center;
   width: 100%;
   max-width: 100%;
`;

const StyledCheckBox = styled(CheckBox)`
    max-width: 100%;
    margin: 0;
    color: ${palette.grey2};
    text-overflow: ellipsis;
    border-radius: 4px;
    background: 0 none;
    outline: 0;
    transition: border-color 0.3s ease;
    background-color: ${palette.white} !important;
    white-space: nowrap;
    letter-spacing: 0.06em;
    .dx-checkbox-text {
        font-weight: 500;
    }
`;

const styledComponents = { StyledCheckBox, StyledCheckBoxContainer };

export default styledComponents;