import React, { useCallback } from 'react';
import { withField } from '../field';
import { InputSelect, IDataItemProps, InputSelectChangeHandler } from '../primitives';

export const MultiSelectField = withField<{
    selected: IDataItemProps<any>[];
    items: IDataItemProps<any>[];
    clearable?: boolean;
    searchable?: boolean;
    emptyText?: string;
    maxHeight?: number;
    required?: boolean;
    withoutFeedback?: boolean;
}>(
    ({
        id,
        onChange,
        value: { items, selected, emptyText, maxHeight, clearable = false, searchable = false, required = false },
        ...rest
    }) => (
        <InputSelect
            id={`input-${id}`}
            options={items}
            clearable={clearable}
            searchable={searchable}
            required={required}
            maxHeight={maxHeight}
            noResultsText={emptyText}
            selectedOption={selected}
            onChange={useCallback<InputSelectChangeHandler>(
                (selected: any) => onChange({ selected, items, emptyText, searchable, clearable, maxHeight, required }),
                [items, emptyText, searchable, clearable, maxHeight, required, onChange])}
            isMultiSelector
            {...rest}
        />
    )
);
