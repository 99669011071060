import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { SelectElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { Bar, IDataItemProps } from '@liasincontrol/ui-basics';
import { momentInputCustomOptions } from '../../_shared/OptionItem/MeasureMomentOptionItem';
import { TextBox } from 'devextreme-react';
import { isMomentOpen, useUserSettings } from '@liasincontrol/core-service';

type HierarchyListFilterProps = {
    hierarchyDefinitions: Domain.Shared.HierarchyDefinition[],
    measureMoments: Domain.Shared.MeasureMoment[],
    measureMomentStatuses: IDataItemProps<string>[],
    onFilterChanged: (filterOpts: FilterOptions) => void,
}
export type FilterOptions = {
    definitionId?: string,
    momentId?: string,
    momentYear?: number,
    momentStateId?: string,
}

type HierarchyType = IDataItemProps<string> & { status: string };

export const HierarchyListFilter: React.FC<HierarchyListFilterProps> = (props) => {
    const { baseYear, setBaseYear, measureMomentId, setMeasureMomentId, } = useUserSettings();
    const [selectedHierarchyDefinition, setSelectedHierarchyDefinition] = useState<HierarchyType>();
    const [selectedMomentStatus, setSelectedMomentStatus] = useState<IDataItemProps<string>>();

    const hierarchyDefinitionOptions = useMemo(() => {
        if (!props.hierarchyDefinitions) return [];
        return props.hierarchyDefinitions.map(def => ({
            value: def.hierarchyDefinitionId, label: def.name, status: def.deleted ? 'Uitgeschakeld' : 'Ingeschakeld',
        })) as HierarchyType[];
    }, [props.hierarchyDefinitions]);

    const measureMomentStatuses = useMemo(() => {
        const mms = props.measureMoments.map((moment) => ({
            value: moment.id,
            label: moment.name,
            closed: !isMomentOpen(moment.status)
        }));

        return mms;
    }, [props.measureMomentStatuses]);

    const selectedMoment = useMemo(() => measureMomentStatuses.find(mms => mms.value === measureMomentId)
        , [measureMomentStatuses, measureMomentId]);

    const baseYearOptions = _.uniqBy(props.measureMoments, (mm) => mm.baseYear).map(mm => mm.baseYear);

    useEffect(() => {
        props.onFilterChanged({
            momentYear: baseYear === 0 ? 0 : (baseYear || null),
            momentId: measureMomentId || null,
            momentStateId: selectedMomentStatus?.value || null,
            definitionId: selectedHierarchyDefinition?.value || null,
        });
    }, [measureMomentId, selectedHierarchyDefinition, baseYear, selectedMomentStatus]);

    return (
        <Bar look='toolbar'>
            <Bar start>
                <SelectElement<HierarchyType>
                    id='input-hierarchy-definition'
                    label='Hiërarchiedefinitie'
                    displayExpr='label'
                    grouped={true}
                    groupSelector='status'
                    groupOrder='asc'
                    optionItems={hierarchyDefinitionOptions}
                    value={hierarchyDefinitionOptions.find(hd => hd.value === selectedHierarchyDefinition?.value)}
                    customSingleValue={(item) => <TextBox value={item?.label || selectedHierarchyDefinition?.label} placeholder='Kies...' />}
                    clearable={true}
                    searchable={true}
                    editorSettings={{
                        onChange: setSelectedHierarchyDefinition
                    }}
                />
                <SelectElement<Domain.Shared.MomentItem>
                    id='input-moment'
                    label='Moment'
                    displayExpr='label'
                    optionItems={measureMomentStatuses}
                    value={measureMomentStatuses.find(mms => mms.value === selectedMoment?.value)}
                    clearable={true}
                    searchable={true}
                    customOptions={(customProps) => momentInputCustomOptions({ ...customProps })}
                    customSingleValue={(customProps) => momentInputCustomOptions({ ...customProps, ...selectedMoment }, { isFieldTemplate: true, placeholder: 'Kies...' })}
                    editorSettings={{
                        onChange: (item) => {
                            setMeasureMomentId(item?.value);
                        }
                    }}
                />
                <SelectElement<number>
                    id='input-baseyear'
                    label='Basisjaar'
                    optionItems={baseYearOptions}
                    value={baseYear}
                    clearable={true}
                    searchable={true}
                    editorSettings={{
                        disabled: false,
                        restrictions: { required: false },
                        validationErrors: [],
                        onChange: (item) => {
                            setBaseYear(item);
                        }
                    }}
                />
                <SelectElement<IDataItemProps<string>>
                    id='input-moment-state'
                    label='Open/gesloten moment'
                    displayExpr='label'
                    optionItems={props.measureMomentStatuses}
                    value={props.measureMomentStatuses.find(mms => mms.value === selectedMomentStatus?.value)}
                    clearable={true}
                    searchable={true}
                    editorSettings={{
                        disabled: false,
                        restrictions: { required: false },
                        validationErrors: [],
                        onChange: setSelectedMomentStatus
                    }}
                />
            </Bar>
        </Bar>
    );
};