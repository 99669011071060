import React from 'react';
import { ColorPicker } from '@liasincontrol/ui-basics';
import { ColorPickerProps } from './index.props';

/**
 * Represents a UI component that renders a color picker editor.
 */
export const ColorPickerEditor: React.FC<ColorPickerProps> = (props) => {
    const value = props.value ? props.value : props.defaultColor;
    return (
        <ColorPicker
            id={props.id}
            ariaLabel={props.ariaLabel}
            title={props.title}
            helpText={props.helpText}
            size={props.size}
            disabled={props.editorSettings.disabled}
            color={value}
            colors={props.colors}
            onColorChange={props.editorSettings.onChange}
        />
    );
};