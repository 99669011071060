//import ReactDOM from "react-dom/client";

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { locale } from 'devextreme/localization';
import App from './app/App';
import { store } from '@liasincontrol/redux-service';
import { UserSettingsProvider } from "@liasincontrol/core-service";
import * as serviceWorker from './serviceWorker';
import { UserBack } from './app/plugins/userback';
import theme from "./app/config/material-theme";

declare global {
  interface Window { Userback: UserBack }
}

locale('nl');

window.Userback = window.Userback || {};

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <UserSettingsProvider>
        <App />
      </UserSettingsProvider>
    </Provider>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
