import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of performance hierarchies.
 */
export class HierarchyDataAccessor {

    /**
     * Gets the collection of performance hierarchy items.
     * 
     * @param measureMomentId Defines the measure moment unique identifier.
     * @param includeSettings True if the performance tree settings should also be retrieved along with the tree data.
     * @param includeWorkflowState True if the performance tree items should also contain workflow state information.
     */
    public static get = async (measureMomentId: string, includeSettings = true, includeWorkflowState = true): Promise<AxiosResponse<{ hierarchy: Domain.Performance.HierarchyItem[], settings?: Domain.Performance.HierarchySettings }>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const extraParams = includeSettings || includeWorkflowState ? `?includeSettings=${includeSettings}&includeWorkflowStates=${includeWorkflowState}` : '';
        return HttpClient.get<{ hierarchy: Domain.Performance.HierarchyItem[], settings?: Domain.Performance.HierarchySettings }>(`/api/performance/${measureMomentId}${extraParams}`, config);
    };

    /**
     * Gets the collection of performance HierarchyTasks where logged in user is assigned to.
     * 
     * @param measureMomentId Defines the measure moment unique identifier.
     */
    public static getAssignedTasks = async (measureMomentId: string): Promise<AxiosResponse<{ tasklist: Domain.Performance.HierarchyTask[], settings: Domain.Performance.HierarchySettings }>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<{ tasklist: Domain.Performance.HierarchyTask[], settings: Domain.Performance.HierarchySettings }>(`/api/performance/${measureMomentId}/tasks`, config);
    };

    /** 
     * Updates the name of a performance element definition.
     * 
     * @param elementDefinitionId Defines the unique identifier of the element definition.
     * @param name Defines the new name of the performance performance element definition.
     */
    public static updatePerformanceElementDefinitionName = async (elementDefinitionId: string, name: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/performance/elementDefinition/${elementDefinitionId}/name`, { name: name }, config);
    };

    /**
     * Imports a performance hierarchy from one moment to another.
     * 
     * @param fromMeasureMomentId Defines the source measure moment Id
     * @param toMeasureMomentId  Defines the destination measure moment Id
     */
    public static import = async (fromMeasureMomentId: string, toMeasureMomentId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/performance/hierarchy/copy`, {
            fromMeasureMomentId: fromMeasureMomentId,
            toMeasureMomentId: toMeasureMomentId
        }, config);
    };
}

export default HierarchyDataAccessor;