import styled, { css } from 'styled-components';
import { cssFontBold, cssFontExtraBold, cssFontNormal, cssFontSemiBold } from '../../style';
import { cssBreakpoint } from '../../responsive/css';
import { palette } from '../../styleguide';

// tslint:disable:no-magic-numbers

export const cssHeading1 = css`
    ${cssFontBold(24, 32, 60)};
    color: ${palette.grey2};
    margin: 0;
    padding: 0;

    ${cssBreakpoint('xs', 's', 'm')`
        ${cssFontBold(20, 28, 60)};
    `};
`;
export const Heading1 = styled.h1`
    ${cssHeading1}
`;

export const cssHeading2 = css`
    ${cssFontSemiBold(20, 28, 60)};
    color: ${palette.grey2};
    margin: 0;
    padding: 0;

    ${cssBreakpoint('xs', 's', 'm')`
        ${cssFontSemiBold(16, 24, 60)};
    `};
`;
export const Heading2 = styled.h2`
    ${cssHeading2}
`;

export const cssHeading3 = css`
    ${cssFontSemiBold(18, 26, 60)};
    color: ${palette.grey2};
    margin: 0;
    padding: 0;

    ${cssBreakpoint('xs', 's', 'm')`
        ${cssFontSemiBold(14, 22, 60)};
    `};
`;
export const Heading3 = styled.h3`
    ${cssHeading3}
`;

export const cssHeading4 = css`
    ${cssFontSemiBold(16, 24, 60)};
    color: ${palette.grey3};
    margin: 0;
    padding: 0;

    ${cssBreakpoint('xs', 's', 'm')`
        ${cssFontSemiBold(12, 20, 60)};
    `};
`;
export const Heading4 = styled.h3`
    ${cssHeading4}
`;

export const cssElementLabel = css`
    ${cssFontBold(14, 18, 30)};
    color: ${palette.grey2};
    margin: 0;
    padding: 0;
    display: inline-block;

    ${cssBreakpoint('xs', 's', 'm')`
        ${cssFontBold(12, 16, 60)};
    `};
`;
export const ElementLabel = styled.label`
    ${cssElementLabel}
    &.task-label {
        min-width: 70px;
    }
    &.nowrap {
        white-space: nowrap;
    }
`;

export const cssGridLabel = css`
    ${cssFontBold(14, 18, 30)};
    color: ${palette.grey2};
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    display: block;

    ${cssBreakpoint('xs', 's', 'm')`
        ${cssFontBold(12, 16, 60)};
    `};
`;
export const GridLabel = styled.span`
    ${cssGridLabel}
`;

export const cssBasicText = css`
    ${cssFontNormal(14, 22, 30)};
    color: ${palette.grey2};
    margin: 0;
    padding: 0;

    ${cssBreakpoint('xs', 's', 'm')`
        ${cssFontNormal(13, 21, 60)};
    `};
`;
export const BasicText = styled.p`
    ${cssBasicText}
`;

export const cssSmallText = css`
    ${cssFontExtraBold(10, 14, 100)};
    color: ${palette.grey2};
    margin: 0;
    padding: 0;
    display: block;
    text-transform: uppercase;
`;
export const SmallText = styled.span`
    ${cssSmallText}
`;

export const cssNormalText = css`
    ${cssFontExtraBold(14, 18, 100)};
    color: ${palette.grey2};
    margin: 0;
    padding: 0;
    display: block;
    text-transform: uppercase;
`;
export const NormalText = styled.span`
    ${cssNormalText}
`;

export const cssLargeText = css`
    ${cssFontExtraBold(16, 20, 100)};
    color: ${palette.grey2};
    margin: 0;
    padding: 0;
    display: block;
    text-transform: uppercase;
`;
export const LargeText = styled.span`
    ${cssLargeText}
`;
