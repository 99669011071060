import React, { useEffect, useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { Button, ElementLabel } from '@liasincontrol/ui-basics';
import { AttachmentElement, ImageFocusPointElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { AttachmentsHelper } from '@liasincontrol/core-service';
import DeleteIcon from '@mui/icons-material/Delete';

type PageImageSettingsProps = {
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly disabled: boolean,
    readonly image: Domain.Shared.Attachment,
    readonly fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>,
    readonly onSaveImageSettings: (settings: ImageAttachmentState) => Promise<void>,
    readonly onLoadAttachment: (id: string) => Promise<Blob>,
    readonly onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    readonly onRemoveAttachment: (id: string) => void,
    readonly isEditing: () => void,
}

export type ImageAttachmentState = {
    image: string,
    focalPoint: string,
    attachments: Domain.Shared.Attachment[]
}

export const PageImageSettings: React.FC<PageImageSettingsProps> = (props) => {
    const [imageAttachment, setImageAttachment] = useState<ImageAttachmentState>(initImageAttachment());
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const coverImageDefinition = props.fieldDefinitions?.[SystemFieldDefinitions.Pub.CoverImage];

    useEffect(() => {
        setImageAttachment({
            focalPoint: props.sitemapNode.focalPoint,
            image: props.sitemapNode.image,
            attachments: props.image ? [props.image] : null,
        })
    }, [props.sitemapNode, props.image]);

    const onSave = () => {
        props.onSaveImageSettings(imageAttachment).then(() => setIsEditing(prev => !prev));
    };

    const onDeleteImage = () => {
        // props.onRemoveAttachment(imageAttachment.image);
        setImageAttachment(prev => ({ ...prev, attachments: null, image: null }));
    };

    return (
        <>
            <MuiGrid item xs={12} md={isEditing && imageAttachment.image ? 7 : 8}>
                <ElementLabel>Pagina banner</ElementLabel>
            </MuiGrid>
            {isEditing && imageAttachment.image &&
                <MuiGrid item xs={1} md={1}>
                    <Button
                        btnbase="iconbuttons"
                        btntype="small_background"
                        icon={<DeleteIcon />}
                        aria-label="Verwijder"
                        onClick={onDeleteImage}
                    >
                        Verwijder
                    </Button>
                </MuiGrid>
            }
            <MuiGrid item xs={12} md={4}>
                <Button
                    id="btn-page-metadata"
                    btnbase="ghostbuttons"
                    btntype="small_noicon"
                    onClick={() => {
                        if (isEditing)
                            onSave();
                        else {
                            setIsEditing(prev => !prev);
                            props.isEditing?.();
                        }
                    }}
                    aria-label="Selecteren"
                    disabled={props.disabled}
                >
                    {isEditing ? 'Opslaan' : 'Bewerken'}
                </Button>
            </MuiGrid>

            <MuiGrid item xs={12} md={12}>
                {isEditing && imageAttachment.image
                    ? <ImageFocusPointElement
                        id={props.sitemapNode.image}
                        label='Focuspunt'
                        attachmentId={imageAttachment.image}
                        value={imageAttachment.focalPoint}
                        onLoadAttachment={(id) =>
                            props.onLoadAttachment(id).then(blob => {
                                setImageAttachment(prev => ({ ...prev, image: id, focalPoint: props.sitemapNode.focalPoint }));
                                return blob;
                            })
                        }
                        editorSettings={{
                            disabled: props.disabled,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (focusPoint) => setImageAttachment(prev => ({ ...prev, focalPoint: focusPoint })),
                        }}
                    />
                    : <AttachmentElement
                        key={`${props.sitemapNode.elementId}-image`}
                        id={imageAttachment.image}
                        editorSettings={{
                            disabled: props.disabled || !isEditing,
                            restrictions: { required: false, maxFileSize: coverImageDefinition?.attachmentMaxFileSize ?? attachmentMaxFileSize, allowedFileTypes: attachmentAllowedFileTypes },
                            validationErrors: [],
                            onChange: () => { /* do nothing */ },
                        }}
                        allowMultiple={false}
                        value={imageAttachment.image}
                        onLoadAttachment={props.onLoadAttachment} // won't fire
                        onUploadAttachment={(file, abort) =>
                            props.onUploadAttachment(file, abort)
                                .then(blobId => {
                                    // model is required to be an array of attachments
                                    const attachment = [AttachmentsHelper.mapFileToAttachment(file, blobId)];
                                    setImageAttachment(prev => ({ ...prev, attachments: attachment, image: blobId }));
                                    return blobId;
                                })
                        }
                    />
                }
            </MuiGrid>
        </ >
    );
};

const initImageAttachment = (): ImageAttachmentState => {
    return ({
        attachments: null,
        focalPoint: null,
        image: null,
    });
};

const attachmentMaxFileSize = 10000000;

const attachmentAllowedFileTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/gif',
];
