import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { Grid as MuiGrid } from '@mui/material';
import { ActionSource, ModulesActionCreator, RootState, State, UserGroupsActionCreator, UsersActionCreator } from '@liasincontrol/redux-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Shared as DataAccess, Publisher as PubDataAccess } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Heading1, Button } from '@liasincontrol/ui-basics';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
import ArrowBack from '@mui/icons-material/ArrowBack';

import { UserInGroup } from './UserInGroup';
import { RolesInGroup } from './RolesInGroup';

type Props = {
    userIdentity: UserIdentity,
    lastRefresh?: number;
    onError: (error: Domain.Shared.ErrorInfo) => void;
    onRefresh?: (tick: number) => void;
    setSelectedTab?: (tabIndex: number) => void;
};
/**
 * Represents a UI component that renders the user group details page.
 */
const Index: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const props = useOutletContext<Props>();
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
    const modules = useSelector((state: State) => state.modules[ActionSource.Publication]);

    const [lastRefreshRoles, setLastRefreshRoles] = useState<number>(Date.now());
    const [lastRefreshUsers, setLastRefreshUsers] = useState<number>(Date.now());
    const [roles, setRoles] = useState<Domain.Shared.UserRole[]>([]);
    const [users, setUsers] = useState<Domain.Shared.User[]>([]);
    const [userGroup, setUserGroup] = useState<Domain.Shared.UserGroup>(undefined);
    const [userGroupRoles, setUserGroupRoles] = useState<Domain.Shared.UserGroupRoles>(undefined);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);

    const canUpdate = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Update);

    useEffect(() => {
        props.setSelectedTab(2);
        PubDataAccess.RolesDataAccess.getAll().then((response) => setRoles(response.data));
    }, []);

    useEffect(() => {
        DataAccess.Users.get().then(res => setUsers(res.data));
        DataAccess.UserGroups.get().then((response) => {
            const group = response.data?.find(group => group.id === id);
            if (!group) return;
            setUserGroup(group);
        });
    }, [id, lastRefreshUsers]);

    useEffect(() => {
        DataAccess.UserGroups.getGroupRoles(id).then((response) => {
            setUserGroupRoles(response.data);
        });
    }, [id, lastRefreshRoles]);

    if (!modules) {
        dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} })).unwrap();
        return null;
    }

    /**
     * Represents an event handler that triggers when saving the user group changes.
     */
    const onUsersAdded = (userIds: string[]) => {
        const changedData: Domain.Dto.Shared.UpdateUserGroup = {
            name: userGroup.name,
            userIds: userIds,
        };

        DataAccess.UserGroups.update(id, changedData)
            .then(() => {
                dispatch(UserGroupsActionCreator.set()).unwrap();
                dispatch(UsersActionCreator.set()).unwrap();
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err));
            }).finally(() => setLastRefreshUsers(Date.now()));
    };

    /**
    * Represents an event handler that triggers when saving the user group roles changes.
    */
    const onRolesAdded = (roleIds: string[]) => {
        DataAccess.UserGroups.setGroupRoles(id, roleIds)
            .catch((err) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err)))
            .finally(() => setLastRefreshRoles(Date.now()));
    };

    return (<>
        <Heading1 className='mb-050'>
            <Button
                btnbase="iconbuttons"
                btntype="medium_transparentmain"
                icon={<ArrowBack />}
                onClick={() => navigate('/admin/usermanagement/usergroup')}
            />
            {userGroup ? userGroup.name : !error ? <LoadIndicator variant={IndicatorSize.default} /> : `Gebruikersgroep`}
        </Heading1>
        <MuiGrid container justifyContent="flex-start" alignItems="flex-start" spacing={2} columns={{ md: 2 }}>
            <MuiGrid item md={1}>
                <RolesInGroup
                    userGroupRoles={userGroupRoles}
                    roles={roles}
                    disabled={!canUpdate}
                    onRolesAdded={onRolesAdded}
                />
            </MuiGrid>
            <MuiGrid item md={1}>
                <UserInGroup
                    userGroup={userGroup}
                    users={users}
                    disabled={!canUpdate}
                    onUsersAdded={onUsersAdded}
                />
            </MuiGrid>
        </MuiGrid>
    </>);
};

export { Index as index };
