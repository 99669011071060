import React from 'react';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { Actions } from '@liasincontrol/userrights-service';
import { Administrator } from '../..';
import { Route } from 'react-router-dom';

const Contents = (
    <>
        <Route path="usermanagement/*" element={<PrivateRoute permissions={[Actions.CRUD_UsersAndGroups, Actions.CRUD_Roles]} component={Administrator.UserManagement.index} />}>
            <Route path="users/*" element={<PrivateRoute component={Administrator.UsersList.index} />} />
            <Route path="roles/*" element={<PrivateRoute component={Administrator.RoleList.index} />} />
            <Route path="usergroup/:id/information" element={<PrivateRoute component={Administrator.UserGroupItem.index} />} />
            <Route path="usergroup/*" element={<PrivateRoute component={Administrator.UsersGroupList.index} />} />
        </Route>

        <Route path="datastore/list" element={<PrivateRoute permissions={Actions.CRUD_DataStores} component={Administrator.DataStoresList.index} />} />

        <Route path="datastore/:id/information" element={<PrivateRoute permissions={Actions.CRUD_DataStores} component={Administrator.DataStoreItem.index} />} />

        <Route path="workflow/list" element={<PrivateRoute permissions={Actions.CRUD_Workflows} component={Administrator.WorkflowTemplateList.index} />} />
        <Route path="workflow/:id/information" element={<PrivateRoute permissions={Actions.CRUD_Workflows} component={Administrator.WorkflowTemplateItem.index} />} />

        <Route path="measuremoment/list" element={<PrivateRoute permissions={Actions.CRUD_MeasureMoments} component={Administrator.MeasureMomentList.index} />} />
    </>);

export { Contents };