import React, { useCallback } from 'react';
import { withField1, WithFieldBaseType } from '@liasincontrol/ui-basics';
import { DateBoxTypes } from 'devextreme-react/cjs/date-box';
import DateBox from "devextreme-react/date-box";

type DateProps = WithFieldBaseType<Date> & {
    minDate?: Date,
    maxDate?: Date,
    showClearButton?: boolean,
}

export const DateField = withField1<Date, DateProps>(
    (props) => {
        const onValueChanged = useCallback((e: DateBoxTypes.ValueChangedEvent) => {
            props.onChange?.(e.value);
        }, [props.onChange]);

        return <DateBox
            applyValueMode="instantly"
            value={props.value}
            displayFormat="dd-MM-yyyy"
            min={props.minDate}
            max={props.maxDate}
            disabled={props.disabled}
            useMaskBehavior={true}
            id={`dx-datebox-${props.id}`}
            inputAttr={{
                'aria-label': props.label,
                'id': `dx-input-datebox-${props.id}`
            }}
            onValueChanged={onValueChanged}
        />
    }
);
