import { ContentTypeUtils } from '@liasincontrol/core-service';
import { AxiosResponse, CancelToken } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import { Shared } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';

/**
 * Represents the attachments data accessor.
 */
export class AttachmentsHelper {
    /**
     * Gets the information of an attachment based on its unique identifier.
     * 
     * @param attachmentId Defines the attachment id.
     * @param cancelToken Defines the cancel token.
     * 
     * @returns a promise that contains a url to the attachment.
     */
    public static getAttachmentInfo = async (attachmentId: string, cancelToken?: CancelToken): Promise<AxiosResponse<{ urlWithSasToken: string }>> => {
        const config = await Shared.DataAccessHostUtils.getPubRequestConfig();
        config.cancelToken = cancelToken;

        return HttpClient.get<{ urlWithSasToken: string }>(`/api/storage/downloadlink/${attachmentId}`, config);
    };

    /**
     * Uploads an attachment.
     * 
     * @param file Defines the attachment file.
     * @param abortSignal Defines the abort signal.
     * @param setAttachmentCallback Method used to store the loaded attachment in persistent (Redux) storage.
     * 
     * @returns a promise that contains the blobId of the uploaded attachment.
     */
    public static uploadAttachment = async (
        file: File,
        abortSignal: AbortSignal,
        setAttachmentCallback: (attachmentId: string, attachment: File) => void
    ): Promise<string> => {
        const config = await Shared.DataAccessHostUtils.getPubRequestConfig();
        const blobUploadInfo = await HttpClient.get<{ urlWithSasToken: string, blobId: string }>('/api/storage/uploadlink', config);

        const headers = new Headers({
            'content-type': ContentTypeUtils.translate(file.type, file.name),
            'x-ms-blob-type': 'BlockBlob',
        });

        await fetch(blobUploadInfo.data.urlWithSasToken!, {
            method: 'PUT',
            headers,
            body: file,
            signal: abortSignal
        });

        setAttachmentCallback(blobUploadInfo.data.blobId, file);
        return blobUploadInfo.data.blobId;
    };

    /**
     * Helper that returns a cached attachment (if available) or downloads and caches the requested attachment.
     * 
     * @param attachmentId Defines the attachment id.
     * @param attachmentsStorage Defines a dictionary of existing attachments.
     * @param setAttachmentCallback Method used to store the loaded attachment in persistent (Redux) storage.
     * @param attachmentNames Optional dictionary of attachmentId to file name to be used on the created blob instance.
     */
    public static loadExistingAttachment = async (
        attachmentId: string,
        attachmentsStorage: { [attachmentId: string]: File },
        setAttachmentCallback: (attachmentId: string, attachment: File) => void,
        attachmentNames?: Record<string, string>
    ): Promise<Blob> => {
        if (!attachmentId) {
            return Promise.resolve(null);
        }
        const attachment = attachmentsStorage && attachmentsStorage[attachmentId];
        if (attachment) {
            return Promise.resolve(attachment);
        }

        return AttachmentsHelper.getAttachmentInfo(attachmentId)
            .then(async (response) => {
                const fileResponse = await fetch(response.data.urlWithSasToken, { method: 'GET' });
                const blob = await fileResponse.blob();
                const fileName = attachmentNames ? attachmentNames[attachmentId] : '';

                const file = new File([blob], fileName, { type: blob.type });
                setAttachmentCallback(attachmentId, file);
                return file;
            });
    };

    /**
     * Generates an attachment object that can be sent to an API call, based on an uploaded File object.
     * @param attachment File object describing the uploaded file.
     * @param attachmentId Id assigned to the uploaded file.
     * @param isFieldAttachment True if the attachment is related to a field.
     */
    public static mapFileToAttachment = (
        attachment: File,
        attachmentId: string,
        isFieldAttachment?: boolean,
        deleted?: boolean
    ): Domain.Shared.Attachment => {
        const fileName = attachment.name;
        const attachmentExtension = fileName.split('.').reverse()[0];
        return {
            id: attachmentId,
            blobId: attachmentId,
            name: fileName.replace(/\.[^/.]+$/, ""),
            fileExtension: attachmentExtension,
            isFieldAttachment: isFieldAttachment,
            deleted: deleted,
        };
    };

    /**
     * Translates a list of attachments to a dictionary that can be used in the loadExistingAttachment to set the proper attachment names.
     * @param attachments List of possible attachments.
     */
    public static getAttachmentNamesDictionary = (attachments: Domain.Shared.Attachment[]): Record<string, string> => {
        if (attachments) {
            return attachments.reduce(
                (collection, item) => ({ ...collection, [item.id]: item.name }),
                {});
        }
        return {};
    };
}