import { AxiosRequestConfig, ResponseType } from 'axios';
import { ConfigService, Config, AppSettingsService } from '@liasincontrol/config-service';
import * as Domain from '@liasincontrol/domain';

/**
 * Helper class for data accessors, providing common functionality for setting up the AxiosRequestConfig object.
 */
export class DataAccessHostUtils {

    /**
     * Gets a default request config object for the Pub endpoint.
     * 
     * @param {boolean} [extendedTimeout=false] - Indicates whether to extend the timeout duration.
     * @param {string} [responseType] - Optional response type for the Axios request.
     */
    public static getPubRequestConfig = async (extendedTimeout = false, responseType?: string, accept?: string): Promise<AxiosRequestConfig> => {
        const configObject = {
            baseURL: (await ConfigService.getConfigurations()).API.PUB_API_URL,
            timeout: AppSettingsService.getAppSettings().Api.Timeout * (extendedTimeout ? 2 : 1),
            headers: {} // Initialize headers
        } as AxiosRequestConfig;
        if (responseType) configObject.responseType = responseType as ResponseType;
        // Set Accept header if provided
        if (accept) configObject.headers['Accept'] = accept as string;
        return configObject;
    };

    /**
     * Gets a default request config object for the either endpoint.
     * @param endpoint Which of the existing endpoints to call.
     * @param config Configuration settings object.
     */
    public static getModuleRequestConfig = (endpoint: Domain.Shared.ApiEndpoint, config: Config): AxiosRequestConfig => {
        let host = '';
        switch (endpoint) {
            case Domain.Shared.ApiEndpoint.Pub:
                host = config.API.PUB_API_URL;
                break;
            default:
                throw new Error(`Invalid endpoint type ${endpoint}`);
        }

        return {
            baseURL: host,
        } as AxiosRequestConfig;
    }

    /**
     * Gets the default config object for the publications as array of column names.  
     */
    public static getDefaultPublicationsConfig = () => {
        const columns: (keyof Domain.Publisher.PublicationElement)[] = ['name'];
        return columns;
    }
}