import React from "react";
import { useState } from "react";
import { useBaseYears } from "../../shared/hooks";
import { Grid as MuiGrid } from '@mui/material';
import { SelectElement, TextElement } from '@liasincontrol/ui-elements';

export type FilterModel = {
    baseYear?: number;
    journalElementCombinationRK?: string;
    code?: string;
    measureMomentTag?: string;
}

type Props = {
    onFilter: (filter: FilterModel) => void;
}

export const ObligationsFilter: React.FC<Props> = (props) => {
    const [filter, setFilter] = useState<FilterModel>({});
    const baseYears = useBaseYears();

    const onFilterChanged = (key: keyof FilterModel, value: number | string) => {
        setFilter((prev) => {
            const newValue = { ...prev, [key]: value };
            props.onFilter(newValue);
            return newValue;
        });
    }

    return <MuiGrid container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 1, md: 8 }}
        justifyContent="flex-start"
        alignItems="flex-end">
        <MuiGrid item xs={1} md={1}>
            <SelectElement<number>
                id='filter-base-year'
                label='Basisjaar'
                optionItems={baseYears.items || []}
                value={filter.baseYear}
                clearable={true}
                searchable={false}
                editorSettings={{
                    disabled: false,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (item) => onFilterChanged('baseYear', item),
                }}
            />
        </MuiGrid>
        <MuiGrid item xs={1} md={2}>
            <TextElement id='filter-journal-combination'
                value={filter.journalElementCombinationRK}
                label='Combinatie'
                editorSettings={{
                    disabled: false,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (value) => onFilterChanged('journalElementCombinationRK', value),
                }}
            />
        </MuiGrid>
        <MuiGrid item xs={1} md={2}>
            <TextElement id='filter-code'
                value={filter.code}
                label='Code'
                editorSettings={{
                    disabled: false,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (value) => onFilterChanged('code', value),
                }}
            />
        </MuiGrid>
        <MuiGrid item xs={1} md={2}>
            <TextElement id='filter-measure-moment-tag'
                value={filter.measureMomentTag}
                label='Moment label'
                editorSettings={{
                    disabled: false,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (value) => onFilterChanged('measureMomentTag', value),
                }}
            />
        </MuiGrid>
    </MuiGrid>
};