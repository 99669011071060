import React, { useCallback } from 'react';
import { HelpTextElement, withField1, WithFieldBaseType } from '@liasincontrol/ui-basics';
import Styled from './index.styled';
import { ValueChangedEvent } from 'devextreme/ui/check_box_types';

export type CheckBoxFieldProps = WithFieldBaseType<boolean> & {
    altLabel?: string,
};

export const CheckboxField = withField1<boolean, CheckBoxFieldProps>(
    (props) => {
        const onValueChanged = useCallback((e: ValueChangedEvent) => {
            props.onChange?.(e.value);
        }, [props.onChange]);

        return <Styled.StyledCheckBoxContainer>
            <Styled.StyledCheckBox
                height={props.label ? 48 : 'auto'}
                value={props.value}
                text={props.altLabel}
                disabled={props.disabled}
                id={`dx-checkbox-${props.id}`}
                elementAttr={{
                    class: "lias-checkbox"
                }}
                iconSize="25"
                onValueChanged={onValueChanged}
            />
            {props.helpText && <HelpTextElement helpText={props.helpText} />}
        </Styled.StyledCheckBoxContainer>
    }
);

