import React from "react";
import { IDataItemProps, MultiSelectField } from "@liasincontrol/ui-basics";
import { getValidationErrorsNode } from '../../shared/validationHelper';
import { MultiSelectProps } from "./index.props";

export const MultiSelectEditor: React.FC<MultiSelectProps> = (props) => {
    return (
        <MultiSelectField
            id={props.id}
            key={props.id}
            placeholder='Kies…'
            label={props.label}
            helpText={props.helpText}
            disabled={props.editorSettings?.disabled}
            mandatory={props.editorSettings?.restrictions?.required}
            maxTagsCount={props.maxTagsCount}
            error={getValidationErrorsNode(props.editorSettings?.validationErrors)}
            value={{
                items: props.optionItems,
                selected: props.value.reduce((acc, value) => {
                    const foundItem = props.optionItems.find(oi => oi.value === value.value);
                    if (foundItem) {
                        acc.push(foundItem);
                    }
                    return acc;
                }, [] as IDataItemProps<any>[]),
                maxHeight: 280,
                searchable: true,
                clearable: true,
                emptyText: 'Niets gevonden',
            }}
            onChange={(val) => props.editorSettings?.onChange?.(val.selected)}
            withoutFeedback={props.editorSettings?.withoutFeedback}
        />
    );
};