import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Add from '@mui/icons-material/Add';
import { ContextMenu, createSource, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { Bar, Button, ErrorOverlay, FlexBox, Heading2, IconSize, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { DropDownElement, SelectElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { Finance as DataAccess } from '@liasincontrol/data-service';
import { UsersActionCreator, State, MeasureMomentsActionCreator, FinanceSettingsActionCreator, FinanceBaseYearsActionCreator, AjaxRequestStatus, BudgetJournalGroupActionCreator, StructuresActionCreator, StructureNodeActionCreator, ActionSource } from '@liasincontrol/redux-service';
import { ApiErrorReportingHelper, FormMode, IconHelper, useUserSettings } from '@liasincontrol/core-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { BudgetJournalForm } from './BudgetJournalForm';
import { BudgetJournalCreateForm } from './BudgetJournalCreateForm';
import { Grid as MuiGrid } from '@mui/material';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

export const editStageLabels = new Map<Domain.Finance.EditStage, string>([
    [Domain.Finance.EditStage.Draft, "Concept"],
    [Domain.Finance.EditStage.Workflow, "Workflow"],
    [Domain.Finance.EditStage.Final, "Afgerond"],
]);

/**
 * Represents a UI component that renders the overview of budgetJournal items.
 */
export const Index: React.FC<Props> = (props) => {
    const [formDialog, setFormDialog] = useState<{
        isVisible: boolean,
        isSaving: boolean,
        formMode: FormMode,
        entity?: Domain.Finance.BudgetJournalDetail,
    }>({ isVisible: false, isSaving: false, formMode: FormMode.View });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const baseYears = useMemo(() => props.baseYears.items, [props.baseYears]);
    const userSettingsContext = useUserSettings();

    const filterOptions = useMemo(() => {
        const returnValue = {
            baseYearOptions: props.baseYears.items,
            measureMomentOptions: (userSettingsContext.baseYear
                ? props.measureMoments.items.filter((moment) => moment.baseYear === userSettingsContext.baseYear)
                : []),
            structuresOptions: (userSettingsContext.baseYear
                ? props.structures.items.filter((structure) => structure.baseYear === userSettingsContext.baseYear)
                : []),
            structureNodesOption: (userSettingsContext.structureRK && props.structureNodes[userSettingsContext.structureRK] ?
                props.structureNodes[userSettingsContext.structureRK].items?.map(item => item.parentRK === userSettingsContext.structureRK ? { ...item, parentRK: null } : item)
                : [])
        };
        return returnValue;
    }, [props.measureMoments, props.structures, props.structureNodes, userSettingsContext.baseYear, userSettingsContext.structureRK]);

    useEffect(() => {
        props.fetchUsers();
        props.fetchMeasureMoments();
        props.fetchFinanceSettings();
        props.fetchBudgetJournalGroups();
        props.fetchStructures();
    }, []);

    useEffect(() => {
        if (!userSettingsContext.structureRK) return;
        props.fetchStructureNodes(userSettingsContext.structureRK);
    }, [userSettingsContext.structureRK]);

    const customDataSource = useMemo(() => {
        const cachedFilter = Object.fromEntries(Object.entries(userSettingsContext).filter(([_, value]) => typeof value !== 'function'));

        const filterBy: { [x: string]: any; }[] = Object.keys(cachedFilter).filter((column) => !!cachedFilter[column])
            ?.filter((column) => column !== 'structureRK' && column !== 'structureNodeRK')
            ?.map((column) => (
                { [column]: column === 'measureMomentId' ? { eq: { type: 'guid', value: cachedFilter[column] } } : cachedFilter[column] }
            ));

        return createSource({
            keyExpr: 'id',
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            filter: filterBy,
            dataSourcePromise: (query) => DataAccess.BudgetJournalDataAccessor.getAll(userSettingsContext.structureRK, userSettingsContext.structureNodeRK, query)
        });
    }, [lastRefresh, userSettingsContext]);

    if (!props.measureMoments.items.length || !props.users.items.length || !props.structures.items.length) {
        return null;
    }

    if (!props.settings?.item?.yearsAhead || !props.settings?.item?.yearsBack) {
        console.error('No years ahead or years behind setted correclty');
        return null;
    }

    if (props.baseYears.status === AjaxRequestStatus.NotSet) {
        props.fetchBaseYears();
        return null;
    }

    const fetchBudgetJournal = (budgetJournalId: string) => {
        DataAccess.BudgetJournalDataAccessor.get(budgetJournalId).then((response) => {
            setError(undefined);
            setFormDialog({
                ...formDialog,
                isVisible: true,
                entity: response.data,
                formMode: FormMode.Edit,
            })
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        });
    }

    const onClickRow = (item) => {
        if (!item) return;
        fetchBudgetJournal(item.id);
    };

    const onCancel = () => {
        setFormDialog({
            entity: undefined,
            isVisible: false,
            isSaving: false,
            formMode: FormMode.View
        });
    };

    const onCreate = (newBudgetJournal: Domain.Dto.Finance.CreateBudgetJournal) => {
        DataAccess.BudgetJournalDataAccessor.create(newBudgetJournal).then((response) => {
            setLastRefresh(Date.now());
            onClickRow({ id: response.data });
        }).catch((err) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
        }).finally(() => {
            setFormDialog({ ...formDialog, isSaving: false, isVisible: false, formMode: FormMode.View });
        });
    };

    const onSave = (budgetJournal: Domain.Finance.BudgetJournalDetail, closeModal = true) => {
        setFormDialog({ ...formDialog, isSaving: true });
        return DataAccess.BudgetJournalDataAccessor.update(budgetJournal).then(() => {
            setLastRefresh(Date.now());
            setFormDialog({ ...formDialog, isSaving: false, isVisible: !closeModal, formMode: FormMode.View });
        }).catch((err) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.EditOnBudgetJournalFinalStage)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.EditOnBudgetJournalFinalStage] });
            } else {
                setError(errorInfo);
            }
            setFormDialog({ ...formDialog, isSaving: false, isVisible: false, formMode: FormMode.View });
            throw err;
        });
    };

    const onDelete = (budgetJournal: Domain.Finance.BudgetJournal) => {
        DataAccess.BudgetJournalDataAccessor.delete(budgetJournal.id)
            .then(() => {
                setLastRefresh(Date.now());
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.DeleteOnBudgetJournalFinalStage)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.DeleteOnBudgetJournalFinalStage] });
                } else {
                    setError(errorInfo);
                }
            });
    };

    const availableColumns = getColumnConfiguration(onDelete);

    return <>
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Begrotingswijzigingen</Heading2>
                </PageTitle>
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <Bar look='toolbar'>
                        <Bar start>
                            <Button
                                id='btn-add-new-budgetJournal'
                                btnbase='textbuttons'
                                btntype='medium_icon'
                                icon={<Add />}
                                onClick={() => setFormDialog({ isVisible: true, isSaving: false, formMode: FormMode.AddNew })}
                            >
                                Nieuw
                            </Button>
                        </Bar>
                    </Bar>
                    {filterOptions && <Section look='white'>
                        <MuiGrid container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 1, sm: 2, lg: 8, xl: 10, xxl: 16 }}
                            justifyContent="flex-start"
                            alignItems="flex-end">
                            <MuiGrid item xs={1} lg={1}>
                                <SelectElement<number>
                                    id='select-base-year'
                                    label='Basisjaar'
                                    optionItems={filterOptions.baseYearOptions}
                                    value={userSettingsContext.baseYear}
                                    clearable={true}
                                    searchable={false}
                                    editorSettings={{
                                        disabled: false,
                                        restrictions: { required: false },
                                        validationErrors: [],
                                        withoutFeedback: true,
                                        onChange: (item) => {
                                            userSettingsContext.setBaseYear(item);
                                        }
                                    }}
                                />
                            </MuiGrid>
                            <MuiGrid item xs={1} lg={2}>
                                <SelectElement<Domain.Shared.MeasureMoment>
                                    id='select-measure-moment'
                                    label='Meetmoment'
                                    displayExpr='name'
                                    optionItems={filterOptions.measureMomentOptions}
                                    value={filterOptions.measureMomentOptions.find(mm => mm.id === userSettingsContext.measureMomentId)}
                                    clearable={true}
                                    searchable={false}
                                    editorSettings={{
                                        disabled: !userSettingsContext.baseYear,
                                        restrictions: { required: false },
                                        validationErrors: [],
                                        withoutFeedback: true,
                                        onChange: (item) => {
                                            userSettingsContext.setMeasureMomentId(item?.id);
                                        },
                                    }}
                                />
                            </MuiGrid>
                            <MuiGrid item xs={1} lg={2}>
                                <SelectElement<Domain.Finance.Structure>
                                    id='select-structure'
                                    label='Structuren'
                                    displayExpr='name'
                                    optionItems={filterOptions.structuresOptions}
                                    value={filterOptions.structuresOptions.find(str => str.rk === userSettingsContext.structureRK)}
                                    clearable={true}
                                    searchable={false}
                                    editorSettings={{
                                        disabled: !userSettingsContext.baseYear,
                                        restrictions: { required: false },
                                        validationErrors: [],
                                        withoutFeedback: true,
                                        onChange: (item) => {
                                            userSettingsContext.setStructureRK(item?.rk);
                                        },
                                    }}
                                />
                            </MuiGrid>
                            <MuiGrid item xs={1} lg={2}>
                                <DropDownElement<Domain.Finance.StructureNode>
                                    id='select-structurenode'
                                    label='Structuuronderdeel'
                                    displayExpr='name'
                                    valueExpr='rk'
                                    keyExpr="rk"
                                    parentIdExpr="parentRK"
                                    value={filterOptions.structureNodesOption.find(strn => strn.rk === userSettingsContext.structureNodeRK)}
                                    editorSettings={{
                                        disabled: !(userSettingsContext.baseYear && userSettingsContext.structureRK),
                                        restrictions: { required: false },
                                        validationErrors: [],
                                        withoutFeedback: true,
                                        onChange: (item) => {
                                            userSettingsContext.setStructureNodeRK(item?.rk);
                                        },
                                    }}
                                    optionItems={filterOptions.structureNodesOption}
                                    openOnFieldClick={true}
                                    clearable={true}
                                    searchable={true}
                                />
                            </MuiGrid>
                        </MuiGrid>
                    </Section>}
                    <Section look='white'>
                        <LsGrid
                            dataSource={customDataSource}
                            columns={availableColumns}
                            enableColumnChooser={false}
                            paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                            showRowLines={true}
                            onClickRow={onClickRow}
                            onDataError={(error) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))}
                        />
                    </Section>
                </ErrorOverlay>
            </WrapperContent>
        </Wrapper>
        {
            formDialog.isVisible &&
            (formDialog.formMode === FormMode.AddNew
                ? <BudgetJournalCreateForm
                    baseYears={baseYears}
                    disableSaveButton={false}
                    onSave={onCreate}
                    onCancel={onCancel} />
                : <BudgetJournalForm
                    disableSaveButton={formDialog.isSaving}
                    formMode={formDialog.formMode}
                    isReadonly={!!formDialog.entity.authorId && props.userIdentity.profile.sub !== formDialog.entity.authorId}
                    userIdentity={props.userIdentity}
                    budgetJournal={formDialog.entity}
                    measureMoments={props.measureMoments.items}
                    users={props.users.items}
                    yearsAhead={props.settings.item.yearsAhead}
                    budgetJournalGroups={props.budgetJournalGroups.items}
                    onSave={onSave}
                    onCancel={onCancel}
                    onError={(err) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err))}
                    onRefresh={() => {
                        setLastRefresh(Date.now());
                    }}
                />)
        }
    </>;
};

const getColumnConfiguration = (onDelete: (budgetJournal: Domain.Finance.BudgetJournal) => void): GridColumn<Domain.Finance.BudgetJournal>[] => {
    return [
        {
            name: 'baseYear',
            title: 'Basisjaar',
            align: 'left',
        },
        {
            name: 'measureMomentName',
            title: 'Meetmoment',
        },
        {
            name: 'budgetJournalGroupName',
            title: 'Journaalgroepen',
        },
        {
            name: 'code',
            title: 'Code'
        },
        {
            name: 'name',
            title: 'Naam'
        },
        {
            name: 'authorName',
            title: 'Aangemaakt door',
        },
        {
            name: 'createdOn',
            title: 'Aangemaakt op',
            dataType: 'date',
            formatter: 'datetime',
        },
        {
            name: 'editStage',
            title: 'Status',
            align: 'left',
            renderCustom: (item) => <>{editStageLabels.get(item.data.editStage)}</>,
        },
        {
            name: 'workflowStep',
            title: 'Workflow stap',
            renderCustom: (item) => (item.data.workflowStep
                ? <FlexBox>
                    {IconHelper.getWorkFlowStatusIcon(item.data.workflowStep, IconSize.small)}
                    {item.data.workflowStep}
                </FlexBox>
                : <></>
            )
        },
        {
            name: 'id',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => (
                data.editStage !== Domain.Finance.EditStage.Final &&
                onDelete &&
                <ContextMenu<Domain.Finance.BudgetJournal>
                    item={data}
                    keyExpr='id'
                    actions={[
                        {
                            action: onDelete,
                            ariaLabel: `Verwijderen journaal ${data.budgetJournalName}`,
                            actionName: `delete-budgetJournal-${data.budgetJournalId}`,
                            displayName: 'Verwijderen'

                        }
                    ]}
                />)
        }
    ];
};

const mapStateToProps = (state: State) => {
    return {
        users: state.users,
        measureMoments: {
            items: state.measuremoments.items,
            status: state.measuremoments.status,
        },
        settings: {
            item: state.finance.settings.item,
            status: state.finance.settings.status,
        },
        baseYears: {
            items: state.finance.baseYears.items,
            status: state.finance.baseYears.status,
        },
        budgetJournalGroups: {
            items: state.finance.budgetJournalGroups.items,
            status: state.finance.budgetJournalGroups.status,
        },
        structures: {
            items: state.finance.structures.items,
            status: state.finance.structures.status,
        },
        structureNodes: state.finance.structureNodes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsers: () => {
            dispatch(UsersActionCreator.set());
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
        fetchFinanceSettings: () => {
            dispatch(FinanceSettingsActionCreator.set());
        },
        fetchBaseYears: () => {
            dispatch(FinanceBaseYearsActionCreator.set())
        },
        fetchBudgetJournalGroups: () => {
            dispatch(BudgetJournalGroupActionCreator.set());
        },
        fetchStructures: () => {
            dispatch(StructuresActionCreator.set());
        },
        fetchStructureNodes: (structureRK: string) => {
            dispatch(StructureNodeActionCreator.set({
                data: { structureRK: structureRK },
                source: ActionSource.Financial,
            }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
