import React from 'react';
import { TextViewer } from '../../shared/TextViewer';
import { SelectEditor } from './index.editor';
import { SingleSelectProps } from './index.props';
/**
 * Represents a UI component that renders an Select element.
 */
const SelectElement = <V,>(props: SingleSelectProps<V>) => {
    return (
        props.editorSettings && !props.editorSettings.disabled
            ? <SelectEditor {...props} />
            : <TextViewer id={props.id} label={props.label} helpText={props.helpText} withoutFeedback={props.editorSettings?.withoutFeedback} value={getSelectedValue<V>(props.value, props.optionItems, props.displayExpr)} />
    );
};

const getSelectedValue = <V,>(value: V, optionItems: V[], displayExpr: any): string => {
    if (!value || optionItems?.length < 1) return '';

    const selectedItem = optionItems.find((optionItem) => optionItem === value);
    const val = selectedItem ? (displayExpr ? selectedItem[displayExpr] : selectedItem) : ' - ';
    return val;
};

export type SelectElementTemplateProps = {
    placeholder?: string;
    isFieldTemplate?: boolean;
}

export { SelectElement, SelectEditor }; 