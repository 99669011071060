import React from "react";
import { ColorPickerElement } from '@liasincontrol/ui-elements';
import { palette } from '@liasincontrol/ui-basics';
import styled from "styled-components";
import { Button } from '@liasincontrol/ui-basics';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

const maxColors = 6;

export interface CustomColorPaletteProps {
    setCustomColors: (colors: string[]) => void;
    colorPalette: string[];
    isEditing: boolean;
}

export const CustomColorPalette: React.FC<CustomColorPaletteProps> = ({ colorPalette, setCustomColors, isEditing }) => {
    const addColor = () => {
        setCustomColors([...colorPalette, '#000000']);
    }

    const removeColor = (i: number) => {
        setCustomColors(colorPalette.filter((_, index) => index !== i));
    }

    const setColor = (i: number, e) => {
        setCustomColors(colorPalette.map((color, index) => index === i ? e : color));
    }

    return (
        <Wrapper>
            {colorPalette.map((color, i) => <div className="color-picker-wrapper" key={i}>
                <ColorPickerElement
                    id={`custom-color-picker-${i}`}
                    editorSettings={{
                        disabled: !isEditing,
                        onChange: (e) => setColor(i, e),
                    }}
                    size='small'
                    defaultColor={palette.grey4}
                    value={color}
                />
                {isEditing && colorPalette?.length > 1 &&
                    <Button
                        className="remove-color"
                        btnbase="iconbuttons"
                        btntype="small_fabghost"
                        icon={<ClearIcon />}
                        onClick={() => removeColor(i)}
                    />
                }
            </div>
            )}
            {colorPalette.length < maxColors && isEditing &&
                <Button
                    className="add-color"
                    btnbase="iconbuttons"
                    btntype="small_fabprimary"
                    icon={<AddIcon />}
                    onClick={addColor}
                />
            }
        </Wrapper>);
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;

    .color-picker-wrapper {
        position: relative;

        > button.remove-color {
            position: absolute;
            top: -0.5rem;
            right: -0.5rem;
        }

        .color-picker {
            margin-bottom: 0;
        }
    }
`;