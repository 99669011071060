import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { AttachmentsHelper, FormInfo, FormMode, ValueType } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ModalDialog, EditingToolbar } from '@liasincontrol/ui-basics';
import { TextAssistantAI, MultilineToolbarContainer } from '@liasincontrol/ui-elements';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions, License } from '@liasincontrol/userrights-service';
import { useTextAssistantSkills } from "@liasincontrol/redux-service";
import { StudioElementForm } from '../StudioElementForm';
import { DetailCardLayout } from '../../_shared/Renderer/ElementDetailsForm/utils';
import { MomentType } from '../HierarchyList/HerarchyListItem';

type Props = {
    userIdentity: UserIdentity,
    fields: Record<string, string>,
    attachments: Domain.Shared.Attachment[],
    audit: Domain.Dto.Shared.AuditEvent[],
    users: Domain.Shared.User[],
    elementDefinition: Domain.Shared.ElementDefinition,
    workflowTemplate?: Domain.Shared.WorkflowTemplateWithStates,
    workflowState: Domain.Shared.AbstractElementWorkflowStatus,
    hierarchyItemId: string,
    linkedStructures: Domain.Shared.LinkedStructure[],
    measureMoment: MomentType,
    mode: FormMode,
    leaseInfo?: Domain.Shared.AcquireLease,
    onSaveChanges: (
        fields: Record<string, ValueType>,
        workflowState: Domain.Shared.AbstractElementWorkflowStatus,
        attachments: Domain.Shared.Attachment[],
        bypassThen: boolean) => void,
    onCancelChanges: () => void,
    onRefresh?: () => void,
    forceSaveHierarchyItem?: boolean,
    icons?: Record<string, Domain.Shared.SvgIcon>,
};

/**
 * Represents a UI component that renders the form for creating or editing an entity.
 */
export const HierarchyItem: React.FC<Props> = (props) => {
    const hasEditPermission = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_GlobalStudioContributor, ActionType.Update);

    const [fields, setFields] = useState<FormInfo<ValueType>>({
        values: props.fields,
        attachments: props.attachments ?? [],
        workflow: props.workflowState,
        isValid: true,
        isTouched: false,
    });
    const [selectedFieldId, setSelectedFieldId] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const [showTextAssistantAI, setShowTextAssistantAI] = useState<boolean>(false);
    const hasArtificialIntelligenceAvailable = UserRightsService.getInstance().userHasLicence(props.userIdentity, License.AITextAssistant);
    const textAssistantSkills = useTextAssistantSkills(hasArtificialIntelligenceAvailable);

    useEffect(() => {
        if (!!props.forceSaveHierarchyItem) {
            props.onSaveChanges(fields.values, fields.workflow, fields.attachments, true);
        }
    }, [props.forceSaveHierarchyItem]);

    // #region event handlers...
    /**
     * Sets a new value for the workflow state related to the current performance item.
     * @param workflowStateValue The value of the workflow state that has been changed.
     */
    const onWorkflowStateChanged = (workflowStateValue: Domain.Shared.AbstractElementWorkflowStatus) => {
        setFields(prevFields => {
            const newFields = _.cloneDeep(prevFields);
            newFields.workflow = workflowStateValue;
            newFields.isTouched = true;
            return newFields;
        });
    };

    /**
     * Sets a new value for the field values related to the current performance item.
     * @param fields The data with the fields that has been changed.
     */
    const onFieldsChanged = (fields: FormInfo<ValueType>) => {
        setFields(prevFields => {
            const newFields = _.cloneDeep(prevFields);
            newFields.values = fields.values;
            newFields.isValid = fields.isValid;
            newFields.isTouched = true;
            return newFields;
        });
    };

    const onUploadAttachment = async (file: File, abortSignal: AbortSignal) => {
        return AttachmentsHelper.uploadAttachment(file, abortSignal, (attachmentId: string, file: File) => {
            let element = _.cloneDeep(fields);
            element = {
                ...element,
                attachments: [...element.attachments, AttachmentsHelper.mapFileToAttachment(file, attachmentId, false)],
                isTouched: true,
            };

            setFields(element);
        });
    };

    const onRemoveAttachment = (attachmentId: string) => {
        const attachmentsList = _.cloneDeep(fields.attachments);

        const attachment = attachmentsList.find(item => item.id === attachmentId);
        if (attachment) {
            attachment.deleted = true;
        }

        const newFields = _.cloneDeep(fields);
        newFields.attachments = attachmentsList;
        newFields.isTouched = true;
        setFields(newFields);
    };
    // #endregion

    const middleEditorToolbars = useMemo(() => {
        if (!hasArtificialIntelligenceAvailable || !selectedFieldId) {
            return null;
        }

        const selectedMultilineField = props.elementDefinition.fields.find(field => field.id === selectedFieldId && isFieldMultiline(field));

        if (selectedMultilineField) {
            return <MultilineToolbarContainer hidden={false} onClick={() => setShowTextAssistantAI(true)} />
        } else {
            return null;
        }
    }, [props.elementDefinition, selectedFieldId, hasArtificialIntelligenceAvailable]);


    const allRequiredFieldsUsed = useMemo(() => {
        if (!props.elementDefinition) return false;
        const detailCard = props.elementDefinition.detailcards.find((card) => card.id === props.elementDefinition.defaultDetailcardId);
        if (!detailCard) return false;
        const flatten = JSON.parse(detailCard.layout) as DetailCardLayout;
        const placedFieldIds = flatten.columns.flatMap(column => column.fieldIds);
        const requiredFieldIds = props.elementDefinition.fields
            .filter(field => field.systemId === Domain.SystemFieldDefinitions.Studio.Dynamic && field.required).map(field => field.id);
        const found = requiredFieldIds.every(fieldId => placedFieldIds.includes(fieldId));
        return found;
    }, [props.elementDefinition]);

    const onSetShowTextAssistantAI = useCallback(() => {
        setShowTextAssistantAI(true);
    }, []);

    return (
        <ModalDialog
            toolbars={<EditingToolbar
                look="default"
                isVisible={true}
                isValid={fields.isValid}
                disabled={(isBusy || !fields.isTouched || !hasEditPermission || !allRequiredFieldsUsed)}
                middleNode={middleEditorToolbars}
                onSave={() => props.onSaveChanges(fields.values, fields.workflow, fields.attachments, false)}
                onCancel={props.onCancelChanges} />}
            customPadding
            modalDialogStyle="card"
        >
            <StudioElementForm
                fields={fields}
                selectedFieldId={selectedFieldId}
                users={props.users}
                audit={props.audit}
                measureMoment={props.measureMoment}
                hierarchyItemId={props.hierarchyItemId}
                linkedStructures={props.linkedStructures}
                elementDefinition={props.elementDefinition}
                workflowTemplate={props.workflowTemplate}
                mode={props.mode}
                onFieldsDataChanged={onFieldsChanged}
                onWorkflowStateChanged={onWorkflowStateChanged}
                onUploadAttachment={onUploadAttachment}
                onRemoveAttachment={onRemoveAttachment}
                onStartMultipleUpload={() => setIsBusy(true)}
                onFinishMultipleUpload={() => setIsBusy(false)}
                onSelect={(fieldId) => setSelectedFieldId(fieldId)}
                onRefresh={props.onRefresh}
                hasEditPermission={hasEditPermission}
                allRequiredFieldsUsed={allRequiredFieldsUsed}
                leaseInfo={props.leaseInfo}
                hasTextAssistant={hasArtificialIntelligenceAvailable}
                onTextAssistant={onSetShowTextAssistantAI}
                icons={props.icons}
            />
            {hasArtificialIntelligenceAvailable && showTextAssistantAI &&
                <TextAssistantAI
                    textAssistantSkills={textAssistantSkills?.items}
                    textContent={fields?.values?.[selectedFieldId]}
                    onCancel={() => setShowTextAssistantAI(false)}
                />}

        </ModalDialog>
    );
};

const isFieldMultiline = (field: Domain.Shared.FieldDefinition) => {
    if (field.dataType !== Domain.Shared.FieldDataType.String.toString())
        return false;

    if (field.stringType === Domain.Shared.StringType.MultiLine) {
        return true;
    }

    switch (field.systemId) {
        case Domain.SystemFieldDefinitions.Performance.Explanation:
        case Domain.SystemFieldDefinitions.Performance.Description:
        case Domain.SystemFieldDefinitions.Performance.Result:
            return true;
        default: return false;
    }
};