import React, { useEffect, useMemo, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Bar, Button, Section } from '@liasincontrol/ui-basics';
import { ConnectingDialog } from '@liasincontrol/ui-elements';
import { ContextMenu, GridColumn, LsGrid, ScrollView } from '@liasincontrol/ui-devextreme';

type Props = {
    userGroupRoles: Domain.Shared.UserGroupRoles,
    roles: Domain.Shared.UserRole[],
    disabled: boolean,
    onRolesAdded?: (roleIds: string[]) => void,
};

/**
 * Represents a UI component that renders the group with roles form.
 */
export const RolesInGroup: React.FC<Props> = (props) => {
    const [roleIds, setRoleIds] = useState<string[]>([]);
    const [addRole, setAddRole] = useState<boolean>(false);

    const availableColumns: GridColumn<Domain.Shared.UserRole>[] = [{
        name: 'name',
        title: 'Rollen',
        allowSorting: false,
    },
    {
        name: 'id',
        title: '',
        type: 'buttons',
        width: '5%',
        align: 'right',
        hideInColumnChooser: true,
        renderCustom: ({ data }) => {
            if (props.disabled) return null;
            return <ContextMenu<Domain.Shared.UserGroup>
                keyExpr='id'
                item={data}
                actions={[
                    {
                        action: () => {
                            const newIds = roleIds.filter(id => id !== data.id);
                            props.onRolesAdded(newIds);
                        },
                        actionName: `delete-${data.id}`,
                        displayName: 'Verwijderen',
                        ariaLabel: `Verwijder ${data.name}`
                    }
                ]}
            />
        },
    }
    ];
    const assignedRoles = useMemo(() => props.roles.filter(role => roleIds.includes(role.id)), [props.roles, roleIds]);

    useEffect(() => {
        if (!props.userGroupRoles) {
            return;
        }
        setRoleIds(props.userGroupRoles.roleIds || []);
    }, [props.userGroupRoles]);

    const getAssignmentDialogElement = (userGroupRoles: Domain.Shared.UserGroupRoles) => {
        const listItems = props.roles
            .filter((role) => !roleIds.includes(role.id))
            .map((role) => { return { id: role.id, label: role.name, value: false } });

        return (
            <ConnectingDialog
                disableSaveButton={false}
                mandatory={false}
                title={`Rollen koppelen naar ${userGroupRoles.name}`}
                listItems={listItems}
                selectedItems={assignedRoles.map(role => ({ label: role.name, id: role.id, value: false }))}
                onCancelled={() => setAddRole(false)}
                onConfirmed={(roles) => {
                    const selected = roles.map(role => role.id);
                    setAddRole(false);
                    props.onRolesAdded(selected);
                }}
            />
        );
    };

    return (<>
        {!props.disabled && <Bar look='toolbar'>
            <Bar start>
                <Button id='btn-add-role-to-group' btnbase='textbuttons' btntype='medium_icon' onClick={() => setAddRole(true)}>
                    Rollen toevoegen
                </Button>
            </Bar>
        </Bar>}
        <Section look='white' padding={true}>
            <ScrollView
                elementAttr={{
                    class: 'lias-modal-dialog-content'
                }}
                bounceEnabled={false}
                showScrollbar="always"
                useNative={true}
                scrollByThumb={true}
                direction="vertical"
            >
                <div className='p-150'>
                    <LsGrid
                        dataSource={assignedRoles}
                        columns={availableColumns}
                        enableColumnChooser={false}
                        showRowLines={true}
                        enableFilterRow={true}
                    />
                </div>
            </ScrollView>
        </Section>
        {addRole && getAssignmentDialogElement(props.userGroupRoles)}
    </>
    );
};
