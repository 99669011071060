import React from 'react';
import * as Domain from '@liasincontrol/domain';
import { ConnectingDialog } from '@liasincontrol/ui-elements';
import { AjaxRequestStatus, useUserGroups } from '@liasincontrol/redux-service';
import { LoadPanel } from '@liasincontrol/ui-devextreme';

type Props = {
    user: Domain.Shared.User;
    onSave: (userId: string, userGroupsId: string[]) => void;
    onCancel: () => void;
    onError: (error: Domain.Shared.ErrorInfo) => void;
    isBusy?: boolean;
};

/**
 * Represents a UI component that link userGroups to selected user.
 */
export const LinkUserGroup: React.FC<Props> = (props) => {
    const userGroups = useUserGroups();

    if (userGroups.status === AjaxRequestStatus.Pending) {
        return <LoadPanel visible={true} />
    }

    const availableUserGroups = userGroups.items.reduce((acc, group) => {
        if (!props.user.groups.some(userGroup => userGroup.id === group.id)) {
            acc.push({
                id: group.id,
                label: group.name,
                value: false,
            });
        }
        return acc;
    }, []);

    return <ConnectingDialog
        disableSaveButton={props.isBusy}
        mandatory={false}
        title={`Gebruikersgroep koppelen aan gebruiker ${props.user?.name}`}
        listItems={availableUserGroups}
        selectedItems={props.user.groups.map(group => ({ label: group.name, id: group.id, value: false }))}
        onCancelled={props.onCancel}
        onConfirmed={(groups) => {
            const selected = groups.map(group => group.id);
            props.onSave(props.user.id, selected);
        }}
    />;

};
