import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { ConnectingDialog } from '@liasincontrol/ui-elements';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { AjaxRequestStatus, useUserGroups } from '@liasincontrol/redux-service';
import { LoadPanel } from '@liasincontrol/ui-devextreme';

type Props = {
    role: Domain.Shared.UserRole,
    onSave: (roleId: string, userGroupsId: string[]) => void,
    onCancel: () => void,
    onError: (error: Domain.Shared.ErrorInfo) => void;
};

/**
 * Represents a UI component that link userGroups to selected role.
 */
export const LinkUserGroup: React.FC<Props> = (props) => {
    const [role, setRole] = useState<Domain.Shared.UserRole>(undefined);
    const userGroups = useUserGroups();

    //Need refresh role?
    useEffect(() => {
        if (!props.role?.id) return;
        DataAccess.RolesDataAccess.get(props.role.id)
            .then((response) => {
                setRole(response.data);
            }).catch((err) => {
                setRole(undefined);
                props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });
    }, [props.role]);

    if (userGroups.status === AjaxRequestStatus.Pending) {
        return <LoadPanel visible={true} />
    }

    const availableUserGroups = userGroups.items.reduce((acc, group) => {
        if (!props.role.groups.some(userGroup => userGroup.id === group.id)) {
            acc.push({
                id: group.id,
                label: group.name,
                value: false,
            });
        }
        return acc;
    }, []);

    return <ConnectingDialog
        disableSaveButton={false}
        mandatory={false}
        title={`Gebruikersgroep koppelen aan rol ${role?.name}`}
        listItems={availableUserGroups}
        selectedItems={props.role.groups.map(group => ({ label: group.name, id: group.id, value: false }))}
        onCancelled={props.onCancel}
        onConfirmed={(groups) => {
            const selected = groups.map(group => group.id);
            props.onSave(props.role.id, selected);
        }}
    />;

};
