import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Grid as MuiGrid } from '@mui/material';
import { SelectElement, TextElement } from '@liasincontrol/ui-elements';

export type FilterModel = {
    baseYear?: number,
    budgetYear?: number,
    journalElementCombinationRK?: string;
    measureMomentTag?: string;
}

type Props = {
    baseYears?: number[],
    filter?: FilterModel;
    yearsBehind?: number;
    yearsAhead?: number;
    onFilterChanged: (filter: FilterModel) => void,
}

export const BudgetAmountsFilters: React.FC<Props> = (props) => {
    const [filter, setFilter] = useState<FilterModel>(props.filter);
    const budgetYearOptions = _.range(_.min(props.baseYears), +_.max(props.baseYears) + +(props.yearsAhead), 1);

    useEffect(() => {
        props.onFilterChanged(filter);
    }, [filter]);

    return (
        <MuiGrid container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 1, md: 8 }}
            justifyContent="flex-start"
            alignItems="flex-end">
            {props.baseYears &&
                <MuiGrid item xs={1} md={1}>
                    <SelectElement<number>
                        id='select-base-year'
                        label='Basisjaar'
                        optionItems={props.baseYears}
                        value={filter.baseYear}
                        clearable={true}
                        searchable={false}
                        editorSettings={{
                            disabled: false,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (item) => setFilter(prev => ({ ...prev, baseYear: item })),
                        }}
                    />
                </MuiGrid>
            }
            {props.baseYears &&
                <MuiGrid item xs={1} md={1}>
                    <SelectElement<number>
                        id='select-budget-year'
                        label='Jaarschijf'
                        optionItems={budgetYearOptions}
                        value={filter.budgetYear}
                        clearable={true}
                        searchable={false}
                        editorSettings={{
                            disabled: false,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (item) => setFilter(prev => ({ ...prev, budgetYear: item })),
                        }}
                    />
                </MuiGrid>
            }
            <MuiGrid item xs={1} md={2}>
                <TextElement id='search-journalCombination' value={filter.journalElementCombinationRK}
                    label='Combinatie'
                    editorSettings={{
                        disabled: false,
                        restrictions: { required: false },
                        validationErrors: [],
                        onChange: (value: string) => setFilter(prev => ({ ...prev, journalElementCombinationRK: value })),
                    }}
                />
            </MuiGrid>
            <MuiGrid item xs={1} md={2}>
                <TextElement id='search-measureMomentTag' value={filter.measureMomentTag}
                    label='Moment label'
                    editorSettings={{
                        disabled: false,
                        restrictions: { required: false },
                        validationErrors: [],
                        onChange: (value: string) => setFilter(prev => ({ ...prev, measureMomentTag: value })),
                    }}
                />
            </MuiGrid>
        </MuiGrid>
    );
}