import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { ApiErrorReportingHelper, FormMode } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ContextMenu, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { Bar, Button, Section } from '@liasincontrol/ui-basics';
import { AppSettingsService } from '@liasincontrol/config-service';
import { UserRightsService, Actions, ActionType } from '@liasincontrol/userrights-service';
import { RoleAddEdit } from './RoleAddEdit';
import { ConfirmDialog } from '../../../_shared/ConfirmDialog';
import { LinkUserGroup } from './LinkUserGroup';

type Props = {
    userIdentity: UserIdentity,
    lastRefresh?: number;
    onError: (error: Domain.Shared.ErrorInfo) => void;
    onRefresh?: (tick: number) => void;
    setSelectedTab?: (tabIndex: number) => void;
};

/**
 * Represents a UI component that renders the roles management screen.
 */
const Index: React.FC = () => {
    const props = useOutletContext<Props>();
    const [userRoles, setUserRoles] = useState<Domain.Shared.UserRole[]>([]);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isRoleCreating, setIsRoleCreating] = useState<{ visible: boolean, mode: FormMode, data?: Domain.Shared.UserRole }>();
    const [isRoleDeleting, setIsRoleDeleting] = useState<{ visible: boolean, data?: Domain.Shared.UserRole }>({ visible: false });
    const [isRoleLink, setIsRoleLink] = useState<{ visible: boolean, data?: Domain.Shared.UserRole }>({ visible: false });
    const hasManageRoleAccess = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Roles);
    const canCreate = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Roles, ActionType.Create);
    const canUpdate = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Roles, ActionType.Update);
    const canDelete = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Roles, ActionType.Delete);

    useEffect(() => {
        props.setSelectedTab(1);
        DataAccess.RolesDataAccess.getAll()
            .then((response) => {
                setUserRoles(response.data);
            }).catch((err) => {
                props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err));
            });
    }, [props.lastRefresh]);

    /**
     * Represents an event handler that triggers when discarding the changes.
     */
    const cancelChanges = () => {
        setIsRoleCreating((prev) => ({ ...prev, visible: false, data: undefined, mode: FormMode.View }));
    };

    /**
     * Represents an event handler that triggers when creating/updating role.
     */
    const saveChanges = (fields: Record<string, any>, mode: FormMode) => {
        if (!fields || !mode) {
            return;
        }

        const role: Domain.Dto.Shared.UserRole = {
            name: fields['name'],
            permissions: fields['permissions']
        };

        if (mode === FormMode.AddNew)
            DataAccess.RolesDataAccess.create(role)
                .then(() => {
                    props.onRefresh(Date.now());
                }).catch((err) => {
                    props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err));
                });
        else if (mode === FormMode.Edit) {
            DataAccess.RolesDataAccess.save(isRoleCreating.data?.id, role)
                .then(() => {
                    props.onRefresh(Date.now());
                }).catch((err) => {
                    const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
                    if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.RoleNameNotUnique)) {
                        props.onError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.RoleNameNotUnique] });
                    } else {
                        props.onError(errorInfo);
                    }
                });
        }
        cancelChanges();
    };

    /**
     * Represents an event handler that triggers when deleting a role.
     */
    const deleteRole = (roleId: string) => {
        setIsBusy(true);
        DataAccess.RolesDataAccess.delete(roleId)
            .then(() => {
                props.onRefresh(Date.now());
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.ItemAlreadyInUse)) {
                    props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting,
                        { ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.ItemAlreadyInUse] }));
                } else {
                    props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception));
                }
            }).finally(() => {
                setIsRoleDeleting({ visible: false });
                setIsBusy(false);
            });
    };

    const linkUserGroupsToRole = (roleId: string, userGroupsId: string[]) => {
        setIsBusy(true);
        DataAccess.RolesDataAccess.addRoleToGroups(roleId, userGroupsId)
            .then(() => {
                props.onRefresh(Date.now());
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.ItemAlreadyInUse)) {
                    props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting,
                        { ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.ItemAlreadyInUse] }));
                } else {
                    props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception));
                }
            }).finally(() => {
                setIsRoleLink({ visible: false });
                setIsBusy(false);
            });
    };

    const availableColumns: GridColumn<Domain.Shared.UserRole>[] = [
        { name: 'name', title: 'Rol' },
        {
            name: 'groups',
            title: 'Gebruikersgroep(en)',
            dataType: 'string',
            calculateDisplayValue: (data) => data.groups.map(group => group.name).join(', '),
            calculateFilterExpression: (filterValue, selectedFilterOperation, target) => {
                if (target === "filterRow") {
                    return [(data) => {
                        const groupNames = data.groups.map(group => group.name.toLowerCase());
                        const filterVal = String(filterValue).toLowerCase();

                        switch (selectedFilterOperation) {
                            case 'contains':
                                return groupNames.some(name => name.includes(filterVal));
                            case 'notcontains':
                                return groupNames.every(name => !name.includes(filterVal));
                            case 'startswith':
                                return groupNames.some(name => name.startsWith(filterVal));
                            case 'endswith':
                                return groupNames.some(name => name.endsWith(filterVal));
                            case '=':
                                return groupNames.some(name => name === filterVal);
                            case '<>':
                                return groupNames.every(name => name !== filterVal);
                            default:
                                return false;
                        }
                    }];
                }
                return null;
            }
        },
        {
            name: 'id',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => {
                return (
                    <ContextMenu<Domain.Shared.UserRole>
                        keyExpr='id'
                        item={data}
                        actions={[
                            {
                                action: (item) => setIsRoleCreating({ visible: true, mode: canUpdate ? FormMode.Edit : FormMode.View, data: item }),
                                displayName: 'Bewerken',
                                ariaLabel: `Bewerken ${data.name}`,
                                actionName: `edit-${data.id}`
                            },
                            {
                                action: (item) => setIsRoleLink({ visible: true, data: item }),
                                displayName: 'Bewerk gebruikersgroep',
                                ariaLabel: `Bewerken groep ${data.name}`,
                                actionName: `link-${data.id}`,
                                hidden: !canUpdate
                            },
                            {
                                action: (item) => setIsRoleDeleting({ visible: true, data: item }),
                                displayName: 'Verwijderen',
                                ariaLabel: `Verwijderen ${data.name}`,
                                actionName: `delete-${data.id}`,
                                hidden: !canDelete
                            }
                        ]}
                    />);
            },
        }];

    return (
        <>
            {hasManageRoleAccess && <Bar look='toolbar'>
                <Bar start>
                    <Button
                        id="btn-add-new-role"
                        btnbase="textbuttons"
                        btntype="medium_icon"
                        icon={<Add />}
                        disabled={!canCreate}
                        onClick={() => setIsRoleCreating({ visible: true, mode: FormMode.AddNew, data: undefined })}
                    >
                        Nieuw
                    </Button>
                </Bar>
            </Bar>}
            <Section look='white'>
                {userRoles && availableColumns && (
                    <LsGrid
                        keyExpr='id'
                        dataSource={userRoles}
                        columns={availableColumns}
                        enableColumnChooser={false}
                        searching={false}
                        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                        showRowLines={true}
                        onClickRow={(item: Domain.Shared.UserRole) => {
                            setIsRoleCreating({ visible: true, mode: canUpdate ? FormMode.Edit : FormMode.View, data: item });
                        }}
                        enableFilterRow={true}
                    />
                )}
            </Section>
            {isRoleCreating?.visible ?
                <RoleAddEdit
                    mode={isRoleCreating.mode}
                    role={isRoleCreating.mode === FormMode.AddNew ? undefined : isRoleCreating.data}
                    roles={userRoles}
                    licenses={props.userIdentity?.profile?.licenses || []}
                    onSave={saveChanges}
                    onCancel={cancelChanges}
                    onError={props.onError}
                />
                : null}
            {canDelete && isRoleDeleting.visible ? (
                <ConfirmDialog
                    look="message"
                    title="Verwijder rol"
                    text="Weet u zeker dat u de rol wilt verwijderen?"
                    confirmButtonText="Verwijderen"
                    disableConfirmation={isBusy}
                    onCancel={() => setIsRoleDeleting({ visible: false })}
                    onConfirm={() => deleteRole(isRoleDeleting.data?.id)} />
            ) : null}
            {canUpdate && isRoleLink.visible ? (
                <LinkUserGroup
                    role={isRoleLink.data}
                    onSave={linkUserGroupsToRole}
                    onCancel={() => setIsRoleLink({ visible: false })}
                    onError={props.onError}
                />
            ) : null}
        </>
    );
};

export { Index as index };
