import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import _, { Dictionary } from 'lodash';
import { FieldsHelper, JsonUtils } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ThumbnailImg, useComponentBounds } from '@liasincontrol/ui-basics';
import { CUSTOM_PALETTE, DEFAULT_PALETTE, getRealHeight, IChartLegend, ILsColumnProps, LsBarChart } from '@liasincontrol/ui-devextreme';
import { DataSourceControlsUtils, PublicationContext } from '../../../../../../helpers';
import Styled from './index.styled';
import SharedStyled from '../../SharedControlElements/index.styled';
import { ChartLegend } from '../../SharedControlElements/constants';

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    variables?: Domain.Shared.ComplexFieldItem[],
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onLoadAttachment: (id: string) => Promise<Blob>,
    hasErrorInSettings?: (elementId: string, hasError: boolean, message?: string, keepError?: boolean) => void,
}

type ControlSettings = {
    barChart: Domain.Publisher.BarChartControl,
    orientation: boolean,
    legend: IChartLegend
}

/**
 * Represents a UI component that renders a bar chart control.
 */
const BarChartControl: React.FC<Props> = (props) => {
    const { element, elementList, onLoadAttachment, getElementDefinition } = props;

    const [dataSource, setDataSource] = useState<Domain.Publisher.DataSourceElement>();
    const [dataSourceData, setDataSourceData] = useState<{ data: [], fields: ILsColumnProps[] }>();
    const pieChartWrapperRef = useRef<HTMLDivElement>(null);
    const { width: parentWidth } = useComponentBounds(pieChartWrapperRef);
    const pubContext = useContext(PublicationContext);

    const controlSettings: ControlSettings = useMemo(() => {
        const pieChartElement = elementList?.[element.elementId];
        if (!pieChartElement) return;
        const definition = getElementDefinition(pieChartElement.elementDefinitionSystemId, pieChartElement.elementDefinitionId);
        const settings = new Domain.Publisher.BarChartControl();
        FieldsHelper.mapObject<Domain.Publisher.BarChartControl>(settings, definition.fields, pieChartElement.fields);
        const orientationOption = FieldsHelper.mapFieldOption<Domain.Publisher.BarChartControl>(settings, 'orientation', definition);
        const chartLegendOption = FieldsHelper.mapFieldOption<Domain.Publisher.BarChartControl>(settings, 'legend', definition);
        const chartLegend = ChartLegend[chartLegendOption?.value || 0];
        return {
            barChart: settings,
            orientation: !!orientationOption?.value,
            legend: chartLegend,
        };
    }, [elementList]);

    useEffect(() => {
        const loadDataSourceAsync = async () => {
            if (controlSettings.barChart?.dataSourceId) {
                const dsElement = await pubContext.loadDataSourceElement(controlSettings.barChart.dataSourceId)
                setDataSource(dsElement);
            } else {
                setDataSource(undefined);
            }
        };
        loadDataSourceAsync();
    }, [controlSettings.barChart?.dataSourceId]);

    useEffect(() => {
        props.hasErrorInSettings?.(element.elementId, false);
        if (!dataSource?.dataFileId || !dataSource?.schemaFileId || dataSource?.failedRefresh) {
            if (dataSource?.failedRefresh) props.hasErrorInSettings?.(element.elementId, true, 'Fout opgetreden bij het verversen.', true);
            setDataSourceData(undefined);
        } else {
            const loadBlobsAsync = async () => {
                try {
                    const responses = await Promise.all([
                        onLoadAttachment(dataSource.dataFileId),
                        onLoadAttachment(dataSource.schemaFileId)
                    ]);
                    const blobs = await Promise.all([
                        responses[0].text(),
                        responses[1].text()
                    ]);
                    setDataSourceData({
                        data: JSON.parse(blobs[0]),
                        fields: DataSourceControlsUtils.mapsDataSourceColumnFields(JSON.parse(blobs[1]))
                    });
                } catch (e) {
                    props.hasErrorInSettings?.(element.elementId, true, 'Databron fout.');
                }
            };
            loadBlobsAsync();
        }
    }, [dataSource?.id, dataSource?.dataFileId, dataSource?.schemaFileId, onLoadAttachment]);

    if (!(controlSettings && controlSettings.barChart.argument && controlSettings.barChart.value && dataSourceData?.data
        && DataSourceControlsUtils.checkDataSourceForSeriesItems(controlSettings.barChart.value, controlSettings.barChart.argument, dataSourceData?.fields))) {
        return <SharedStyled.ThumbnailWrapper><ThumbnailImg variant="pieChart" /></SharedStyled.ThumbnailWrapper>;
    }

    const valueFormat = JsonUtils.toJson(controlSettings.barChart?.valueFormat);
    const filter = DataSourceControlsUtils.getDataSourceFilter(controlSettings.barChart.filter, props.variables);

    const colorPalette = pubContext.colorPalette === CUSTOM_PALETTE 
        ? pubContext.customColors
        : pubContext.colorPalette || DEFAULT_PALETTE;

    return (
        <>
            {controlSettings.barChart.title && <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{controlSettings.barChart.title}</SharedStyled.Title>}
            <SharedStyled.GridWrapper
                primaryColor={props.publicationElement.primaryColor}
                primaryTextColor={props.publicationElement.primaryContrastColor}
                textColor={props.publicationElement.bodyFontColor}
                textFontSize={props.publicationElement.bodyFontSize}
                editMode={false}
            >
                <Styled.CenteredDiv ref={pieChartWrapperRef}>
                    <LsBarChart
                        type="bar"
                        data={dataSourceData.data}
                        filterValue={filter}
                        orientation={controlSettings.orientation}
                        pieChartData={{
                            valueField: controlSettings.barChart.value,
                            argumentField: controlSettings.barChart.argument,
                        }}
                        palette={colorPalette}
                        primaryColor={props.publicationElement.primaryColor}
                        primaryTextColor={props.publicationElement.primaryContrastColor}
                        tooltipData={{
                            enabled: true,
                            customizeTooltip: DataSourceControlsUtils.setPieChartTooltipText,
                            format: valueFormat,
                        }}
                        legendData={{
                            verticalAlignment: controlSettings.legend.verticalAlignment,
                            horizontalAlignment: controlSettings.legend.horizontalAlignment,
                            visible: false,//controlSettings.legend.visible
                        }}
                        redrawOnResize={true}
                        size={parentWidth ? { width: parentWidth, height: getRealHeight(parentWidth, maxAllowedHeight) } : undefined}
                    />
                </Styled.CenteredDiv>
            </SharedStyled.GridWrapper>
        </>
    );
}

const maxAllowedHeight = 400;

export default React.memo(BarChartControl, (prevProps, nextProps) =>
    _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId]) && prevProps.variables === nextProps.variables
);